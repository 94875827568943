import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/tooltip';

import { MediaAsset } from '@app/data/models';
import { Store } from '@ngxs/store';

import { HomeActions } from '../../actions';
import { ManageMediaState } from '../../state/manage-media.state';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MediaAssetListComponent } from '../../components/media-asset-list/media-asset-list.component';
import { MatButton } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'admin-inline-media-asset-dialog',
    templateUrl: './inline-media-asset-dialog.component.html',
    styleUrls: ['./inline-media-asset-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER],
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        ReactiveFormsModule,
        MediaAssetListComponent,
        MatDialogActions,
        MatButton,
        AsyncPipe,
    ]
})
export class InlineMediaAssetDialogComponent implements OnInit {
    private data = inject(MAT_DIALOG_DATA);
    private dialog = inject<MatDialogRef<InlineMediaAssetDialogComponent>>(MatDialogRef);
    private fb = inject(FormBuilder);
    private store = inject(Store);

    form = this.fb.group({
        mediaAssetId: [''],
    });

    mediaAssets$ = this.store.select(ManageMediaState.getMediaAssets());

    ngOnInit() {
        this.form.patchValue({ mediaAssetId: this.data });
    }

    onPreviewMediaAsset(model: MediaAsset) {
        this.store.dispatch(new HomeActions.PreviewMediaAsset(model.rowKey));
    }

    onSave() {
        this.dialog.close(this.form.get('mediaAssetId')?.value || '');
    }

    onCancel() {
        this.dialog.close(false);
    }
}
