import { InjectionToken } from '@angular/core';

import { Idle } from 'idle.ts/src/js/idle';
import { Environment } from './models';

export const APP_WINDOW = new InjectionToken<Window>('AppWindow');
export const APP_ENVIRONMENT = new InjectionToken<Environment>('AppEnvironment');
export const APP_DEBUG = new InjectionToken<boolean>('AppDebug');
export const APP_AUTOUPDATE = new InjectionToken<boolean>('AppAutoUpdate');
export const APP_TIPTAP_EDITOR_CONFIG = new InjectionToken<any>('AppTipTapEditorConfig');
export const APP_INITIAL_POINTER_EVENT = new InjectionToken<any>('AppInitialPointerEvent');
export const APP_FPJS_TOKEN = new InjectionToken<string>('AppFpjsToken');
export const APP_DOCUMENT = new InjectionToken<Document>('AppDocument');
export const APP_IDLE_SERVICE = new InjectionToken<Idle>('AppIdle');
export const VIDEOJS_DEFAULT_CONFIGURATION = new InjectionToken<any>('AppVideojsDefaultConfigFactory');

