import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { EMPTY, from, Observable, of, timer } from 'rxjs';
import { catchError, filter, finalize, mergeMap, take, tap } from 'rxjs/operators';

import { CoreActions, NotificationActions } from '@app/data/actions';
import { AudioBinary, EncodingJob, MediaAsset } from '@app/data/models';
import { BlobService } from '@app/data/services/blob.service';
import { AuthState } from '@app/data/state/auth.state';
import { ToastrProgressComponent } from '@app/shared/components/toastr-progress/toastr-progress.component';
import { BlobContainer, ErrorCodes, MediaAssetType } from '@app/shared/enums';
import { IAction, isErrorModel } from '@app/shared/models';
import { isArray, isFunction } from '@app/shared/util';
import { Action, createSelector, State, StateContext, Store } from '@ngxs/store';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import { ManageMediaActions } from '../actions';
import { ToastrEncodingJobComponent } from '../components/toastr-encoding-job/toastr-encoding-job.component';
import { DeleteMediaAssetConfirmationDialogComponent } from '../dialogs/delete-media-asset-confirmation/delete-media-asset-confirmation-dialog.component';
import { RenameMediaAssetDialogComponent } from '../dialogs/rename-media-asset/rename-media-asset-dialog.component';
import { EncodingJobsService } from '../services/encoding-jobs.service';
import { HomeState } from './home.state';
import { ManageMediaService } from '../services/manage-media.service';

export interface StateModel {
    hasLoaded: boolean;
    isLoading: boolean;
    isSaving: boolean;
    hasLoadedMediaAssets: boolean;
    errors: Record<string, unknown>;
    mediaAssets: MediaAsset[];
    encodingJobs: EncodingJob[];
    isNormalizing: boolean;
    isDownloading: boolean;
    normalizedAudio: AudioBinary | null;
    normalizeProgress: number;
}

@State<StateModel>({
    name: 'manageMedia',
    defaults: {
        hasLoaded: false,
        isLoading: false,
        isSaving: false,
        hasLoadedMediaAssets: false,
        errors: {},
        mediaAssets: [],
        encodingJobs: [],
        normalizeProgress: 0,
        isNormalizing: false,
        isDownloading: false,
        normalizedAudio: null,
    },
})
@Injectable({
    providedIn: 'root',
})
export class ManageMediaState {
    private store = inject(Store);
    private dialog = inject(MatDialog);
    private blobService = inject(BlobService);
    private toastr = inject(ToastrService);
    private manageMediaService = inject(ManageMediaService);
    private encodingJobService = inject(EncodingJobsService);

    static getErrors() {
        return createSelector([ManageMediaState], (state: StateModel) => state.errors);
    }

    static isNormalizing() {
        return createSelector([ManageMediaState], (state: StateModel) => state.isNormalizing);
    }

    static getNormalizedAudio() {
        return createSelector([ManageMediaState], (state: StateModel) => state.normalizedAudio);
    }

    static getMediaAssets() {
        return createSelector([ManageMediaState], (state: StateModel) => state.mediaAssets);
    }

    static getEncodingJobs() {
        return createSelector([ManageMediaState], (state: StateModel) => state.encodingJobs);
    }

    static isDownloading() {
        return createSelector([ManageMediaState], (state: StateModel) => state.isDownloading);
    }

    static hasLoadedMediaAssets() {
        return createSelector([ManageMediaState], (state: StateModel) => state.hasLoadedMediaAssets);
    }

    ngxsOnInit({ dispatch }: StateContext<StateModel>) {
        this.store
            .select(AuthState.isAuthenticated())
            .pipe(
                filter(isAuthenticated => isAuthenticated),
                take(1),
                mergeMap(() => dispatch(new ManageMediaActions.LoadEncodingJobs())),
            )
            .subscribe();
    }

    @Action(ManageMediaActions.EnsureLoadMediaAssets)
    ensureLoadMediaAssets({ patchState, dispatch }: StateContext<StateModel>): Observable<any> | void {
        const hasLoadedMediaAssets = this.store.selectSnapshot(ManageMediaState.hasLoadedMediaAssets());

        if (hasLoadedMediaAssets) {
            return;
        }

        patchState({ hasLoadedMediaAssets: true });

        dispatch(new ManageMediaActions.LoadMediaAssets());
    }

    @Action(CoreActions.Refresh)
    autoRefresh({ patchState, dispatch }: StateContext<StateModel>) {
        const isAuthenticated = this.store.selectSnapshot(AuthState.getCurrentUser());

        if (isAuthenticated) {
            dispatch(new ManageMediaActions.LoadEncodingJobs());
        }
    }

    @Action(ManageMediaActions.LoadEncodingJobs)
    loadEncodingJobs({ dispatch, patchState }: StateContext<StateModel>) {
        return this.encodingJobService.getEncodingJobs().pipe(
            tap(jobs => {
                const previousEncodingJobs = this.store.selectSnapshot(ManageMediaState.getEncodingJobs());

                // remove any jobs that are queued
                patchState({
                    encodingJobs: jobs.concat(
                        previousEncodingJobs.filter(
                            j => j.rowKey === '' && !jobs.some(j2 => j2.mediaAssetId === j.mediaAssetId),
                        ),
                    ),
                });

                dispatch(new ManageMediaActions.LoadEncodingJobsSuccess(previousEncodingJobs, jobs));
            }),
            catchError(error => dispatch(new ManageMediaActions.LoadEncodingJobsFailure(error))),
        );
    }

    @Action(ManageMediaActions.LoadEncodingJobsSuccess)
    loadEncodingJobsSuccess(
        { dispatch, patchState }: StateContext<StateModel>,
        action: ManageMediaActions.LoadEncodingJobsSuccess,
    ): Observable<any> | void {
        const previous = action.previous.map(s => s.rowKey);
        const current = action.current.map(s => s.rowKey);

        const selectedNode = this.store.selectSnapshot(HomeState.getCurrentNode());
        const removedAssets = previous.filter(rowKey => !current.includes(rowKey));

        if (removedAssets.length > 0) {

            removedAssets.forEach(rowKey => {
                const toast =
                    this.toastr.toasts.find(
                        t => t.toastRef.componentInstance && t.toastRef.componentInstance?.state?.rowKey === rowKey,
                    ) || null;

                if (toast) {
                    toast.toastRef.close();
                }
            });
        }

        if (current.length > 0) {
            action.current.forEach(job => {
                if (job.acknowledged) {
                    return;
                }

                let toast =
                    this.toastr.toasts.find(
                        t =>
                            t.toastRef.componentInstance &&
                            (t.toastRef.componentInstance as ToastrEncodingJobComponent).encodingJob?.rowKey === job.rowKey,
                    ) || null;

                if (!toast) {
                    toast = this.toastr.success('', '', {
                        toastComponent: ToastrEncodingJobComponent,
                        disableTimeOut: true,
                        positionClass: 'toast-bottom-right',
                    });

                    const instance = toast.toastRef.componentInstance as ToastrEncodingJobComponent;
                    instance.registerCancel(() => {
                        dispatch([new ManageMediaActions.CancelEncodingJob(job)]);
                    });
                    instance.registerCompleted(() => {
                        dispatch([new ManageMediaActions.AcknowledgeEncodingJob(job)]);
                        toast?.toastRef.close();
                    });
                    instance.registerCopy(text => {
                        this.store.dispatch(new CoreActions.CopyToClipboard({ text, message: 'Copied to clipboard' }));
                    });
                }

                (toast as ActiveToast<ToastrEncodingJobComponent>).toastRef.componentInstance.updateProgress(job);
            });
        }

        if (!selectedNode || previous.every(rowKey => selectedNode.mediaAssetId !== rowKey)) {
            return;
        }

        previous.forEach(rowKey => {
            if (!current.includes(rowKey) && selectedNode.mediaAssetId === rowKey) {
                // reload the video
                // dispatch(new HomeActions.GetStreamingUrl(selectedNode));
            }
        });
    }

    @Action(ManageMediaActions.AcknowledgeEncodingJob)
    acknowledgeEncodingJob(ctx: StateContext<StateModel>, { job }: ManageMediaActions.AcknowledgeEncodingJob) {
        const { rowKey } = job;

        return this.encodingJobService.acknowledgeEncodingJob(rowKey).pipe(
            tap(() => {
                const toast =
                    this.toastr.toasts.find(
                        t => t.toastRef.componentInstance && t.toastRef.componentInstance?.state?.rowKey === rowKey,
                    ) || null;

                if (toast) {
                    toast.toastRef.close();
                }
            }),
            catchError(error => this.store.dispatch(new ManageMediaActions.AcknowledgeEncodingJobFailure(error))),
        );
    }

    @Action(ManageMediaActions.AddPlaceHolderJob)
    addPlaceHolderJob(ctx: StateContext<StateModel>, { job }: ManageMediaActions.AddPlaceHolderJob) {
        const encodingJobs = this.store.selectSnapshot(ManageMediaState.getEncodingJobs());

        encodingJobs.push(job);

        ctx.patchState({ encodingJobs });
    }

    @Action(ManageMediaActions.SendToEncodingQueue)
    sendToEncodingQueue({ dispatch }: StateContext<StateModel>, { model }: ManageMediaActions.SendToEncodingQueue) {
        return this.encodingJobService.createEncodingJobFromMediaAsset(model).pipe(
            mergeMap(() => timer(1000).pipe(mergeMap(() => dispatch(new ManageMediaActions.LoadEncodingJobs())))),
            catchError(error => this.store.dispatch(new ManageMediaActions.SendToEncodingQueueFailure(error))),
        );
    }

    @Action(ManageMediaActions.CancelAssetEncoding)
    cancelAssetEncoding(ctx: StateContext<StateModel>, action: ManageMediaActions.CancelAssetEncoding) {
        return this.manageMediaService.cancelMediaAsset(action.mediaAssetId);
    }

    @Action(ManageMediaActions.LoadMediaAssets)
    loadMediaAssets(
        { patchState, dispatch }: StateContext<StateModel>,
        { rowKeys }: ManageMediaActions.LoadMediaAssets,
    ): any {
        patchState({ isLoading: true });
        dispatch(new ManageMediaActions.ClearErrors(ManageMediaActions.LoadMediaAssetsFailure));

        return this.manageMediaService.getMediaAssets(rowKeys).pipe(
            tap(response => {
                if (rowKeys.length > 0) {
                    const mediaAssets = this.store.selectSnapshot(ManageMediaState.getMediaAssets());
                    // update the existing media assets with the new ones
                    const updatedAssets = mediaAssets.filter(m => !rowKeys.includes(m.rowKey)).concat(response);
                    patchState({ mediaAssets: updatedAssets });
                } else {
                    patchState({ mediaAssets: response });
                }
            }),
            catchError(error => dispatch(new ManageMediaActions.LoadMediaAssetsFailure(error))),
            finalize(() => patchState({ isLoading: false })),
        );
    }

    @Action(ManageMediaActions.DeleteMediaAssetConfirmation)
    deleteMediaAssetConfirmation(
        { dispatch }: StateContext<StateModel>,
        action: ManageMediaActions.DeleteMediaAssetConfirmation,
    ) {
        const asset = action.asset;
        const nodes = this.store.selectSnapshot(HomeState.getNodes()).filter(n => n.mediaAssetId === asset.rowKey);

        return this.dialog
            .open(DeleteMediaAssetConfirmationDialogComponent, {
                closeOnNavigation: false,
                disableClose: true,
                data: { asset, nodes },
            })
            .afterClosed()
            .pipe(
                mergeMap(result => {
                    if (result) {
                        return dispatch(new ManageMediaActions.DeleteMediaAsset(asset));
                    }

                    return of(null);
                }),
            );
    }

    @Action(ManageMediaActions.DeleteMediaAsset)
    deleteMediaAsset({ dispatch }: StateContext<StateModel>, action: ManageMediaActions.DeleteMediaAsset) {
        dispatch(new ManageMediaActions.ClearErrors(ManageMediaActions.DeleteMediaAssetFailure));

        return this.manageMediaService.deleteMediaAsset(action.asset.rowKey).pipe(
            mergeMap(result => {
                return dispatch([
                    new ManageMediaActions.RemoveMediaAsset(result.mediaAssetsToRemove),
                    new ManageMediaActions.DeleteMediaAssetSuccess(),
                    new NotificationActions.Success('Media has been successfully deleted'),
                ]);
            }),
            catchError(error => dispatch(new ManageMediaActions.DeleteMediaAssetFailure(error))),
        );
    }

    @Action(ManageMediaActions.RenameMediaAsset)
    renameMediaAsset({ dispatch }: StateContext<StateModel>, action: ManageMediaActions.RenameMediaAsset) {
        return this.dialog
            .open(RenameMediaAssetDialogComponent, {
                data: { ...action.asset },
            })
            .afterClosed()
            .pipe(
                mergeMap(result => {
                    if (result) {
                        return this.manageMediaService
                            .renameMediaAsset(action.asset.rowKey, result.name)
                            .pipe(mergeMap(asset => dispatch(new ManageMediaActions.UpdateMediaAsset(asset))));
                    }

                    return EMPTY;
                }),
            );
    }

    @Action(ManageMediaActions.DownloadMediaAsset)
    downloadMediaAsset({ dispatch }: StateContext<StateModel>, action: ManageMediaActions.DownloadMediaAsset) {
        const prefix = `media-assets/${action.asset.rowKey}/`;

        return from(this.blobService.listBlobs(prefix)).pipe(
            mergeMap(blobs => {
                const blob = blobs.find(b => b.name.endsWith('.original'));

                if (blob) {
                    return from(this.blobService.getBlobUri(blob.name, BlobContainer.Private)).pipe(
                        tap(uri => {
                            const name = blob.name.split('/').pop() || blob.name;

                            const link = document.createElement('a');
                            link.setAttribute('href', uri);
                            link.setAttribute('download', `${name.replace('.original', '')}`);
                            link.setAttribute('target', '_blank');
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }),
                    );
                } else {
                    dispatch(new NotificationActions.Error('Original asset not found'));
                }

                return of(null);
            }),
        );
    }

    @Action(ManageMediaActions.DownloadMediaAssetBinary)
    downloadMediaAssetBinary(
        ctx: StateContext<StateModel>,
        { asset, assetName }: ManageMediaActions.DownloadMediaAssetBinary,
    ): Observable<any> | void {
        const isDownloading = this.store.selectSnapshot(HomeState.isDownloading());

        if (isDownloading) {
            return;
        }

        ctx.patchState({ isDownloading: true });
        const link = document.createElement('a');
        let uri: string | null = null;

        return this.manageMediaService.downloadMediaAsset(asset.rowKey, assetName).pipe(
            tap(response => {
                const blob = new Blob([response.body as any], { type: 'application/octet-binary' });
                uri = URL.createObjectURL(blob);
                link.setAttribute('href', uri);
                link.setAttribute('download', `${assetName}`);
                document.body.appendChild(link);
                link.click();
            }),
            catchError(error => this.store.dispatch(new ManageMediaActions.DownloadMediaAssetBinaryFailure(error))),
            finalize(() => {
                URL.revokeObjectURL(uri as string);
                document.body.removeChild(link);
                ctx.patchState({ isDownloading: false });
            }),
        );
    }

    @Action(ManageMediaActions.RemoveMediaAsset)
    removeMediaAsset({ patchState, getState }: StateContext<StateModel>, action: ManageMediaActions.RemoveMediaAsset) {
        const mediaAssets = getState().mediaAssets.filter(m => !action.mediaAssetIds.includes(m.rowKey));

        patchState({ mediaAssets });
    }

    @Action(ManageMediaActions.UpdateMediaAsset)
    updateMediaAsset({ patchState, getState }: StateContext<StateModel>, action: ManageMediaActions.UpdateMediaAsset) {
        const mediaAssets = [...getState().mediaAssets];
        const ix = mediaAssets.findIndex(p => p.rowKey === action.asset.rowKey);

        if (ix >= 0) {
            // update
            mediaAssets[ix] = { ...action.asset };
        } else {
            // add
            mediaAssets.push({ ...action.asset });
        }

        patchState({ mediaAssets });
    }

    @Action(ManageMediaActions.NormalizeWebmAudio)
    normalizeWebmAudio({ patchState, dispatch }: StateContext<StateModel>, { file }: ManageMediaActions.NormalizeWebmAudio) {
        patchState({ isNormalizing: true, normalizeProgress: 0 });

        const formData = new FormData();
        formData.append('audio', file);

        // clear all known errors for this job
        this.store.dispatch(new ManageMediaActions.ClearErrors([ManageMediaActions.NormalizeWebmAudioFailure]));

        const toast = this.toastr.success('', '', {
            toastComponent: ToastrProgressComponent,
            disableTimeOut: true,
            positionClass: 'toast-bottom-right',
        });

        const abortSignal = new AbortController();
        const instance = toast.toastRef.componentInstance as ToastrProgressComponent;
        instance.title = `Normalizing audio`;
        instance.message = `Uploading`;

        instance.registerCancel('Cancel', () => {
            abortSignal.abort();
            this.toastr.remove(toast.toastId);
        });

        return this.manageMediaService.normalizeWebmAudio(formData).pipe(
            tap((response: HttpEvent<Blob>) => {
                if (response.type === HttpEventType.UploadProgress || response.type === HttpEventType.DownloadProgress) {
                    const progress = Math.floor(100 * (response.loaded / (response?.total || 0)));
                    instance.updateProgress(progress);
                    patchState({ normalizeProgress: progress });

                    if (response.type === HttpEventType.DownloadProgress) {
                        instance.message = `Receiving recording`;
                    }
                } else if (response.type === HttpEventType.Response) {
                    const blob = response.body as Blob;
                    const contentType = response.headers.get('Content-Type') as string;
                    const nodeBinary = {
                        blob,
                        contentType,
                        name: file.name,
                        blobUri: URL.createObjectURL(blob),
                    };

                    patchState({
                        normalizedAudio: nodeBinary,
                    });

                    dispatch(new ManageMediaActions.NormalizeWebmAudioSuccess(nodeBinary));
                }
            }),
            catchError(error => dispatch(new ManageMediaActions.NormalizeWebmAudioFailure(error))),
            finalize(() => {
                this.toastr.remove(toast.toastId);
                patchState({ isNormalizing: false, normalizeProgress: 0 });
            }),
        );
    }

    @Action(ManageMediaActions.ClearAudioRecording)
    clearAudioRecording({ patchState }: StateContext<StateModel>): void {
        patchState({ normalizedAudio: null });
    }

    @Action(ManageMediaActions.CreateMediaAsset)
    createMediaAsset(
        { dispatch, patchState }: StateContext<StateModel>,
        { mediaType, asset, clientId }: ManageMediaActions.CreateMediaAsset,
    ) {
        return this.manageMediaService.createMediaAsset(asset, mediaType, MediaAssetType.Inline, false).pipe(
            mergeMap(asset => {
                return dispatch(new ManageMediaActions.CreateMediaAssetSuccess({ ...asset, clientId }));
            }),
        );
    }

    @Action([
        ManageMediaActions.RecordingFailure,
        ManageMediaActions.LoadMediaAssetsFailure,
        ManageMediaActions.SendToEncodingQueueFailure,
        ManageMediaActions.NormalizeWebmAudioFailure,
    ])
    handleFailures(ctx: StateContext<StateModel>, action: { error: any }): Observable<any> | void {
        switch (true) {
            case isErrorModel(action.error) && action.error.errorCode === ErrorCodes.NoConnection:
                {
                    //do nothing
                    console.log('NoConnection');
                }
                break;
            case action instanceof ManageMediaActions.LoadMediaAssetsFailure:
                return this.store.dispatch(
                    new ManageMediaActions.AddErrors(ManageMediaActions.LoadMediaAssetsFailure, action.error),
                );
            case action instanceof ManageMediaActions.RecordingFailure:
                return this.store.dispatch(
                    new ManageMediaActions.AddErrors(ManageMediaActions.RecordingFailure, action.error),
                );
            case action instanceof ManageMediaActions.SendToEncodingQueueFailure:
                return this.store.dispatch(new NotificationActions.Error('Failed to send to encoding queue', action.error));
            case action instanceof ManageMediaActions.NormalizeWebmAudioFailure:
                return this.store.dispatch(new NotificationActions.Error('Failed to normalize audio', action.error));
            default:
                return this.store.dispatch(
                    new NotificationActions.Error(`Unexpected error occurred ${action.error.message}`, action.error),
                );
        }
    }

    @Action(ManageMediaActions.ClearErrors)
    clearErrors(ctx: StateContext<StateModel>, { actions }: ManageMediaActions.ClearErrors) {
        let errors = { ...this.store.selectSnapshot(ManageMediaState.getErrors()) };

        if (isArray<IAction>(actions)) {
            actions.forEach(act => {
                errors[act.type] = null;
            });
        } else if (isFunction(actions)) {
            errors[actions.type] = null;
        } else {
            errors = {};
        }

        ctx.patchState({ errors });
    }

    @Action(ManageMediaActions.AddErrors)
    addErrors(ctx: StateContext<StateModel>, { action, error }: ManageMediaActions.AddErrors) {
        const errors = { ...this.store.selectSnapshot(ManageMediaState.getErrors()) };

        errors[action.type] = error;

        ctx.patchState({ errors });
    }
}
