import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { ConfirmDialogData } from '@app/data/models';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'admin-confirmation-dialog',
    template: `
        <h1 mat-dialog-title>{{ title }}</h1>
        <mat-dialog-content>
            <p>{{ message }}</p>
        </mat-dialog-content>

        <mat-dialog-actions>
            <button mat-button [mat-dialog-close]="false">{{ cancelText }}</button>
            <button mat-raised-button [color]="confirmColor" [mat-dialog-close]="true">{{ confirmText }}</button>
        </mat-dialog-actions>
    `,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
    ]
})
export class ConfirmationDialogComponent {
    private data = inject<ConfirmDialogData>(MAT_DIALOG_DATA);

    get title(): string {
        return this.data.title;
    }

    get message(): string {
        return this.data.message || '';
    }

    get cancelText(): string {
        return this.data.cancelText || 'Cancel';
    }

    get confirmText(): string {
        return this.data.confirmText || 'Confirm';
    }

    get confirmColor(): string {
        return this.data.confirmColor || 'warn';
    }
}
