import { Injectable, inject } from '@angular/core';
import { from, of, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable({
    providedIn: 'root',
})
export class ClipboardService {
    private clipboard = inject(Clipboard);


    copyToClipboard(item: string | ClipboardItem[] | undefined): Observable<boolean> {

        if (!item) {
            return of(false);
        }

        if (item instanceof Array) {
            return from(navigator.clipboard.write(item)).pipe(
                map(s => true),
                catchError(() => of(false)),
            );
        }

        return of(this.clipboard.copy(item)).pipe(
            mergeMap(result => (!result ? this.copyToClipboardViaApi(item) : of(true))),
            mergeMap(result => (!result ? this.copyToClipboardViaDom(item) : of(true))),
            mergeMap(result => (!result ? this.copyToClipboardViaPrompt(item) : of(true))),
        );
    }

    copyToClipboardViaApi(text: string) {
        return from(navigator.clipboard.writeText(text)).pipe(
            map(s => true),
            catchError(() => of(false)),
        );
    }

    copyToClipboardViaDom(text: string): Observable<boolean> {
        return new Observable(subscriber => {
            let textarea: HTMLTextAreaElement | null = document.createElement('textarea');
            let result = false;
            try {
                textarea.setAttribute('readonly', 'true');
                textarea.setAttribute('contenteditable', 'true');
                textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
                textarea.value = text;

                document.body.appendChild(textarea);

                textarea.focus();
                textarea.select();

                const range = document.createRange();
                range.selectNodeContents(textarea);

                const sel = window.getSelection();
                if (sel) {
                    sel.removeAllRanges();
                    sel.addRange(range);
                }

                textarea.setSelectionRange(0, textarea.value.length);
                result = document.execCommand('copy');
            } catch (err) {
                result = false;
            } finally {
                document.body.removeChild(textarea);
                textarea = null;
                subscriber.next(result);
                subscriber.complete();
            }
        });
    }

    copyToClipboardViaPrompt(text: string): Observable<boolean> {
        return new Observable(subscriber => {
            const isMac = navigator.userAgent.match(/ipad|ipod|iphone/i);
            const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
            subscriber.next(!!prompt(`Press ${copyHotkey}`, text)); // eslint-disable-line no-alert
            subscriber.complete();
        });
    }
}
