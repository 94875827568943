import { HttpErrorResponse } from '@angular/common/http';

import { EMPTY, from, MonoTypeOperatorFunction, Observable, ObservableInput, of, pipe, throwError, timer } from 'rxjs';
import { catchError, map, mergeMap, repeat, retry, tap } from 'rxjs/operators';

import { ErrorCodes } from '@app/shared/enums';
import { ErrorModel, IAction, ServerResult } from '@app/shared/models';
import { readAsText } from '@app/shared/util';
import { TransferProgressEvent } from '@azure/core-http';
import { BlobDownloadResponseParsed, BlobUploadCommonResponse, RestError } from '@azure/storage-blob';

export function pollWithBackoff<T>(delay: number, maxDelay: number): MonoTypeOperatorFunction<T> {
    return pipe(
        retry({
            delay: (_error, i) => {
                const backoffDelay = Math.min(delay * Math.pow(2, i - 1), maxDelay);
                return timer(backoffDelay);
            },
        }),
        repeat({
            delay,
        }),
    );
}

export const filterByErrorTypes = <T>(errorTypes: IAction[]) =>
    map((model: Record<string, unknown>) => {
        const result = {} as Record<string, T>;

        errorTypes.forEach(act => {
            if (model[act.type] !== undefined) {
                result[act.type] = model[act.type] as T;
            }
        });

        return result;
    });

export const verifyServerResult = <T>() =>
    map((source: ServerResult<T>) => {
        if (source.status === 1) {
            return new ServerResult<T>(source as any);
        }

        throw new ServerResult<T>(source);
    });

export const getData = <T>() => map((source: ServerResult<T>) => source.data);

export const handleReportProgress = (callback: (model: { loadedBytes: number; isComplete: boolean }) => void) =>
    tap((ev: TransferProgressEvent | BlobUploadCommonResponse) => {
        // check if the progress event has loadedBytes
        if ('loadedBytes' in ev) {
            callback({ loadedBytes: ev.loadedBytes, isComplete: false });
        } else if ('_response' in ev) {
            callback({ loadedBytes: 1, isComplete: true });
        }
    });

export const transformResponseToText = () =>
    mergeMap((source: BlobDownloadResponseParsed | Response) => {
        if (source && 'text' in source) {
            if (source.status === 200) {
                return from(source.text());
            } else if (source.status === 404) {
                return of('');
            }
        } else if (source && source.blobBody) {
            return from(source.blobBody).pipe(mergeMap(body => readAsText(body)));
        }

        return throwError(() => new Error('No blob body found'));
    });

export const handleNotFoundError = <T1, T2 extends ObservableInput<any>>(value: string = '') =>
    catchError<T1, T2>(err => {
        switch (true) {
            case err instanceof RestError && err.statusCode === 404: {
                return of(value) as unknown as T2;
            }
        }

        return throwError(() => err) as unknown as T2;
    });

export const handleServiceUnavailableError = <T1, T2 extends ObservableInput<any>>(handler: () => Observable<any>) =>
    catchError<T1, T2>(err => {
        switch (true) {
            case err instanceof HttpErrorResponse && (err.status === 0 || err.status === 503 || err.status === 504): {
                return handler().pipe(map(() => EMPTY)) as unknown as T2;
            }
            case err instanceof ErrorModel && (err.httpStatus === 0 || err.httpStatus === 503 || err.httpStatus === 504): {
                return handler().pipe(map(() => EMPTY)) as unknown as T2;
            }
        }

        return throwError(() => err) as unknown as T2;
    });

export const errorHandler = <T1, T2 extends ObservableInput<T1>>() =>
    catchError<T1, T2>((err: HttpErrorResponse | Error) => {
        if (err instanceof HttpErrorResponse) {
            if ((err.status === 0 && err.statusText === 'Unknown Error') || err.status === 504) {
                const result = new ServerResult(null);
                result.meta = {
                    errorCode: ErrorCodes.NoConnection,
                };

                return throwError(() => new ErrorModel(result));
            } else if (err.error && err.error instanceof ArrayBuffer) {
                const blob = new Blob([err.error], { type: err.headers.get('Content-Type') || undefined });
                const stream$ = readAsText(blob).pipe(
                    map(json => JSON.parse(json) as any),
                    map(result => {
                        throw new ErrorModel(new ServerResult(result));
                    }),
                );

                return stream$ as any;
            }
        }

        return throwError(() => new ErrorModel(err));
    });

export const genericRetryStrategy =
    ({
        maxRetryAttempts = 3,
        scalingDuration = 1000,
        excludedStatusCodes = [],
    }: {
        maxRetryAttempts?: number;
        scalingDuration?: number;
        excludedStatusCodes?: number[];
    } = {}) =>
    (attempts: Observable<any>) => {
        return attempts.pipe(
            mergeMap((error, i) => {
                const retryAttempt = i + 1;
                // if maximum number of retries have been met
                // or response is a status code we don't wish to retry, throw error
                if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find(e => e === error.status)) {
                    return throwError(() => error);
                }
                console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`);
                // retry after 1s, 2s, etc...
                return timer(retryAttempt * scalingDuration);
            }),
        );
    };