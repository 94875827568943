import { AfterViewInit, Directive, ElementRef, inject } from '@angular/core';

@Directive({
    selector: '[src]',
    standalone: true,
})
export class SrcUnloadDirective implements AfterViewInit {
    element = inject<ElementRef<HTMLImageElement>>(ElementRef);

    ngAfterViewInit(): void {
        this.element.nativeElement.addEventListener('load', () => {
            URL.revokeObjectURL(this.element.nativeElement.src);
        });
    }
}
