import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';

import { MatButton } from '@angular/material/button';

@Component({
    selector: 'shared-toastr-progress',
    templateUrl: `./toastr-progress.component.html`,
    styleUrls: [`./toastr-progress.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatProgressBar,
        MatButton
    ]
})
export class ToastrProgressComponent {
    private cd = inject(ChangeDetectorRef);

    progress = 0;
    title = '';
    message = '';

    isCancelVisible = false;
    isCancelEnabled = false;
    cancelText = '';
    cancelFn: () => void = () => {};

    registerCancel(text: string, cancel: () => void): void {
        this.isCancelVisible = true;
        this.isCancelEnabled = true;
        this.cancelText = text;
        this.cancelFn = cancel;
    }

    updateProgress(progress: number): void {
        this.progress = progress;
        this.cd.detectChanges();
    }

    onCancel(): void {
        this.isCancelEnabled = false;
        this.cancelFn();
    }
}
