<section>
    @if (!hasError()) {
    <mat-progress-spinner mode="determinate" diameter="50" [value]="progress()"></mat-progress-spinner>
    }
    <div class="status-container" [ngClass]="status()">
        <span class="status">{{ status() }}</span>
    </div>
    @if (hasError()) {
    <span class="error-message">{{ errorMessage() }}</span>
    } @if (showIndeterminateBar()) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
</section>
