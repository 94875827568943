import { Injectable, inject } from '@angular/core';

import {
    filter,
    finalize,
    from,
    fromEvent,
    interval,
    mergeMap,
    skip,
    take,
    takeUntil,
    tap,
    timer,
    withLatestFrom,
} from 'rxjs';

import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { Action, Actions, createSelector, NgxsOnInit, ofActionDispatched, State, StateContext, Store } from '@ngxs/store';

import { MediaPlayerActions } from '../actions';
import { AuthState } from './auth.state';

export interface StateModel {
    id: string | null;
    title: string | null;
    isPlaying: boolean;
    canPlay: boolean;
    duration: number;
    position: number;
    isLoading: boolean;
    hasInteracted: boolean;
    playbackRate: number;
    canSeek: boolean;
}

@State<StateModel>({
    name: 'mediaPlayer',
    defaults: {
        id: null,
        title: null,
        isPlaying: false,
        canPlay: false,
        duration: 0,
        position: 0,
        playbackRate: 1,
        isLoading: true,
        hasInteracted: false,
        canSeek: true,
    },
})
@Injectable()
export class MediaPlayerState implements NgxsOnInit {
    private env = inject<Environment>(APP_ENVIRONMENT);
    private store = inject(Store);
    private actions = inject(Actions);

    audioPlayer: HTMLAudioElement = new Audio();

    static isLoading() {
        return createSelector([MediaPlayerState], (state: StateModel) => state.isLoading);
    }

    static hasInteracted() {
        return createSelector([MediaPlayerState], (state: StateModel) => state.hasInteracted);
    }

    static getId() {
        return createSelector([MediaPlayerState], (state: StateModel) => state.id);
    }

    static isPlaying() {
        return createSelector([MediaPlayerState], (state: StateModel) => state.isPlaying);
    }

    static canPlay() {
        return createSelector([MediaPlayerState], (state: StateModel) => state.canPlay);
    }

    static getPostition() {
        return createSelector([MediaPlayerState], (state: StateModel) => state.position);
    }

    static getDuration() {
        return createSelector([MediaPlayerState], (state: StateModel) => state.duration);
    }

    static getPlaybackRate() {
        return createSelector([MediaPlayerState], (state: StateModel) => state.playbackRate);
    }

    static getTitle() {
        return createSelector([MediaPlayerState], (state: StateModel) => state.title);
    }

    static canSeek() {
        return createSelector([MediaPlayerState], (state: StateModel) => state.canSeek);
    }

    ngxsOnInit({ getState, patchState, dispatch }: StateContext<StateModel>): void {
        interval(100)
            .pipe(
                filter(() => getState().isPlaying),
                tap(() => {
                    patchState({
                        position: this.audioPlayer?.currentTime || 0,
                    });
                }),
            )
            .subscribe();

        fromEvent(this.audioPlayer as HTMLAudioElement, 'canplay')
            .pipe(
                tap(() => {
                    patchState({
                        canPlay: true,
                        duration: this.audioPlayer?.duration || 0,
                    });
                }),
            )
            .subscribe();

        fromEvent(this.audioPlayer as HTMLAudioElement, 'ended')
            .pipe(
                tap(() => {
                    patchState({
                        isPlaying: false,
                        position: 0,
                        hasInteracted: false,
                    });

                    dispatch(new MediaPlayerActions.PlaybackEnded());
                }),
            )
            .subscribe();
    }

    @Action(MediaPlayerActions.LoadAudio)
    loadAudio(
        { patchState, dispatch }: StateContext<StateModel>,
        { id, title, src, canSeek, startTime, reportProgress }: MediaPlayerActions.LoadAudio,
    ) {
        patchState({
            id,
            title,
            isPlaying: false,
            isLoading: true,
            canPlay: false,
            hasInteracted: false,
            duration: 0,
            position: 0,
            canSeek: canSeek,
        });

        const token = this.store.selectSnapshot(AuthState.getToken());

        this.audioPlayer.pause();

        if (reportProgress) {
            timer(0, 5000)
                .pipe(
                    takeUntil(
                        this.actions.pipe(
                            skip(1), // skip the first action, no clue why it's being dispatched
                            ofActionDispatched(MediaPlayerActions.LoadAudio),
                            take(1),
                            finalize(() => {
                                console.log('action dispatched completed');
                            }),
                        ),
                    ),
                    withLatestFrom(
                        this.store.select(MediaPlayerState.getId()),
                        this.store.select(MediaPlayerState.isPlaying()),
                        this.store.select(MediaPlayerState.getPostition()),
                    ),
                    tap(([_, id, isPlaying, position]) => {
                        if (isPlaying) {
                            dispatch(new MediaPlayerActions.ReportProgress(id, position));
                        }
                    }),
                    finalize(() => {
                        console.log('timer completed');
                    }),
                )
                .subscribe();
        }

        return from(this.fetchAudio(src, token?.token)).pipe(
            mergeMap(objectUrl => {
                this.audioPlayer.src = objectUrl;
                this.audioPlayer.load();

                return dispatch(new MediaPlayerActions.PlayMedia(startTime));
            }),
            finalize(() => patchState({ isLoading: false })),
        );
    }

    @Action(MediaPlayerActions.PlayMedia)
    playMedia({ patchState, getState }: StateContext<StateModel>, { startTime }: MediaPlayerActions.PlayMedia) {
        if (startTime > 0) {
            this.audioPlayer.currentTime = startTime;
        }

        if (this.audioPlayer.paused) {
            this.audioPlayer.play();
        }

        patchState({ isPlaying: true, hasInteracted: true });
    }

    @Action(MediaPlayerActions.PauseMedia)
    pauseMedia({ patchState, getState }: StateContext<StateModel>) {
        this.audioPlayer.pause();
        patchState({ isPlaying: false, hasInteracted: true });
    }

    @Action(MediaPlayerActions.SeekMedia)
    seekMedia({ patchState, getState }: StateContext<StateModel>, { amount }: MediaPlayerActions.SeekMedia) {
        const { canSeek } = getState();

        if (!canSeek) {
            return;
        }

        this.audioPlayer.currentTime = this.audioPlayer.currentTime + amount;
    }

    @Action(MediaPlayerActions.PlaybackRate)
    playbackRate({ patchState, getState }: StateContext<StateModel>, { rate }: MediaPlayerActions.PlaybackRate) {
        this.audioPlayer.playbackRate = rate;
        patchState({ playbackRate: rate });
    }

    async fetchAudio(src: string | File, token: string | null | undefined): Promise<string> {
        if (src instanceof File) {
            return URL.createObjectURL(src);
        }

        const options = { headers: {} };

        if (token && src.includes(this.env.privateContainerName)) {
            options['headers'] = { Authorization: `Bearer ${token}` };
        }

        const response = await fetch(src, options);
        const blob = await response.blob();
        return URL.createObjectURL(blob);
    }
}
