import {
    FormLinkModel,
    FormLinkSearchResult,
    FormLinkTemplateModel,
    LinkSessionSummary,
    SearchFormOptions,
    SearchOptionsModel,
    Submission,
} from '@app/data/models';
import { Timezone } from 'timezones.json';

const ns = '[Forms]';

export class Save {
    static readonly type = `${ns} Save`;
}

export class SaveFailure {
    static readonly type = `${ns} Save Failure`;
    constructor(public error: any) {}
}

export class SearchLinks {
    static readonly type = `${ns} Search Links`;
    constructor(public model: SearchFormOptions) {}
}

export class SearchLinksFailure {
    static readonly type = `${ns} Search Links Failure`;
    constructor(public error: any) {}
}

export class LoadLinkSessions {
    static readonly type = `${ns} Load Link Sessions`;
    constructor(public rowKey?: string) {}
}

export class LoadLinkSessionsFailure {
    static readonly type = `${ns} Load Link Sessions Failure`;
    constructor(public error: any) {}
}

export class LoadLinkSubmissions {
    static readonly type = `${ns} Load Link Submissions`;
    constructor(public rowKey?: string) {}
}

export class LoadLinkSubmissionsFailure {
    static readonly type = `${ns} Load Link Submissions Failure`;
    constructor(public error: any) {}
}

export class LoadArchivedLink {
    static readonly type = `${ns} Load Archived Link`;
    constructor(public rowKey: string) {}
}
export class LoadArchivedLinkSuccess {
    static readonly type = `${ns} Load Archived Link Success`;
}
export class LoadArchivedLinkFailure {
    static readonly type = `${ns} Load Archived Link Failure`;
    constructor(public error: any) {}
}

export class LoadArchivedLinkSessions {
    static readonly type = `${ns} Load Archived Link Sessions`;
    constructor(public rowKey?: string | null) {}
}

export class LoadArchivedLinkSessionsFailure {
    static readonly type = `${ns} Load Archived Link Sessions Failure`;
    constructor(public error: any) {}
}

export class Error {
    static readonly type = `${ns} Error`;
    constructor(public error: any, public source: any) {}
}

export class ActivateSession {
    static readonly type = `${ns} Activate Session`;
    constructor(public rowKey: string) {}
}

export class ActivateSessionFailure {
    static readonly type = `${ns} Activate Session Failure`;
    constructor(public error: any) {}
}

export class DeactivateSession {
    static readonly type = `${ns} Deactivate Session`;
    constructor(public rowKey: string) {}
}

export class DeactivateSessionFailure {
    static readonly type = `${ns} Deactivate Session Failure`;
    constructor(public error: any) {}
}

export class UpdateSessionCount {
    static readonly type = `${ns} Update Session Count`;
    constructor(public rowKey: string) {}
}

export class UpdateSessionCountFailure {
    static readonly type = `${ns} Update Session Count Failure`;
    constructor(public error: any) {}
}

export class ConfirmMergeSession {
    static readonly type = `${ns} Confirm Merge Sessions`;
    constructor(public rowKeys: string[]) {}
}

export class MergeSessions {
    static readonly type = `${ns} Merge Sessions`;
    constructor(public primaryRowKey: string, public rowKeys: string[]) {}
}

export class MergeSessionsFailure {
    static readonly type = `${ns} Merge Sessions Failure`;
    constructor(public error: any) {}
}

export class ConfirmDeleteSession {
    static readonly type = `${ns} Confirm Delete Session`;
    constructor(public rowKeys: string[]) {}
}

export class DeleteSessions {
    static readonly type = `${ns} Delete Session`;
    constructor(public rowKeys: string[]) {}
}

export class DeleteSessionFailure {
    static readonly type = `${ns} Delete Session Failure`;
    constructor(public error: any) {}
}

export class ApproveSessions {
    static readonly type = `${ns} Approve Sessions`;
    constructor(public rowKeys: string[], public linkId: string) {}
}

export class ApproveSessionsFailure {
    static readonly type = `${ns} Approve Sessions Failure`;
    constructor(public error: any) {}
}

export class SyncActiveSessions {
    static readonly type = `${ns} Sync Active Sessions`;
    constructor(public rowKey: string) {}
}

export class SyncActiveSessionsFailure {
    static readonly type = `${ns} Sync Active Sessions Failure`;
    constructor(public error: any) {}
}

export class ArchiveLinks {
    static readonly type = `${ns} Archive Link`;
    constructor(public links: FormLinkSearchResult[] | FormLinkModel[]) {}
}

export class ArchiveLinksFailure {
    static readonly type = `${ns} Archive Link Failure`;
    constructor(public error: any) {}
}

export class ConfirmArchiveLinks {
    static readonly type = `${ns} Confirm Archive Link`;
    constructor(public links: FormLinkSearchResult[] | FormLinkModel[]) {}
}

export class ConfirmDeleteLinks {
    static readonly type = `${ns} Confirm Delete Link`;
    constructor(public links: FormLinkSearchResult[]) {}
}

export class ConfirmCancelScheduledEvents {
    static readonly type = `${ns} Confirm Cancel Scheduled Events`;
    constructor(public sessions: LinkSessionSummary[]) {}
}

export class CancelScheduledEvents {
    static readonly type = `${ns} Cancel Scheduled Events`;
    constructor(public sessions: LinkSessionSummary[]) {}
}

export class CancelScheduledEventsFailure {
    static readonly type = `${ns} Cancel Scheduled Events Failure`;
    constructor(public error: any) {}
}

export class DeleteLinks {
    static readonly type = `${ns} Delete Link`;
    constructor(public links: FormLinkModel[] | FormLinkSearchResult[]) {}
}

export class DeleteLinksFailure {
    static readonly type = `${ns} Delete Link Failure`;
    constructor(public error: any) {}
}

export class CopyLink {
    static readonly type = `${ns} Copy Link`;
    constructor(public link: FormLinkModel | FormLinkSearchResult, public isTemplate = false) {}
}

export class CopyLinkToClipboard {
    static readonly type = `${ns} Copy Link to Clipboard`;
    constructor(
        public link: FormLinkModel | FormLinkSearchResult,
        public timezone: Timezone,
        public includeInfluencer: boolean = false,
    ) {}
}

export class OpenLink {
    static readonly type = `${ns} Open Link`;
    constructor(public link: FormLinkSearchResult) {}
}

export class SetCurrentTabIndex {
    static readonly type = `${ns} Set Current Tab Index`;
    constructor(public index: number) {}
}

export class SearchArchivedLinks {
    static readonly type = `${ns} Search Archived Links`;
    constructor(public model: SearchFormOptions) {}
}

export class SearchArchivedLinksFailure {
    static readonly type = `${ns} Search Archived Links Failure`;
    constructor(public error: any) {}
}
export class UploadFile {
    static readonly type = `${ns} Upload File`;
    constructor(public fileId: string, public getFile: () => File) {}
}

export class UpdateLinkSummary {
    static readonly type = `${ns} Update Link Summary`;
}

export class UpdateLinkSummaryFailure {
    static readonly type = `${ns} Update Link Summary Failure`;
    constructor(public err: any) {}
}

export class CompleteSubmission {
    static readonly type = `${ns} Complete Submission`;
    constructor(public submission: Submission) {}
}

export class CompleteSubmissionFailure {
    static readonly type = `${ns} Complete Submission Failure`;
    constructor(public err: any) {}
}

export class ShowExtendLinkDialog {
    static readonly type = `${ns} Show Extend Link Dialog`;
    constructor(public links: FormLinkModel[] | FormLinkSearchResult[]) {}
}

export class ExtendLinkExpirationDate {
    static readonly type = `${ns} Extend Link Expiration Date`;
    constructor(public links: FormLinkModel[] | FormLinkSearchResult[], public days: number) {}
}

export class ExtendLinkExpirationDateFailure {
    static readonly type = `${ns} Extend Link Expiration Date Failure`;
    constructor(public error: any) {}
}

export class CopyFormLinkTemplate {
    static readonly type = `${ns} Create Link From Template`;
    constructor(public template: FormLinkTemplateModel) {}
}

export class AcknowledgeInfluencerNotification {
    static readonly type = `${ns} Acknowledge Influencer Notifications`;
    constructor(public rowKeys: string[]) {}
}

export class AcknowledgeInfluencerNotificationFailure {
    static readonly type = `${ns} Acknowledge Influencer Notifications Failure`;
    constructor(public error: any) {}
}

export class LoadSubmissionMessagesForLink {
    static readonly type = `${ns} Load Submission Messages For Link`;
    constructor(public link: FormLinkModel) {}
}

export class SaveFormSubmissionMessages {
    static readonly type = `${ns} Save Form Submission Messages`;
    constructor(public link: FormLinkModel) {}
}

export class LoadLink {
    static readonly type = `${ns} Load Link`;
    constructor(public linkId: string) {}
}

export class UpdateEditLinkModel {
    static readonly type = `${ns} Update Edit Link Model`;
    constructor(public model: Partial<FormLinkModel>) {}
}

export class EnsureLoadTags {
    static readonly type = `${ns} Ensure Load Tags`;
}

export class LoadTags {
    static readonly type = `${ns} Load Tags`;
}

export class LoadTagsFailure {
    static readonly type = `${ns} Load Tags Failure`;
    constructor(public error: any) {}
}
