import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { Credentials } from '@app/data/models';
import { SignInMethod } from '../../enums';
import { ErrorDisplayComponent } from '../error-display/error-display.component';

@Component({
    selector: 'shared-login-form',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
    imports: [
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatError,
        MatButton,
        MatProgressSpinner,
        MatIcon,
        ErrorDisplayComponent,
    ]
})
export class LoginFormComponent implements OnChanges {
    form: FormGroup = new FormGroup({
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
    });

    SignInMethod: typeof SignInMethod = SignInMethod;

    @Input() isPending: boolean | null = false;
    @Input() error: any;

    @Output() readonly login: EventEmitter<Credentials> = new EventEmitter();

    get username(): AbstractControl {
        return this.form.controls['username'];
    }

    get password(): AbstractControl {
        return this.form.controls['password'];
    }

    onLogin(): void {
        if (!this.form.valid) {
            this.form.updateValueAndValidity();
            return;
        }

        this.login.emit(this.form.value);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.isPending) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }
}
