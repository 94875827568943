import { SelectionModel } from '@angular/cdk/collections';
import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    input,
    OnChanges,
    output,
    signal,
    SimpleChanges,
    TrackByFunction,
    ViewChild,
} from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatTableDataSource,
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';

import { QueryResult, SearchOptionsModel, ShareableLinkModel } from '@app/data/models';
import { ErrorDisplayComponent } from '@app/shared/components/error-display/error-display.component';
import { DayOfWeek, LinkExpiration, LinkInactivityMode, LinkStatus, RegistrationMode, TimerUnit } from '@app/shared/enums';
import { AnyPipe } from '@app/shared/pipes/any.pipe';
import { ExpiresPipe } from '@app/shared/pipes/expires.pipe';
import { FormatDatePipe } from '@app/shared/pipes/format-date.pipe';
import { TimerUnitPipe } from '@app/shared/pipes/timer-unit.pipe';
import { DEFAULT_TIME_ZONE, getFormSubmissionResetDate, getTimeZoneByIanaTimeZone, isDate } from '@app/shared/util';
import timezones, { Timezone } from 'timezones.json';

import { FilterConfig, FilterModel, LinkFilterComponent } from '@app/shared/components/link-filter/link-filter.component';

const DEFAULT_PAGE_SIZE = 25;

interface ContentLinkModelViewModel extends ShareableLinkModel {
    isActivated: boolean;
    isStale: boolean;
    isExpired: boolean;
    hasStarted: boolean;
    hasDates: boolean;
    hasTimer: boolean;
    displayAs: DisplayAs;
    offset: number;
    expires: Date;
    displayTimezone: Timezone;
    resetDisplay: string;
    resetStartDate: Date;
    // displayExpirationDate: Date;
}

enum DisplayAs {
    None,
    StartDate,
    Timer,
    RegistrationCutOff,
    RegistrationReset,
}

@Component({
    selector: 'admin-archived-link-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./archived-link-list.component.html`,
    styleUrls: [`./archived-link-list.component.scss`],
    imports: [
        RouterLink,
        NgClass,
        MatChipListbox,
        MatChipOption,
        MatIcon,
        MatTable,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatMenu,
        MatMenuItem,
        MatCheckbox,
        MatIconButton,
        MatMenuTrigger,
        MatCellDef,
        MatCell,
        MatBadge,
        MatTooltip,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatPaginator,
        AnyPipe,
        ExpiresPipe,
        TimerUnitPipe,
        FormatDatePipe,
        ErrorDisplayComponent,
        LinkFilterComponent,
    ]
})
export class ArchivedLinkListComponent implements OnChanges {
    DisplayAs: typeof DisplayAs = DisplayAs;
    TimerUnit: typeof TimerUnit = TimerUnit;
    LinkInactivityMode: typeof LinkInactivityMode = LinkInactivityMode;

    trackBy = (): TrackByFunction<ShareableLinkModel> => {
        return (ix, model) => model.rowKey;
    };

    readonly error = input<any>();
    readonly isLoading = input<boolean | null>(false);
    readonly queryResult = input<QueryResult<ShareableLinkModel> | null>(null);
    readonly searchOptions = input<SearchOptionsModel | null>(null);
    readonly filter = input<string | null>(null);
    readonly tags = input<string[] | null>([]);

    readonly copyLink = output<ShareableLinkModel>();
    readonly view = output<ShareableLinkModel>();
    readonly delete = output<ShareableLinkModel[]>();
    readonly statusChange = output<{ linkIds: string[]; status: LinkStatus }>();
    readonly refresh = output<SearchOptionsModel>();

    cd = inject(ChangeDetectorRef);

    dataSource: MatTableDataSource<ContentLinkModelViewModel>;
    selection: SelectionModel<ContentLinkModelViewModel> = new SelectionModel<ContentLinkModelViewModel>(true, []);

    _displayedColumns: string[] = ['select', 'description', 'details', 'expires', 'archived', 'actions'];
    filterColumns: FilterConfig[] = [
        {
            type: 'select',
            key: 'displayedColumns',
            label: 'Displayed Columns',
            multiple: true,
            options: [
                { key: 'index', value: 'Id' },
                { key: 'description', value: 'Description' },
                { key: 'details', value: 'Details' },
                { key: 'expires', value: 'Expires' },
                { key: 'archived', value: 'Archived' },
                { key: 'timestamp', value: 'Timestamp' },
            ],
            default: ['description', 'details', 'archived'],
        },
    ];
    filterModel = signal<FilterModel>({
        filter: '',
        tags: [],
        displayedColumns: [],
    });

    get pageSize() {
        return this.searchOptions()?.limit || DEFAULT_PAGE_SIZE;
    }

    get pageIndex() {
        const options = this.searchOptions();
        if (options) {
            return options.offset / this.pageSize;
        }

        return 0;
    }

    get total() {
        const queryResult = this.queryResult();
        return queryResult?.total;
    }

    get displayedColumns(): string[] {
        return this._displayedColumns;
    }

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    onFilterChange(value: FilterModel) {
        switch (value['event']) {
            case 'init':
                this.updateDisplayedColumns(value['displayedColumns']);
                this.onRefresh({
                    filter: value.filter || '',
                    tags: value.tags || [],
                    limit: this.pageSize,
                    total: null,
                    offset: 0,
                });
                break;
            case 'displayedColumns':
                this.updateDisplayedColumns(value['displayedColumns']);
                break;
            case 'filter':
            case 'tags':
            case 'refresh':
                this.onRefresh({
                    filter: value.filter || '',
                    tags: value.tags || [],
                    limit: this.pageSize,
                    total: null,
                    offset: 0,
                });
                break;
        }
    }

    onRefresh(options: SearchOptionsModel) {
        this.paginator.firstPage();
        this.refresh.emit(options);
    }

    updateDisplayedColumns(columns: string[]) {
        this._displayedColumns = columns;

        if (!this._displayedColumns.includes('actions')) {
            this._displayedColumns.push('actions');
        }

        if (!this._displayedColumns.includes('select')) {
            this._displayedColumns = ['select'].concat(this._displayedColumns);
        }

        this.cd.markForCheck();
    }

    onSelectAllChange(event: MatCheckboxChange): void {
        const pageSize = this.paginator.pageSize;
        const pageIndex = this.paginator.pageIndex;
        const recordsToSkip = Math.max(0, pageIndex * pageSize);
        const records = this.dataSource.filteredData.slice(recordsToSkip, recordsToSkip + pageSize);

        records.forEach(r => {
            if (event.checked) {
                this.selection.select(r);
            } else {
                this.selection.deselect(r);
            }
        });
    }

    areAllSelected(): boolean {
        if (!this.paginator || this.dataSource.filteredData.length === 0) {
            return false;
        }

        return this.dataSource.filteredData.every(r => this.selection.isSelected(r));
    }

    areAnySelected(): boolean {
        if (!this.paginator || this.dataSource.filteredData.length === 0) {
            return false;
        }

        return this.dataSource.filteredData.some(r => this.selection.isSelected(r)) && !this.areAllSelected();
    }

    updateDataSource() {
        let items: ContentLinkModelViewModel[] = [];
        this.selection.clear(); // remove any old selected values.

        const queryResult = this.queryResult();

        if (queryResult && queryResult.items.length > 0) {
            items = queryResult.items.map(link => {
                const hasDates = isDate(link.startDate) && isDate(link.endDate);
                const hasTimer = link.timer && link.timer > 0;

                const displayTimezone = getTimeZoneByIanaTimeZone(link.timeZone || DEFAULT_TIME_ZONE);

                const activationDate = link.activationDate ? link.activationDate : null;
                const isActivated = activationDate !== null;
                const now = new Date();
                const linkExpiration = link.linkExpiration;
                const hasRegistrationCutoff = isDate(link.registrationEndDate);
                const registrationEndDate = hasRegistrationCutoff ? (link.registrationEndDate as Date) : null;

                let isExpired = false;
                let displayAs = DisplayAs.StartDate;
                let expires: Date | null = null;
                let resetStartDate: Date | null = null;
                let resetDisplay = null;

                if (hasRegistrationCutoff && now < (registrationEndDate as Date)) {
                    displayAs = DisplayAs.RegistrationCutOff;
                } else if (link.registrationMode !== RegistrationMode.Default) {
                    displayAs = DisplayAs.RegistrationReset;
                    resetStartDate = getFormSubmissionResetDate(link, true);
                    switch (link.registrationMode) {
                        case RegistrationMode.Daily:
                            resetDisplay = 'Resets Daily';
                            break;
                        case RegistrationMode.Weekly:
                            resetDisplay = `Resets ${DayOfWeek[link.dayOfWeek]} Weekly`;
                            break;
                        case RegistrationMode.Monthly:
                            resetDisplay = `Resets Monthly`;
                            break;
                    }
                } else if (linkExpiration === LinkExpiration.Date) {
                    if (isActivated || link.startDate) {
                        displayAs = DisplayAs.StartDate;
                    } else {
                        displayAs = DisplayAs.None;
                    }
                    expires = link.endDate as Date;
                } else if (linkExpiration === LinkExpiration.Timer) {
                    displayAs = DisplayAs.Timer;
                    expires = link.timerEndDate ? new Date(link.timerEndDate) : null;
                } else {
                    displayAs = DisplayAs.None;
                }

                if (linkExpiration === LinkExpiration.Timer && activationDate) {
                    let timer: Date | null = null;

                    switch (link.timerUnit) {
                        case TimerUnit.Months:
                            timer = new Date(activationDate);
                            timer.setDate(activationDate.getDate() + (link.timer as number) * 30);
                            break;
                        case TimerUnit.Weeks:
                            timer = new Date(activationDate);
                            timer.setDate(activationDate.getDate() + (link.timer as number) * 7);
                            break;
                        case TimerUnit.Days:
                            timer = new Date(activationDate);
                            timer.setDate(activationDate.getDate() + (link.timer as number));
                            break;
                        case TimerUnit.Hours:
                            timer = new Date(activationDate);
                            timer.setHours(activationDate.getHours() + (link.timer as number));
                            break;
                        default:
                            timer = new Date(activationDate);
                            timer.setMinutes(activationDate.getMinutes() + (link.timer as number));
                            break;
                    }

                    if (expires === null || timer < expires) {
                        expires = timer;
                    }
                }

                if (expires && now > expires) {
                    isExpired = true;
                }

                return {
                    ...link,
                    // displayExpirationDate,
                    isActivated,
                    isExpired,
                    hasDates,
                    hasTimer,
                    displayAs,
                    expires,
                    resetStartDate,
                    resetDisplay,
                    displayTimezone,
                } as ContentLinkModelViewModel;
            }); //clone
        }

        this.dataSource = new MatTableDataSource(items);
    }

    onView(model: ContentLinkModelViewModel): void {
        this.view.emit(model);
    }

    onPage(page: PageEvent) {
        const { pageIndex, pageSize } = page;
        const { filter, tags } = this.filterModel();

        const options = {
            filter: filter || '',
            tags: tags || [],
            offset: pageIndex * pageSize,
            limit: pageSize,
            total: null,
        };

        this.refresh.emit(options);
    }

    onDeleteSelected(): void {
        if (!this.selection.isEmpty()) {
            this.delete.emit(this.selection.selected);
        }
    }

    onCopyLink(model: ContentLinkModelViewModel): void {
        this.copyLink.emit(model);
    }

    onDelete(model: ContentLinkModelViewModel): void {
        this.delete.emit([model]);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['queryResult']) {
            this.updateDataSource();
        }
    }
}
