import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { Store } from '@ngxs/store';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AuthActions, CoreActions } from '../actions';
import { AuthState } from '../state/auth.state';
import { MatIcon } from '@angular/material/icon';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatList, MatListItem, MatListItemIcon, MatListItemTitle, MatListItemLine } from '@angular/material/list';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <h2 matDialogTitle><mat-icon>app_blocking</mat-icon> Access Requirements</h2>
        <mat-dialog-content>
          <p>The following did not meet the access requirements criteria:</p>
          <mat-list>
            @if (incognito) {
              <mat-list-item>
                <mat-icon matListItemIcon>security</mat-icon>
                <div matListItemTitle>Private/incognito browsing is not supported</div>
                <div matListItemLine>Link does not support incognito browsing.</div>
                <div matListItemLine>Ensure you're not browsing in a private session.</div>
              </mat-list-item>
            }
            @if (cookies) {
              <mat-list-item>
                <mat-icon matListItemIcon>cookie</mat-icon>
                <div matListItemTitle>Cookies must be enabled</div>
                <div matListItemLine>Used to keep track of your user session</div>
              </mat-list-item>
            }
            @if (adBlocker) {
              <mat-list-item>
                <mat-icon matListItemIcon>block_flipped</mat-icon>
                <div matListItemTitle>Ad-blocker must be disabled</div>
                <div matListItemLine>Presence of an ad-blocker may prevent</div>
                <div matListItemLine>the link from functioning properly</div>
              </mat-list-item>
            }
            @if (offline) {
              <mat-list-item>
                <mat-icon matListItemIcon>wifi_off</mat-icon>
                <div matListItemTitle>Offline Connection</div>
                <div matListItemLine>Check your internet connection and try again</div>
              </mat-list-item>
            }
            @if (browser) {
              <mat-list-item>
                <mat-icon matListItemIcon>browser_not_supported</mat-icon>
                <div matListItemTitle>Use a supported browser</div>
                <div matListItemLine>Edge, Firefox, Chrome, and Safari are supported</div>
              </mat-list-item>
            }
          </mat-list>
          <!-- <data-diagnosis></data-diagnosis> -->
        
          <!-- <div *ngIf="getDiagnosticResult$ | async as result; else send">
          <span
            >Report sent.
            <a target="_blank" rel="nofollow" [href]="supportLink$ | async"
              >Click here to enter the WhatsApp support thread.</a
              >
              Once you enter the thread, please share your name and a brief description of the challenge you
              encountered. Please provide the following code:
            </span>
            <div mat-ripple class="code" (click)="onCopyToClipboard(result)">{{ result }}</div>
          </div> -->
        
          <!-- <ng-template #send>
          If you're unable to resolve the above issue, you can enter the WhatsApp support thread by clicking the button
          below.
        </ng-template> -->
        </mat-dialog-content>
        <!-- <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" [disabled]="dataSent" (click)="onSendDiagnosisData()">
          WhatsApp Support Thread
        </button>
        </mat-dialog-actions> -->
        `,
    styles: [
        `
            mat-icon {
                vertical-align: sub;
            }

            .mat-mdc-list-item {
                mat-icon,
                .mat-mdc-list-item-title {
                    color: #f44336;
                }
            }

            .code {
                font-size: 1.2rem;
                text-align: center;
                padding: 5px 0;
            }
        `,
    ],
    imports: [
        MatDialogTitle,
        MatIcon,
        CdkScrollable,
        MatDialogContent,
        MatList,
        MatListItem,
        MatListItemIcon,
        MatListItemTitle,
        MatListItemLine
    ]
})
export class DenyIncognitoDialogComponent {
    result = inject(MAT_DIALOG_DATA);
    private store = inject(Store);
    private deviceService = inject(DeviceDetectorService);
    private sanitizer = inject(DomSanitizer);


    get offline() {
        return !navigator.onLine;
    }

    get incognito() {
        return this.result['incognito'] === true;
    }

    get cookies() {
        return this.result['cookieTest'] === true;
    }

    get adBlocker() {
        return this.result['adblocker'] === true;
    }

    get browser() {
        const agent = this.deviceService.userAgent.toLowerCase();
        const browsers = ['duckduckgo', 'brave'];

        return browsers.includes(agent);
    }

    getDiagnosticResult$ = this.store.select(AuthState.getDiagnosticResult());
    dataSent = false;

    onSendDiagnosisData() {
        this.store.dispatch(new AuthActions.SendDiagnosticData());
        this.dataSent = true;
    }

    onCopyToClipboard(text: string) {
        this.store.dispatch(
            new CoreActions.CopyToClipboard({
                text: `Results code: ${text}`,
                message: 'Successfully copied to clipboard',
            }),
        );
    }
}
