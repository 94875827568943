import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthActions } from '@app/data/actions';
import { Credentials } from '@app/data/models';
import { AuthState } from '@app/data/state/auth.state';
import { SignInMethod } from '@app/shared/enums';
import { Store } from '@ngxs/store';
import { MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { LoginFormComponent } from '@app/shared/components/login-form/login-form.component';
import { AsyncPipe } from '@angular/common';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./login-dialog.component.html`,
    styles: [
        `
            :host {
                display: block;
                width: 100%;
                max-width: 300px;
            }

            mat-dialog-actions {
                display: flex;
                justify-content: flex-end;
            }

            [mat-button] {
                padding: 0;
            }
        `,
    ],
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        LoginFormComponent,
        AsyncPipe,
    ]
})
export class LoginDialogComponent {
    private store = inject(Store);

    isPending$ = this.store.select(AuthState.isPending());
    error$: Observable<any> = this.store.select(AuthState.getErrors());

    onSubmit(credentials: Credentials): void {
        this.store.dispatch(new AuthActions.Login(credentials, SignInMethod.Dialog));
    }
}
