import { Component, computed, inject } from '@angular/core';

import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { select, Store } from '@ngxs/store';

import { FormatDatePipe } from '../../pipes/format-date.pipe';

@Component({
    selector: 'shared-build-info',
    template: `
        @if (show()) {
        <div class="build-info">
            <span>{{ devicePixelRatio() }} {{ env.buildVersion }} ({{ env.buildDate | formatDate }})</span>
        </div>
        }
    `,
    styles: [
        `
            .build-info {
                pointer-events: none;
                opacity: 0.5;
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 12px;
                z-index: 999;
            }
        `,
    ],
    imports: [FormatDatePipe],
})
export class BuildInfoComponent {
    protected env = inject<Environment>(APP_ENVIRONMENT);
    private store = inject(Store);

    devicePixelRatio = computed(() => window.devicePixelRatio);

    show = select(state => state.debug?.isEnabled);
}
