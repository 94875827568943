import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'safeUrl',
    standalone: true,
})
export class SafeUrlPipe implements PipeTransform {
    private sanitizer = inject(DomSanitizer);


    transform(value: string | null, isResource = false): SafeHtml {
        return isResource
            ? this.sanitizer.bypassSecurityTrustResourceUrl(value || '')
            : this.sanitizer.bypassSecurityTrustUrl(value || '');
    }
}
