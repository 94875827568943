import { Pipe, PipeTransform, inject } from '@angular/core';

import { catchError, from, Observable, of, switchMap, tap } from 'rxjs';

import { Environment } from '../models';
import { APP_ENVIRONMENT } from '../tokens';
import { transformToCdnUrl } from '../util';
import { Store } from '@ngxs/store';

@Pipe({
    name: 'cdnUrl',
    standalone: true,
})
export class CdnUrlPipe implements PipeTransform {
    private env = inject<Environment>(APP_ENVIRONMENT);
    private store = inject(Store);


    transform(value: string, type: 'image' | 'video' | 'html' | 'audio' | 'file' | '' = ''): Observable<string> {
        let result = transformToCdnUrl(value, this.env, type);
        const options = { headers: {} };

        const debug = this.store.selectSnapshot(root => root.debug?.isEnabled === true);

        if (debug) {
            options['headers'] = { ...options['headers'], 'Fastly-Debug': '1' };

            if (!this.env.production && result.includes('production-private')) {
                result = result.replace('production-private', this.env.privateContainerName);
            }

            if (!this.env.production && result.includes('production-public')) {
                result = result.replace('production-public', this.env.publicContainerName);
            }
        }

        if (result.includes(this.env.privateContainerName)) {
            const token = this.store.selectSnapshot(root => root.auth.token);
            options['headers'] = { Authorization: `Bearer ${token.token}` };
        }

        return from(fetch(result, options)).pipe(
            tap(resp => {
                if (!resp.ok) {
                    throw new Error('Failed to fetch');
                }
            }),
            switchMap(response => response.blob()),
            switchMap(blob => {
                const blobUri = URL.createObjectURL(blob);
                return of(blobUri);
            }),
            catchError(() => of('')), // set to 'could not load' icon
        );
    }
}
