import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';

@Component({
    selector: 'data-progress-notification',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<section>{{ text }} {{ progress }}%</section>`,
    standalone: false
})
export class ProgressNotificationComponent {
    cd = inject(ChangeDetectorRef);

    text = '';
    progress = 0;
}
