<section>
  @if (!encodingJob) {
    <admin-media-asset-player
      [src]="mediaAssetSrc"
      [token]="token"
      >
    </admin-media-asset-player>
  }

  @if (encodingJob) {
    <admin-encoding-status [encodingJob]="encodingJob"></admin-encoding-status>
  }
</section>
