import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatButton } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatListOption, MatSelectionList, MatSelectionListChange } from '@angular/material/list';

import { LinkSessionSummary } from '@app/data/models';
import { SrcUnloadDirective } from '@app/shared/directives/src-unload.directive';
import { AdminSessionStatus, LinkType } from '@app/shared/enums';
import { FormatDatePipe } from '@app/shared/pipes/format-date.pipe';

@Component({
    templateUrl: './merge-link-sessions-confirmation-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [`./merge-link-sessions-confirmation-dialog.component.scss`],
    imports: [
        CdkScrollable,
        NgTemplateOutlet,
        NgClass,

        MatDialogTitle,
        MatDialogContent,
        MatSelectionList,
        MatDialogActions,
        MatDialogClose,
        MatListOption,
        MatButton,
        MatBadge,
        MatIcon,

        SrcUnloadDirective,
        FormatDatePipe
    ]
})
export class MergeLinkSessionsConfirmationDialogComponent {
    private data = inject(MAT_DIALOG_DATA);

    selected: LinkSessionSummary | null = null;

    LinkType: typeof LinkType = LinkType;

    get linkType(): LinkType {
        console.log(this.data.linkType)
        return this.data.linkType;
    }

    get sessions(): LinkSessionSummary[] {
        return this.data.sessions;
    }

    onSelectionChange(change: MatSelectionListChange) {
        this.selected = change.options[0].value;
    }

    getRowClasses(session: LinkSessionSummary): string[] {
        const classes = [];

        if (session.expanded) {
            classes.push('expanded');
        }

        switch (session.sessionStatus) {
            case AdminSessionStatus.Disabled:
                classes.push('disabled');
                break;
            case AdminSessionStatus.Inactive:
                classes.push('inactive');
                break;
            case AdminSessionStatus.Active:
                classes.push('active');
                break;
            case AdminSessionStatus.ActionRequired:
                classes.push('action-required');
                break;
            case AdminSessionStatus.Reset:
                classes.push('reset');
                break;
        }

        return classes;
    }
}
