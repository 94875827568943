import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';

import { CurrentUser, DropMediaModel, EncodingJob, TokenResult } from '@app/data/models';
import { SrcUnloadDirective } from '@app/shared/directives/src-unload.directive';
import { EncodingStatus } from '@app/shared/enums';
import { Environment } from '@app/shared/models';
import { DestroyService } from '@app/shared/services/destroy.service';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

import { EncodingStatusComponent } from '../encoding-status/encoding-status.component';
import { MediaAssetPlayerComponent } from '../media-asset-player/media-asset-player.component';

@Component({
    selector: 'admin-tiptap-media-asset',
    templateUrl: './tiptap-media-asset.component.html',
    styleUrls: ['./tiptap-media-asset.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService],
    imports: [MediaAssetPlayerComponent, SrcUnloadDirective, EncodingStatusComponent],
})
export class TiptapMediaAssetComponent implements OnChanges {
    private env = inject<Environment>(APP_ENVIRONMENT);

    encodingJob: EncodingJob | null = null;

    isUploading = false;

    get mediaAssetSrc() {
        if (!this.mediaAssetId) {
            return null;
        }

        return `${this.env.cdnUrl}/${this.env.privateContainerName}/media-assets/${this.mediaAssetId}/playlist.m3u8`;
    }

    @Input() isEditable: boolean | null = false;
    @Input() mediaAssetId: string | null = '';
    @Input() mediaFile: DropMediaModel | null = null;

    @Input() token: TokenResult | null = null;
    @Input() isLoading: boolean | null;
    @Input() currentUser: CurrentUser | null;
    @Input() encodingJobs: EncodingJob[] | null;
    @Input() error: any;

    @Output() readonly uploadMediaFile: EventEmitter<File> = new EventEmitter();

    checkForFileUpload() {
        if (this.mediaFile && !this.isUploading) {
            this.isUploading = true;
            this.uploadMediaFile.emit(this.mediaFile.file);
        }
    }

    checkForAssetState() {
        if (this.mediaAssetId !== null && this.encodingJobs !== null) {
            this.encodingJob =
                this.encodingJobs.find(
                    job => job.mediaAssetId === this.mediaAssetId && job.status !== EncodingStatus.Finished,
                ) || null;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['mediaAssetId']) {
            // this.checkForReload();
        }

        if (changes['mediaFile']) {
            this.checkForFileUpload();
        }

        if (changes['encodingJobs']) {
            this.checkForAssetState();
        }
    }
}
