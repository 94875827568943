<div [formGroup]="form" class="field" >
  <mat-form-field appearance="outline" class="time-picker full-width">
    <mat-label>{{ label }}</mat-label>
    <input matInput formControlName="datetime" [placeholder]="placeholder" />
    <mat-hint>{{ hint }}</mat-hint>

    <input type="hidden" [value]="valueAsDate" [matDatepicker]="datePicker" (dateChange)="onDateChange($event)" />

    <mat-datepicker-toggle [disabled]="disabled" matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
    @if (showTime) {
      <button mat-icon-button matSuffix [disabled]="disabled" (click)="timePicker.open()">
        <mat-icon>av_timer</mat-icon>
      </button>
    }
    <ngx-material-timepicker #timePicker [defaultTime]="valueAsTime" [format]="12" (timeSet)="onTimeChange($event)">
    </ngx-material-timepicker>
    @if (showTimeZone) {
      <button
        [disabled]="disabled"
        class="timezone-toggle"
        mat-icon-button
        matSuffix
        [matMenuTriggerFor]="timeZoneMenu"
        >
        <mat-icon [ngClass]="{ 'airplane-mode': DEFAULT_TIME_ZONE !== form.get('timeZone')?.value }">flight</mat-icon>
      </button>
    }

    @if (datetime.invalid) {
      <mat-error>
        <shared-error-display [error]="datetime.errors"></shared-error-display>
      </mat-error>
    }
  </mat-form-field>
</div>

<mat-menu class="time-zones" #timeZoneMenu="matMenu">
  @for (tz of timeZones; track tz) {
    <button
      [ngClass]="{ selected: tz.utc[0] === form.get('timeZone')?.value }"
      mat-menu-item
      (click)="onTimeZoneChange(tz)"
      >
      {{ tz.text }}
    </button>
  }
</mat-menu>
