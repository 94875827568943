import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { errorHandler, getData, verifyServerResult } from '@app/data/operators';
import { LinkType } from '@app/shared/enums';
import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import {
    ChecklistLinkTemplateModel,
    ChecklistTemplate,
    DeleteResult,
    FormLinkTemplateModel,
    PreambleTemplate,
    ShareableLinkTemplateModel,
} from '@app/data/models';

@Injectable({
    providedIn: 'root',
})
export class ManageTemplatesService {
    private env = inject<Environment>(APP_ENVIRONMENT);
    private http = inject(HttpClient);


    getTemplates<T>(type: LinkType): Observable<T[]> {
        return this.http
            .post<ServerResult<T[]>>(`${this.env.serverUrl}/manage/templates`, { type })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    saveShareableLinkTemplate(model: ShareableLinkTemplateModel): Observable<ShareableLinkTemplateModel> {
        return this.http
            .post<ServerResult<ShareableLinkTemplateModel>>(`${this.env.serverUrl}/manage/templates/shareable-link`, model)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    saveFormLinkTemplate(model: FormLinkTemplateModel): Observable<FormLinkTemplateModel> {
        return this.http
            .post<ServerResult<FormLinkTemplateModel>>(`${this.env.serverUrl}/manage/templates/form-link`, model)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    saveChecklistTemplate(model: ChecklistTemplate): Observable<ChecklistTemplate> {
        return this.http
            .post<ServerResult<ChecklistTemplate>>(`${this.env.serverUrl}/manage/templates/checklists`, model, {})
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    savePreambleTemplate(model: PreambleTemplate): Observable<PreambleTemplate> {
        return this.http
            .post<ServerResult<PreambleTemplate>>(`${this.env.serverUrl}/manage/templates/preamble`, model, {})
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    saveChecklistLinkTemplate(model: ChecklistLinkTemplateModel): Observable<ChecklistLinkTemplateModel> {
        return this.http
            .post<ServerResult<ChecklistLinkTemplateModel>>(`${this.env.serverUrl}/manage/templates/checklist-link`, model, {})
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deleteTemplates(linkType: LinkType, rowKeys: string[]): Observable<DeleteResult> {
        return this.http
            .request<ServerResult<DeleteResult>>('DELETE', `${this.env.serverUrl}/manage/templates`, {
                body: { linkType, rowKeys },
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }
}
