import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { NodeType } from '@app/shared/enums';
import { Node } from '@app/data/models';


@Component({
    selector: 'admin-breadcrumb',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./breadcrumb.component.html`,
    styleUrls: [`./breadcrumb.component.scss`],
    imports: []
})
export class BreadcrumbComponent implements OnChanges {
    breadcrumb: Node[] | null = null;

    @Input() path: string | null = null;
    @Input() nodes: Node[] | null = [];

    @Output() readonly selectNode: EventEmitter<Node | null> = new EventEmitter();

    buildBreadcrumb() {
        if (!this.nodes || this.nodes.length === 0) {
            return;
        }

        const nodes = this.nodes.slice(); //clone the array
        const nodeId = this.path?.split('.').pop();
        let node = nodes.find(n => n.rowKey === nodeId);

        this.breadcrumb = nodes
            .reduce(
                (acc: Node[], curr: Node, ix: number, parent: Node[]) => {
                    if (node && node.path) {
                        node = parent.find(n => n.rowKey === node?.parentId);
                        if (node) {
                            acc.push(node);
                        }
                    }
                    return acc;
                },
                node && node.type === NodeType.Node ? [node] : [],
            )
            .reverse();
    }

    onSelect(node: Node | null) {
        this.selectNode.emit(node);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['path']) {
            this.buildBreadcrumb();
        }
    }
}
