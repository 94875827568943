import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';

import { NodeColours, NodeStatus, NodeType } from '@app/shared/enums';
import { Store } from '@ngxs/store';

import { ManageMediaActions, ManageNodeActions } from '../actions';
import { ManageNodesState } from '../state/manage-nodes.state';

export const CreateNodeResolver: ResolveFn<Observable<void>> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    store: Store = inject(Store),
): Observable<void> => {
    store.dispatch(new ManageMediaActions.EnsureLoadMediaAssets());

    const param = route.params['nodeType'] as string;
    const nodeType = param.charAt(0).toUpperCase() + param.slice(1);

    let editModel = store.selectSnapshot(ManageNodesState.getEditModel());

    if (!editModel || NodeType[editModel.type].toString() !== nodeType) {
        if (!editModel) {
            editModel = {
                type: NodeType.Html,
                createdDate: new Date(),
                rowKey: '',
                title: '',

                content: null,
                binary: null,
                binaryContentLength: null,
                binaryContentType: null,
                parentId: null,
                path: '',
                searchIndex: '',
                keywords: null,
                colour: NodeColours.Colour10,
                status: NodeStatus.Active,
                duration: null,
                isLoaded: true,
                isLoading: false,
                isSaving: false,
                error: null,
                mediaAssetId: null,
                eTag: null,
            };
        }

        switch (NodeType[nodeType as any] as unknown as NodeType) {
            case NodeType.Video:
                editModel.type = NodeType.Video;
                break;
            case NodeType.Audio:
                editModel.type = NodeType.Audio;
                break;
            case NodeType.File:
                editModel.type = NodeType.File;
                break;
            case NodeType.Html:
                editModel.type = NodeType.Html;
                break;
            case NodeType.Node:
                editModel.type = NodeType.Node;
                break;
        }

        return store.dispatch(new ManageNodeActions.AddNode(editModel, false));
    }

    return of(void 0);
};
