<section tiptapDraggable [ngClass]="{ selected: isEditable && (isSelected || !mediaAssetId) }">
  @if (isEditable) {
    <button mat-raised-button (click)="onEdit()">Edit</button>
  }
  <ng-container *ngTemplateOutlet="isUploading ? uploadingTpl : mediaAsset"> </ng-container>
</section>

<ng-template #mediaAsset>
  <admin-tiptap-media-asset
    [isEditable]="isEditable"
    [mediaAssetId]="mediaAssetId"
    [mediaFile]="mediaFile"
    [token]="token$ | async"
    [currentUser]="currentUser$ | async"
    [encodingJobs]="encodingJobs$ | async"
    (uploadMediaFile)="onUploadMediaFile($event)"
  ></admin-tiptap-media-asset>
  @if (!mediaAssetId) {
    <span class="no-media"><i>No media selected</i></span>
  }
</ng-template>
<ng-template #uploadingTpl>
  <div class="uploading">
    <mat-spinner [mode]="'determinate'" [value]="uploadProgress" diameter="20"></mat-spinner>
    <span>{{ fileName }}</span>
  </div>
</ng-template>
