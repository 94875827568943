import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { AppSettings } from '@app/data/models';
import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

import { errorHandler, getData, verifyServerResult } from '@app/data/operators';

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    private env = inject<Environment>(APP_ENVIRONMENT);
    private http = inject(HttpClient);


    execute(model: { action: string; sendToQueue: boolean; payload: string}): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/admin`, model)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getSettings(): Observable<AppSettings> {
        return this.http
            .post<ServerResult<AppSettings>>(`${this.env.serverUrl}/app/settings`, null, {})
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    purgeCdn(model: { url?: string | null; keys?: string[] | null }): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/cdn/purge`, JSON.stringify(model))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }
}
