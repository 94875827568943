import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel, MatHint, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { ErrorDisplayComponent } from '@app/shared/components/error-display/error-display.component';
import { MatButton } from '@angular/material/button';

@Component({
    templateUrl: './extend-link-dialog.component.html',
    styleUrls: ['./extend-link-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatHint,
        MatError,
        ErrorDisplayComponent,
        MatDialogActions,
        MatButton,
        MatDialogClose
    ]
})
export class ExtendLinkDialogComponent {
    private fb = inject(FormBuilder);

    get ctrls() {
        return this.form.controls;
    }

    form = this.fb.group({
        days: [7, [Validators.required, Validators.min(-90), Validators.max(90)]],
    });
}
