import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import { CoreActions, NotificationActions } from '@app/data/actions';
import {
    ArchivedFormLinkContentModel,
    EditFormLinkModel,
    FormLinkModel,
    FormLinkSearchResult,
    FormState,
    FormSubmissionMessage,
    LinkSessionSummary,
    PreambleTemplate,
    QueryResult,
    SearchFormOptions,
    Submission,
} from '@app/data/models';
import { handleNotFoundError, transformResponseToText } from '@app/data/operators';
import { BlobService } from '@app/data/services/blob.service';
import { RouterState } from '@app/data/state/router.state';
import {
    BlobContainer,
    ErrorCodes,
    FormLinkType,
    FormSectionType,
    LinkExpiration,
    LinkInactivityMode,
    LinkStatus,
    LinkType,
    RegistrationMode,
    TimerUnit,
    VideoOption,
} from '@app/shared/enums';
import { ErrorModel, isErrorModel } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import {
    convertObjectKeysToCamelCase,
    DEFAULT_TIME_ZONE,
    formatDatetimeWithTimeZone,
    formatLinkUrl,
    generatePassword,
    isNullOrEmpty,
    randomId,
    readAsText,
} from '@app/shared/util';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, State, StateContext, Store } from '@ngxs/store';
import { Timezone } from 'timezones.json';

import { ManageFormsActions, ManageTemplateActions } from '../actions';
import { ArchiveContentLinkConfirmationDialogComponent } from '../dialogs/archive-content-link-confirmation/archive-content-link-confirmation-dialog.component';
import { ManageFormLinksService } from '../services/manage-form-links.service';
import { ManageLinksState } from './manage-links.state';
import { ManageTemplatesState } from './manage-templates.state';
import { CancelScheduledEventsConfirmationDialogComponent } from '../dialogs/cancel-scheduled-events-confirmation/cancel-scheduled-events-confirmation-dialog.component';
import { DeleteLinkSessionsConfirmationDialogComponent } from '../../../../shared/src/lib/dialogs/delete-link-session-confirmation/delete-link-session-confirmation-dialog.component';
import { DeleteShareableLinkConfirmationDialogComponent } from '../dialogs/delete-shareable-link-confirmation/delete-shareable-link-confirmation-dialog.component';
import { ExtendLinkDialogComponent } from '@app/shared/dialogs/extend-link-dialog/extend-link-dialog.component';
import { MergeLinkSessionsConfirmationDialogComponent } from '../../../../shared/src/lib/dialogs/merge-link-sessions-confirmation/merge-link-sessions-confirmation-dialog.component';

interface StateModel {
    archivedFormLinkSearchOptions: SearchFormOptions | null;
    archivedFormLinkSearchResults: QueryResult<FormLinkSearchResult> | null;

    formLinkSearchOptions: SearchFormOptions | null;
    formLinkSearchResults: QueryResult<FormLinkSearchResult> | null;

    tags: string[];

    hasLoadedLinks: boolean;
    hasLoadedArchivedLinks: boolean;
    hasLoadedTags: boolean;

    isSearching: boolean;
    isLoadingLinks: boolean;
    isLoadingArchivedLinks: boolean;
    isSaving: boolean;
    errors: any;

    archivedContent: ArchivedFormLinkContentModel | null;
    archivedLink: FormLinkModel | null;

    linkSessions: LinkSessionSummary[];
    linkSubmissions: Submission[];
    formSubmissionMessages: FormSubmissionMessage[];
    editLink: FormLinkModel | null;
    copyLink: FormLinkModel | null;
    currentTabIndex: number;
    form: FormState<EditFormLinkModel>;
    sessionFilters: FormState<Record<string, any>>;
}

@State<StateModel>({
    name: 'manageForms',
    defaults: {
        isSearching: false,
        archivedFormLinkSearchResults: null,
        archivedFormLinkSearchOptions: {
            formLinkType: FormLinkType.Form,
            linkFilters: [],
            useNewSearchMethod: false,
            filter: '',
            tags: [],
            total: null,
            limit: 25,
            offset: 0,
        },
        formLinkSearchResults: null,
        formLinkSearchOptions: {
            formLinkType: FormLinkType.Form,
            linkFilters: [],
            useNewSearchMethod: false,
            filter: '',
            tags: [],
            total: null,
            limit: 25,
            offset: 0,
        },
        tags: [],

        hasLoadedLinks: false,
        hasLoadedArchivedLinks: false,
        hasLoadedTags: false,

        isLoadingLinks: false,
        isLoadingArchivedLinks: false,
        isSaving: false,
        errors: null,

        archivedContent: null,
        archivedLink: null,
        linkSessions: [],
        linkSubmissions: [],
        editLink: null,
        copyLink: null,
        currentTabIndex: 0,
        formSubmissionMessages: [],
        form: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
        sessionFilters: {
            model: null,
            status: '',
            dirty: false,
            errors: null,
        },
    },
})
@Injectable({
    providedIn: 'root',
})
export class ManageFormsState {
    readonly format: string = 'MMM DD YYYY @ hh:mmA';

    static hasLoadedLinks() {
        return createSelector([ManageFormsState], (state: StateModel) => state.hasLoadedLinks);
    }

    static hasLoadedArchivedLinks() {
        return createSelector([ManageFormsState], (state: StateModel) => state.hasLoadedArchivedLinks);
    }

    static isLoading() {
        return createSelector([ManageFormsState], (state: StateModel) => state.isLoadingLinks);
    }

    static isLoadingArchivedLinks() {
        return createSelector([ManageFormsState], (state: StateModel) => state.isLoadingArchivedLinks);
    }

    static currentTabIndex() {
        return createSelector([ManageFormsState], (state: StateModel) => state.currentTabIndex);
    }

    static isNewLink() {
        return createSelector([RouterState.selectRouteParam('linkId')], (linkId: string) => linkId === 'new');
    }

    static getErrors() {
        return createSelector([ManageFormsState], (state: StateModel) => state.errors);
    }

    static getEditModel() {
        return createSelector([ManageFormsState], (state: StateModel) => state.editLink);
    }

    static getCopyLink() {
        return createSelector([ManageFormsState], (state: StateModel) => state.copyLink);
    }

    static getArchivedContent() {
        return createSelector([ManageFormsState], (state: StateModel) => state.archivedContent);
    }

    static getArchivedLinkSessions() {
        return createSelector([ManageFormsState], (state: StateModel) => state.archivedContent?.sessions || []);
    }

    static getLinkSessions() {
        return createSelector([ManageFormsState], (state: StateModel) => state.linkSessions);
    }

    static getLinkSubmissions() {
        return createSelector([ManageFormsState], (state: StateModel) => state.linkSubmissions);
    }

    static isSaving() {
        return createSelector([ManageFormsState], (state: StateModel) => state.isSaving);
    }

    static isValid() {
        return createSelector([ManageFormsState], (state: StateModel) => state.form.status === 'VALID');
    }

    static getForm() {
        return createSelector([ManageFormsState], (state: StateModel) => state.form);
    }

    static getTags() {
        return createSelector([ManageFormsState], (state: StateModel) => state.tags);
    }

    static getArchivedLinkSearchOptions() {
        return createSelector([ManageFormsState], (state: StateModel) => state.archivedFormLinkSearchOptions);
    }

    static getFormLinkSearchOptions() {
        return createSelector([ManageFormsState], (state: StateModel) => state.formLinkSearchOptions);
    }

    static isSearching() {
        return createSelector([ManageFormsState], (state: StateModel) => state.isSearching);
    }

    static getSearchArchivedLinksResults() {
        return createSelector([ManageFormsState], (state: StateModel) => state.archivedFormLinkSearchResults);
    }

    static getFormLinkSearchResults() {
        return createSelector([ManageFormsState], (state: StateModel) => state.formLinkSearchResults);
    }

    static getArchivedLink() {
        return createSelector([ManageFormsState], (state: StateModel) => state.archivedLink);
    }

    static getFormLinkType() {
        return createSelector([RouterState.selectRouteData()], (data: any) => data?.['linkType'] as FormLinkType);
    }

    static getPreambleContentForLink() {
        return createSelector(
            [
                ManageLinksState.getLinkId(),
                ManageTemplatesState.getPreambleTemplates(),
                ManageFormsState.getFormLinkType(),
                ManageTemplatesState.getPreambleTemplateForLink(),
            ],
            (linkId: string, templates: PreambleTemplate[], formLinkType: FormLinkType, template: string | null) => {
                const preambleTemplates = templates
                    .reduce((acc, current) => {
                        const rowKey = current.rowKey as string;
                        let defaultPreamble = 'preamble-forms';

                        switch (formLinkType) {
                            case FormLinkType.Poll:
                                defaultPreamble = 'preamble-polls';
                                break;
                            case FormLinkType.Booking:
                                defaultPreamble = 'preamble-booking';
                                break;
                        }

                        if (!current.isGlobal || rowKey === defaultPreamble) {
                            acc.push(current);
                        }

                        return acc;
                    }, [] as PreambleTemplate[])
                    .sort((a, b) => {
                        if (a.isGlobal && !b.isGlobal) {
                            return -1;
                        } else if (!a.isGlobal && b.isGlobal) {
                            return 1;
                        }

                        return a.name.localeCompare(b.name);
                    });

                preambleTemplates.push({
                    rowKey: 'custom',
                    name: 'Custom',
                    isEditable: true,
                    isGlobal: false,
                    content: template || '',
                    createdDate: new Date(),
                    hasLoaded: true,
                    sasUri: '',
                });

                return preambleTemplates;
            },
        );
    }

    static getFormSubmissionMessages() {
        return createSelector([ManageFormsState], (state: StateModel) => state.formSubmissionMessages);
    }

    manageFormsService = inject(ManageFormLinksService);
    blobService = inject(BlobService);
    store = inject(Store);
    matDialog = inject(MatDialog);
    env = inject(APP_ENVIRONMENT);

    @Action(ManageFormsActions.EnsureLoadTags)
    ensureLoadTags({ dispatch, getState }: StateContext<StateModel>): Observable<any> | void {
        const { hasLoadedTags } = getState();

        if (hasLoadedTags) {
            return;
        }

        return dispatch([new ManageFormsActions.LoadTags()]);
    }

    @Action(ManageFormsActions.LoadTags)
    loadTags({ patchState }: StateContext<StateModel>): Observable<any> | void {
        return this.manageFormsService.getTags().pipe(
            tap(tags => {
                tags.sort();
                patchState({ tags });
            }),
            catchError(err => this.store.dispatch(new ManageFormsActions.LoadTagsFailure(err))),
            finalize(() => {
                patchState({ hasLoadedTags: true });
            }),
        );
    }

    @Action(ManageFormsActions.CopyFormLinkTemplate)
    copyShareableLinkTemplate(
        { dispatch }: StateContext<StateModel>,
        { template }: ManageFormsActions.CopyFormLinkTemplate,
    ) {
        dispatch(new ManageTemplateActions.SetCustomPreamble(''));

        if (template) {
            if (!isNullOrEmpty(template.password)) {
                template.password = generatePassword();
            }

            if (!isNullOrEmpty(template.influencerPassword)) {
                template.influencerPassword = generatePassword();
            }

            return dispatch([
                new ManageTemplateActions.LoadSubmissionMessagesForLink(template.rowKey as string),
                new ManageTemplateActions.LoadPreambleTemplateForLink(template.rowKey),
            ]).pipe(
                mergeMap(() =>
                    dispatch([
                        new ManageTemplateActions.CopyLink(template),
                        new Navigate(['/home/form-links/template/new']),
                    ]),
                ),
            );
        }

        return dispatch([new ManageTemplateActions.CopyLink(template), new Navigate(['/home/form-links/template/new'])]);
    }

    @Action(ManageFormsActions.AcknowledgeInfluencerNotification)
    acknowledgeInfluencerNotification(
        { patchState, dispatch }: StateContext<StateModel>,
        action: ManageFormsActions.AcknowledgeInfluencerNotification,
    ) {
        patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageFormsService.acknowledgeInfluencerNotification(action.rowKeys).pipe(
            tap(() => {
                const sessions = this.store.selectSnapshot(ManageFormsState.getLinkSessions());
                patchState({
                    linkSessions: sessions.map(session => {
                        if (action.rowKeys.includes(session.rowKey)) {
                            session.hasAdminAcknowledged = true;
                        }
                        return session;
                    }),
                });

                dispatch([new NotificationActions.Success('Submission(s) Acknowledged')]);
            }),
            catchError(err => this.store.dispatch(new ManageFormsActions.AcknowledgeInfluencerNotificationFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageFormsActions.SetCurrentTabIndex)
    setCurrentTabIndex(ctx: StateContext<StateModel>, action: ManageFormsActions.SetCurrentTabIndex): void {
        ctx.patchState({
            currentTabIndex: action.index,
        });
        localStorage.setItem('manageForms.currentTabIndex', JSON.stringify(action.index));
    }

    @Action(ManageFormsActions.LoadLink)
    loadLink(
        { dispatch, patchState }: StateContext<StateModel>,
        action: ManageFormsActions.LoadLink,
    ): Observable<any> | void {
        const isNew = action.linkId === 'new';
        const copyLink = this.store.selectSnapshot(ManageFormsState.getCopyLink());
        const { linkType } = this.store.selectSnapshot(RouterState.selectRouteData());
        const editModel = isNew ? copyLink || getDefaultFormLinkModel(linkType) : null;

        patchState({
            editLink: null,
            copyLink: null,
            linkSessions: [],
            linkSubmissions: [],
        });

        if (!isNew) {
            return this.manageFormsService.getLinkById(action.linkId).pipe(
                mergeMap(model => {
                    return dispatch([
                        new ManageFormsActions.LoadLinkSessions(action.linkId),
                        new ManageFormsActions.LoadLinkSubmissions(action.linkId),
                        new ManageFormsActions.UpdateSessionCount(action.linkId),
                        new ManageFormsActions.LoadSubmissionMessagesForLink(model),
                        new ManageTemplateActions.LoadPreambleTemplateForLink(action.linkId),
                    ]).pipe(
                        withLatestFrom(this.store.select(ManageFormsState.getFormSubmissionMessages())),
                        tap(([_, submissionMessages]) => {
                            model.submissionMessages = submissionMessages;

                            patchState({
                                editLink: model,
                            });
                        }),
                    );
                }),
            );
        }

        patchState({
            editLink: editModel,
        });

        if (!copyLink) {
            return dispatch(new ManageTemplateActions.SetCustomPreamble(''));
        }
    }

    @Action(ManageFormsActions.CopyLink)
    copyLink({ dispatch, patchState }: StateContext<StateModel>, action: ManageFormsActions.CopyLink) {
                // form links loads the link from the server because the link list does not contain
        // the entire object
        const getModel$ = action.isTemplate
            ? of(action.link as FormLinkModel)
            : this.manageFormsService.getLinkById(action.link.rowKey as string);

        return getModel$.pipe(
            mergeMap(link => {
                if (!isNullOrEmpty(link.password)) {
                    link.password = generatePassword();
                }

                if (!isNullOrEmpty(link.influencerPassword)) {
                    link.influencerPassword = generatePassword();
                }

                const { linkType } = this.store.selectSnapshot(RouterState.selectRouteData());

                const copyLink = {
                    ...link,

                    rowKey: null,
                    isDeleted: false,
                    activationDate: null,
                    createdDate: new Date(),
                    status: LinkStatus.Active,
                    activeSubmissionCount: 0,
                    submissionMessages: [] as FormSubmissionMessage[],
                    formLinkType: linkType,
                    pages: link.pages.map(page => ({
                        ...page,
                        children: page.children.map(child => {
                            if (child.sectionType === FormSectionType.MeetingRegistration) {
                                return {
                                    ...child,
                                    webinarId: null,
                                    webinarTemplateId: null,
                                    webinarTopic: null,
                                    webinarAgenda: null,
                                    webinarUniqueId: null,
                                    meetingId: null,
                                    meetingTemplateId: null,
                                    meetingTopic: null,
                                    meetingAgenda: null,
                                    meetingUniqueId: null,
                                };
                            }

                            return child;
                        }),
                    })),
                };

                let uri = '/home/form-links/new';

                switch (link.formLinkType) {
                    case FormLinkType.Booking:
                        uri = '/home/booking-links/new';
                        break;
                    case FormLinkType.Poll:
                        uri = '/home/poll-links/new';
                        break;
                }

                return dispatch([
                    new ManageTemplateActions.LoadPreambleTemplateForLink(link.rowKey as string),
                    new ManageFormsActions.LoadSubmissionMessagesForLink(link),
                ]).pipe(
                    withLatestFrom(this.store.select(ManageFormsState.getFormSubmissionMessages())),
                    tap(([_, submissionMessages]) => {
                        copyLink.submissionMessages = submissionMessages;

                        patchState({
                            copyLink,
                        });
                    }),
                    mergeMap(() => dispatch(new Navigate([uri]))),
                );
            }),
        );
    }

    @Action(ManageFormsActions.LoadArchivedLink)
    loadArchivedLink(
        { dispatch, patchState }: StateContext<StateModel>,
        action: ManageFormsActions.LoadArchivedLink,
    ): Observable<any> | void {
        return this.manageFormsService.getArchivedLink(action.rowKey).pipe(
            mergeMap(archivedLink => {
                patchState({
                    archivedLink,
                });

                return dispatch(new ManageFormsActions.LoadSubmissionMessagesForLink(archivedLink));
            }),
            mergeMap(() => dispatch(new ManageFormsActions.LoadArchivedLinkSuccess())),
            catchError(err => this.store.dispatch(new ManageFormsActions.LoadArchivedLinkFailure(err))),
        );
    }

    @Action(ManageFormsActions.SearchLinks)
    searchLinks({ patchState, getState }: StateContext<StateModel>, action: ManageFormsActions.SearchLinks) {
        const { formLinkSearchOptions: options } = getState();
        const model = action.model;

        if (model.total === undefined) {
            model.total = options?.total;
        }

        patchState({ isLoadingLinks: true });

        return this.manageFormsService.searchLinks(model).pipe(
            tap(queryResult => {
                patchState({
                    formLinkSearchResults: queryResult,
                    formLinkSearchOptions: {
                        filter: '',
                        tags: [],
                        useNewSearchMethod: model.useNewSearchMethod,
                        limit: queryResult.limit,
                        total: queryResult.total,
                        offset: queryResult.offset,
                        formLinkType: model.formLinkType,
                        linkFilters: model.linkFilters,
                    },
                });
            }),
            catchError(err => this.store.dispatch(new ManageFormsActions.SearchLinksFailure(err))),
            finalize(() => {
                patchState({ isLoadingLinks: false, hasLoadedLinks: true });
            }),
        );
    }

    @Action(ManageFormsActions.LoadLinkSessions)
    loadLinkSessions(
        ctx: StateContext<StateModel>,
        { rowKey }: ManageFormsActions.LoadLinkSessions,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageFormsState.getEditModel());
        if ((!model || !model.rowKey || model.rowKey === 'new') && (!rowKey || rowKey === 'new')) {
            return;
        }

        ctx.patchState({ isLoadingLinks: true });

        return this.manageFormsService.getAllLinkSessions((model?.rowKey || rowKey) as string).pipe(
            tap(linkSessions => ctx.patchState({ linkSessions })),
            catchError(err => this.store.dispatch(new ManageFormsActions.LoadLinkSubmissionsFailure(err))),
            finalize(() => ctx.patchState({ isLoadingLinks: false })),
        );
    }

    @Action(ManageFormsActions.LoadArchivedLinkSessions)
    loadArchivedLinkSessions(
        ctx: StateContext<StateModel>,
        action: ManageFormsActions.LoadArchivedLinkSessions,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageFormsState.getArchivedLink()) as FormLinkModel;
        const rowKey = action.rowKey || model?.rowKey;

        if (!rowKey) {
            return;
        }

        ctx.patchState({ isLoadingLinks: true });

        return this.blobService.downloadBlobBlock(`archived-forms/${rowKey}.json`, BlobContainer.Private).pipe(
            transformResponseToText(),
            handleNotFoundError('{}'),
            tap(content => {
                const result = JSON.parse(content) as any;
                const archivedContent = convertObjectKeysToCamelCase(result);
                ctx.patchState({ archivedContent });
            }),
            catchError(err => ctx.dispatch(new ManageFormsActions.LoadArchivedLinkSessionsFailure(err))),
            finalize(() => ctx.patchState({ isLoadingLinks: false })),
        );
    }

    @Action(ManageFormsActions.LoadLinkSubmissions)
    loadLinkSubmissions(
        ctx: StateContext<StateModel>,
        { rowKey }: ManageFormsActions.LoadLinkSubmissions,
    ): Observable<any> | void {
        const model = this.store.selectSnapshot(ManageFormsState.getEditModel());
        if (((!model || !model.rowKey) && !rowKey) || rowKey === 'new') {
            return;
        }

        ctx.patchState({ isLoadingLinks: true });

        return this.manageFormsService.getAllLinkSubmissions((model?.rowKey || rowKey) as string).pipe(
            tap(linkSubmissions => ctx.patchState({ linkSubmissions })),
            catchError(err => this.store.dispatch(new ManageFormsActions.LoadLinkSessionsFailure(err))),
            finalize(() => ctx.patchState({ isLoadingLinks: false })),
        );
    }

    getLinkFromForm(): any {
        const model = this.store.selectSnapshot(ManageFormsState.getForm()).model as EditFormLinkModel;

        const { timeZone } = model.linkExpiration;
        // const timeZone = getTimeZoneByIanaTimeZone(ianaTimeZone);
        const submissionMessages = (model.submissionMessages || []).map((message: FormSubmissionMessage) => {
            return {
                rowKey: message.rowKey,
                name: message.name,
            };
        });

        return {
            ...model,
            ...model.linkExpiration,
            timeZone: timeZone || DEFAULT_TIME_ZONE,
            submissionMessages,
            videoSettings: {
                showWatermark: model.showWatermark,
                elapsedTimeInSeconds: 0,
                isLiveStream: false,
                startTime: VideoOption.None,
                autoplay: false,
                enableWaitingRoom: false,
                waitingRoomOffsetInSeconds: 0,
                showControls: true,
                showPlayButton: true,
                showFullscreenButton: true,
                showProgress: true,
                allowScrubbing: true,
                showPlaybackSpeed: true,
                resumePlayback: false,
                allowListeners: true,
                countDownAssetId: null,
            },
        };
    }

    @Action(ManageFormsActions.Save)
    save({ patchState, dispatch }: StateContext<StateModel>): Observable<void> {
        patchState({
            isSaving: true,
            errors: null,
        });

        const formLinkType = this.store.selectSnapshot(ManageFormsState.getFormLinkType());

        const model = this.getLinkFromForm();
        const preambleTemplate = model.preambleTemplate;
        const preambleTemplateId = model.preambleTemplateId;

        model.formLinkType = formLinkType;
        model.preambleTemplate = null;

        const url =
            formLinkType === FormLinkType.Poll
                ? '/home/poll-links'
                : formLinkType === FormLinkType.Booking
                ? '/home/booking-links'
                : '/home/form-links';

        return this.manageFormsService.saveLink(model).pipe(
            mergeMap(link => {
                patchState({
                    copyLink: null,
                });
                const actions: unknown[] = [new ManageFormsActions.SaveFormSubmissionMessages(link)];

                if (preambleTemplateId === 'custom') {
                    actions.push(
                        new ManageTemplateActions.SavePreambleForLink(
                            preambleTemplate?.content || '',
                            link.rowKey as string,
                        ),
                    );
                }

                return dispatch(actions).pipe(
                    mergeMap(() =>
                        dispatch([
                            new Navigate([url]),
                            new ManageFormsActions.UpdateSessionCount(link.rowKey as string),
                            new ManageFormsActions.UpdateLinkSummary(),
                            new NotificationActions.Success('Link has been successfully saved'),
                        ]),
                    ),
                );
            }),
            catchError(err => dispatch(new ManageFormsActions.SaveFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageFormsActions.SaveFormSubmissionMessages)
    saveFormSubmissionMessages(
        { dispatch, patchState }: StateContext<StateModel>,
        { link }: ManageFormsActions.SaveFormSubmissionMessages,
    ) {
        const model = this.store.selectSnapshot(ManageFormsState.getForm()).model as EditFormLinkModel;
        const submissionMessages = model.submissionMessages || [];

        if (submissionMessages.length === 0) {
            return;
        }

        const actions: Observable<any>[] = link.submissionMessages.reduce((acc: any[], current: FormSubmissionMessage) => {
            const message = submissionMessages.find(m => m.rowKey === current.rowKey);

            if (message) {
                acc.push(
                    this.blobService.uploadBlobBlock(
                        `forms/${link.rowKey}/${current.rowKey}.html`,
                        message.content,
                        'text/html',
                        BlobContainer.Private,
                    ),
                );
            }

            return acc;
        }, []);

        if (actions.length === 0) {
            return;
        }

        return forkJoin(actions);
    }

    @Action(ManageFormsActions.LoadSubmissionMessagesForLink)
    loadSubmissionMessagesForLink(
        { patchState }: StateContext<StateModel>,
        { link }: ManageFormsActions.LoadSubmissionMessagesForLink,
    ) {
        patchState({
            formSubmissionMessages: [],
        });

        if (!link || !link.submissionMessages?.length) {
            return;
        }

        const { rowKey } = link;

        const actions = link.submissionMessages.reduce((acc, current) => {
            const name = `forms/${rowKey}/${current.rowKey}.html`;
            acc.push(
                this.blobService.downloadBlobBlock(name).pipe(
                    catchError(error => of(null)),
                    mergeMap(result => {
                        if (result && result.blobBody) {
                            return from(result.blobBody).pipe(
                                mergeMap(blob => readAsText(blob)),
                                map(content => {
                                    return {
                                        ...current,
                                        content,
                                    };
                                }),
                            );
                        }

                        return of({ ...current, content: '' });
                    }),
                ),
            );

            return acc;
        }, [] as Observable<any>[]);

        return forkJoin(actions).pipe(
            tap(submissionMessages => {
                patchState({
                    formSubmissionMessages: submissionMessages,
                });
            }),
        );
    }

    @Action(ManageFormsActions.ConfirmArchiveLinks)
    confirmArchiveLinks(ctx: StateContext<StateModel>, action: ManageFormsActions.ConfirmArchiveLinks) {
        return this.matDialog
            .open(ArchiveContentLinkConfirmationDialogComponent, {
                disableClose: true,
                data: action.links,
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.store.dispatch(new ManageFormsActions.ArchiveLinks(action.links));
                    }
                }),
            );
    }

    @Action(ManageFormsActions.ArchiveLinks)
    archiveLinks(ctx: StateContext<StateModel>, action: ManageFormsActions.ArchiveLinks) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageFormsService.archiveLinks(action.links.map(r => r.rowKey as string)).pipe(
            mergeMap(result => {
                const linkId = this.store.selectSnapshot(RouterState.selectRouteParam('linkId'));

                if (linkId) {
                    if (action.links.some(l => l.formLinkType === FormLinkType.Poll)) {
                        this.store.dispatch(new Navigate(['/home/poll-links']));
                    } else if (action.links.some(l => l.formLinkType === FormLinkType.Booking)) {
                        this.store.dispatch(new Navigate(['/home/booking-links']));
                    } else {
                        this.store.dispatch(new Navigate(['/home/form-links']));
                    }
                }

                return this.store.dispatch(new NotificationActions.Success('Link has been successfully archived'));
            }),
            catchError(err => this.store.dispatch(new ManageFormsActions.ArchiveLinksFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageFormsActions.ConfirmDeleteLinks)
    confirmDeleteLinks(ctx: StateContext<StateModel>, action: ManageFormsActions.ConfirmDeleteLinks) {
        return this.matDialog
            .open(DeleteShareableLinkConfirmationDialogComponent, {
                disableClose: true,
                data: action.links,
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.store.dispatch(new ManageFormsActions.DeleteLinks(action.links));
                    }
                }),
            );
    }

    @Action(ManageFormsActions.DeleteLinks)
    deleteLinks(ctx: StateContext<StateModel>, action: ManageFormsActions.DeleteLinks) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageFormsService.deleteLinks(action.links.map(m => m.rowKey as string)).pipe(
            mergeMap(result => {
                const linkId = this.store.selectSnapshot(RouterState.selectRouteParam('linkId'));

                if (linkId) {
                    if (action.links.some(l => l.formLinkType === FormLinkType.Poll)) {
                        this.store.dispatch(new Navigate(['/home/poll-links']));
                    } else if (action.links.some(l => l.formLinkType === FormLinkType.Booking)) {
                        this.store.dispatch(new Navigate(['/home/booking-links']));
                    } else {
                        this.store.dispatch(new Navigate(['/home/form-links']));
                    }
                }

                return this.store.dispatch(new NotificationActions.Success('Link has been successfully deleted'));
            }),
            catchError(err => this.store.dispatch(new ManageFormsActions.DeleteLinksFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageFormsActions.ConfirmCancelScheduledEvents)
    confirmCancelScheduledEvents(ctx: StateContext<StateModel>, action: ManageFormsActions.ConfirmCancelScheduledEvents) {
        return this.matDialog
            .open(CancelScheduledEventsConfirmationDialogComponent, {
                disableClose: true,
                data: action.sessions,
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.store.dispatch(new ManageFormsActions.CancelScheduledEvents(action.sessions));
                    }
                }),
            );
    }

    @Action(ManageFormsActions.CancelScheduledEvents)
    cancelScheduledEvents(ctx: StateContext<StateModel>, action: ManageFormsActions.CancelScheduledEvents) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageFormsService.cancelEventsForSessions(action.sessions.map(s => s.rowKey)).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageFormsActions.LoadLinkSubmissions(),
                    new NotificationActions.Success('Scheduled Event(s) Cancelled Successfully'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageFormsActions.CancelScheduledEventsFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageFormsActions.ConfirmMergeSession)
    confirmMergeSessions(ctx: StateContext<StateModel>, action: ManageFormsActions.ConfirmMergeSession) {
        const allSessions = this.store.selectSnapshot(ManageFormsState.getLinkSessions());
        const sessions: LinkSessionSummary[] = [];

        action.rowKeys.forEach(rowKey => {
            const session = allSessions.find(s => s.rowKey === rowKey);
            if (session) {
                sessions.push(session);
            }
        });

        return this.matDialog
            .open(MergeLinkSessionsConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    linkType: LinkType.Form,
                    sessions,
                },
            })
            .afterClosed()
            .pipe(
                tap((primary: LinkSessionSummary) => {
                    if (primary) {
                        this.store.dispatch(new ManageFormsActions.MergeSessions(primary.rowKey, action.rowKeys));
                    }
                }),
            );
    }

    @Action(ManageFormsActions.MergeSessions)
    mergeSessions(ctx: StateContext<StateModel>, action: ManageFormsActions.MergeSessions) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageFormsState.getEditModel()) as FormLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageFormsService.mergeSessions(rowKey, action.primaryRowKey, action.rowKeys).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageFormsActions.LoadLinkSessions(),
                    new ManageFormsActions.LoadLinkSubmissions(),
                    new ManageFormsActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submissions Merged Successfully'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageFormsActions.MergeSessionsFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageFormsActions.ConfirmDeleteSession)
    confirmDeleteSession(ctx: StateContext<StateModel>, action: ManageFormsActions.ConfirmDeleteSession) {
        return this.matDialog
            .open(DeleteLinkSessionsConfirmationDialogComponent, {
                disableClose: true,
                data: action.rowKeys,
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.store.dispatch(new ManageFormsActions.DeleteSessions(action.rowKeys));
                    }
                }),
            );
    }

    @Action(ManageFormsActions.DeleteSessions)
    deleteSession(ctx: StateContext<StateModel>, action: ManageFormsActions.DeleteSessions) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageFormsState.getEditModel()) as FormLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageFormsService.deleteSessions(action.rowKeys).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageFormsActions.LoadLinkSessions(),
                    new ManageFormsActions.LoadLinkSubmissions(),
                    new ManageFormsActions.UpdateLinkSummary(),
                    new ManageFormsActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission(s) Deleted Successfully'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageFormsActions.DeleteSessionFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageFormsActions.ActivateSession)
    activateSession(ctx: StateContext<StateModel>, action: ManageFormsActions.ActivateSession) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageFormsState.getEditModel()) as FormLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageFormsService.activateSession(action.rowKey).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageFormsActions.LoadLinkSessions(),
                    new ManageFormsActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission Activated'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageFormsActions.ActivateSessionFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageFormsActions.DeactivateSession)
    deactivateSession(ctx: StateContext<StateModel>, action: ManageFormsActions.DeactivateSession) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageFormsState.getEditModel()) as FormLinkModel;
        const rowKey = link.rowKey as string;

        return this.manageFormsService.deactivateSession(action.rowKey).pipe(
            mergeMap(() => {
                return this.store.dispatch([
                    new ManageFormsActions.LoadLinkSessions(),
                    new ManageFormsActions.LoadLinkSubmissions(),
                    new ManageFormsActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission Deactivated'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageFormsActions.DeactivateSessionFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageFormsActions.SyncActiveSessions)
    syncActiveSessions({ dispatch, patchState }: StateContext<StateModel>, action: ManageFormsActions.SyncActiveSessions) {
        patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageFormsService.syncActiveSessions(action.rowKey).pipe(
            tap(result => {
                dispatch([
                    new ManageFormsActions.UpdateEditLinkModel({
                        lateRegistrationCount: result.lateRegistrationCount,
                        activeSubmissionCount: result.activeSubmissionCount,
                        approvalCount: result.approvalCount,
                        sessionLimit: result.sessionLimit,
                    }),
                    new NotificationActions.Success('Active Submissions Synchronized'),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageFormsActions.SyncActiveSessionsFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageFormsActions.UpdateSessionCount)
    updateSessionCount({ patchState, dispatch }: StateContext<StateModel>, action: ManageFormsActions.UpdateSessionCount) {
        patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageFormsService.updateLinkCounts(action.rowKey).pipe(
            tap(result => {
                dispatch(
                    new ManageFormsActions.UpdateEditLinkModel({
                        lateRegistrationCount: result.lateRegistrationCount,
                        activeSubmissionCount: result.activeSubmissionCount,
                        approvalCount: result.approvalCount,
                        resubmissionCount: result.resubmissionCount,
                        formSubmissionCount: result.formSubmissionCount,
                    }),
                );
            }),
            catchError(err => this.store.dispatch(new ManageFormsActions.UpdateSessionCountFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageFormsActions.CopyLinkToClipboard)
    copyLinkToClipboard(ctx: StateContext<StateModel>, action: ManageFormsActions.CopyLinkToClipboard): void {
        const model = action.link;
        const url = formatLinkUrl(model, this.env);
        const details = [`Shareable Link: ${url}`];

        if (model.isPasswordRequired) {
            details.push('');
            details.push(`Password: ${model.password}`);
        }

        switch (model.linkExpiration) {
            case LinkExpiration.Timer:
                switch (model.timerUnit) {
                    case TimerUnit.Months:
                        details.push(`Timer length after activation (${model.timer}m)`);
                        break;
                    case TimerUnit.Weeks:
                        details.push(`Timer length after activation (${model.timer}w)`);
                        break;
                    case TimerUnit.Days:
                        details.push(`Timer length after activation (${model.timer}d)`);
                        break;
                    case TimerUnit.Hours:
                        details.push(`Timer length after activation (${model.timer}h)`);
                        break;
                    case TimerUnit.Minutes:
                        details.push(`Timer length after activation (${model.timer}min)`);
                        break;
                    default:
                }
                if (model.timerEndDate) {
                    details.push('');
                    details.push(`Expires ${this.formatDate(model.timerEndDate, action.timezone)}`);
                }
                break;
            case LinkExpiration.Date:
                if (model.startDate) {
                    details.push(`Starts ${this.formatDate(model.startDate, action.timezone)}`);
                }
                if (model.endDate) {
                    details.push(`Expires ${this.formatDate(model.endDate, action.timezone)}`);
                }
                break;
            default:
        }

        if (model.sessionLimit) {
            details.push('');
            details.push(`Active Device & Browser Limit (${model.sessionLimit})`);
        }

        if (model.registrationEndDate) {
            details.push('');
            details.push(`Registration Cut-off ${this.formatDate(model.registrationEndDate, action.timezone)}`);
        }

        if (model.registrationMode === RegistrationMode.Onetime) {
            details.push('');
            details.push(`Link is configured for a one-time access`);
        }

        if (action.includeInfluencer) {
            details.push('');
            details.push(`*Influencer Password: ${model.influencerPassword}*`);
        }

        this.store.dispatch(
            new CoreActions.CopyToClipboard({
                text: details.join('\r\n'),
                message: 'Link copied to clipboard',
            }),
        );
    }

    @Action(ManageFormsActions.OpenLink)
    openLink(ctx: StateContext<ManageFormsState>, action: ManageFormsActions.OpenLink): Observable<any> | void {
        const url = formatLinkUrl(action.link, this.env);

        if (action.link.isPasswordRequired) {
            return this.store
                .dispatch(
                    new CoreActions.CopyToClipboard({
                        text: action.link.password,
                    }),
                )
                .pipe(tap(() => window.open(url, `_blank`)));
        }

        window.open(url, `_blank`);
    }

    @Action(ManageFormsActions.ApproveSessions)
    approveSessions(ctx: StateContext<StateModel>, action: ManageFormsActions.ApproveSessions) {
        ctx.patchState({
            isSaving: true,
            errors: null,
        });

        const link = this.store.selectSnapshot(ManageFormsState.getEditModel()) as FormLinkModel;
        const rowKey = link?.rowKey || action.linkId;

        return this.manageFormsService.approveSession(rowKey, action.rowKeys).pipe(
            mergeMap(() => {
                const actions = [
                    new ManageFormsActions.LoadLinkSessions(),
                    new ManageFormsActions.UpdateSessionCount(rowKey),
                    new NotificationActions.Success('Submission(s) Approved'),
                ];

                if (link && link.hasRegistration && link.registrationEndDate) {
                    const endDate = new Date(link.registrationEndDate);
                    const now = new Date();

                    if (now > endDate) {
                        actions.push(new ManageFormsActions.SyncActiveSessions(rowKey));
                    }
                }

                return this.store.dispatch(actions);
            }),
            catchError(err => this.store.dispatch(new ManageFormsActions.ApproveSessionsFailure(err))),
            finalize(() =>
                ctx.patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageFormsActions.UploadFile)
    uploadFile({ patchState, dispatch }: StateContext<StateModel>, { fileId, getFile }: ManageFormsActions.UploadFile) {
        const file = getFile();
        const { name, type } = file;
        const id = `forms/${fileId}/${name}`;

        return this.blobService.uploadFile(id, file, type, BlobContainer.Private);
    }

    @Action(ManageFormsActions.ShowExtendLinkDialog)
    showExtendLinkDialog(ctx: StateContext<StateModel>, action: ManageFormsActions.ShowExtendLinkDialog) {
        return this.matDialog
            .open(ExtendLinkDialogComponent, {})
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        ctx.dispatch(new ManageFormsActions.ExtendLinkExpirationDate(action.links, result));
                    }
                }),
            );
    }

    @Action(ManageFormsActions.UpdateEditLinkModel)
    updateEditLink(ctx: StateContext<StateModel>, action: ManageFormsActions.UpdateEditLinkModel) {
        const editLink = this.store.selectSnapshot(ManageFormsState.getEditModel()) as FormLinkModel;

        if (!editLink) {
            return;
        }

        ctx.patchState({
            editLink: { ...editLink, ...action.model },
        });
    }

    @Action(ManageFormsActions.ExtendLinkExpirationDate)
    extendLinkExpirationDate(
        { patchState, dispatch }: StateContext<StateModel>,
        action: ManageFormsActions.ExtendLinkExpirationDate,
    ) {
        const editLink = this.store.selectSnapshot(ManageFormsState.getEditModel()) as FormLinkModel;
        const rowKeys = action.links.map(r => r.rowKey) as string[];
        const days = action.days;
        const updateEditLink = rowKeys.includes(editLink?.rowKey || '');

        return this.manageFormsService.extendLinkExpirationDate(rowKeys, days).pipe(
            tap(links => {
                if (!updateEditLink) {
                    return;
                }

                const link = links.find(s => s.rowKey === editLink?.rowKey);

                if (!link) {
                    return;
                }

                dispatch([
                    new ManageFormsActions.UpdateEditLinkModel({
                        linkExpiration: link.linkExpiration,
                        endDate: link.endDate,
                    }),
                    new NotificationActions.Success('Link(s) extended successfully'),
                ]);
            }),
            catchError(err => dispatch(new ManageFormsActions.ExtendLinkExpirationDateFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageFormsActions.CompleteSubmission)
    completeSubmission(
        { patchState, dispatch }: StateContext<StateModel>,
        { submission }: ManageFormsActions.CompleteSubmission,
    ): Observable<any> | void {
        patchState({
            isSaving: true,
            errors: null,
        });

        return this.manageFormsService.completeSubmission(submission.rowKey).pipe(
            tap(() => {
                const submissions = this.store.selectSnapshot(ManageFormsState.getLinkSubmissions());

                patchState({
                    linkSubmissions: submissions.map(s => {
                        if (submission.rowKey === s.rowKey) {
                            submission.isComplete = true;
                        }

                        return submission;
                    }),
                });

                this.store.dispatch([
                    new NotificationActions.Success('Submission Completed'),
                    new ManageFormsActions.LoadLinkSubmissions(),
                    new ManageFormsActions.LoadLinkSubmissions(),
                ]);
            }),
            catchError(err => this.store.dispatch(new ManageFormsActions.CompleteSubmissionFailure(err))),
            finalize(() =>
                patchState({
                    isSaving: false,
                }),
            ),
        );
    }

    @Action(ManageFormsActions.SearchArchivedLinks, { cancelUncompleted: true })
    searchArchivedLinks({ patchState, getState }: StateContext<StateModel>, action: ManageFormsActions.SearchArchivedLinks) {
        const { archivedFormLinkSearchOptions: options } = getState();
        const model = action.model;

        if (model.total === undefined) {
            model.total = options?.total;
        }

        patchState({ isSearching: true });

        return this.manageFormsService.searchArchivedLinks(model).pipe(
            tap(queryResult => {
                patchState({
                    archivedFormLinkSearchResults: queryResult,
                    archivedFormLinkSearchOptions: {
                        filter: '',
                        tags: [],
                        useNewSearchMethod: model.useNewSearchMethod,
                        limit: queryResult.limit,
                        total: queryResult.total,
                        offset: queryResult.offset,
                        formLinkType: model.formLinkType,
                        linkFilters: [],
                    },
                });
            }),
            catchError(err => this.store.dispatch(new ManageFormsActions.SearchArchivedLinksFailure(err))),
            finalize(() => patchState({ isSearching: false, hasLoadedArchivedLinks: true })),
        );
    }

    @Action([
        ManageFormsActions.ActivateSessionFailure,
        ManageFormsActions.SearchLinksFailure,
        ManageFormsActions.SearchArchivedLinksFailure,
        ManageFormsActions.SaveFailure,
        ManageFormsActions.DeleteLinksFailure,
        ManageFormsActions.ArchiveLinksFailure,
        ManageFormsActions.DeleteSessionFailure,
        ManageFormsActions.MergeSessionsFailure,
        ManageFormsActions.ApproveSessionsFailure,
        ManageFormsActions.LoadLinkSessionsFailure,
        ManageFormsActions.DeactivateSessionFailure,
        ManageFormsActions.SyncActiveSessionsFailure,
        ManageFormsActions.UpdateSessionCountFailure,
        ManageFormsActions.LoadArchivedLinkSessionsFailure,
    ])
    handleFailures({ patchState }: StateContext<StateModel>, action: ManageFormsFailureTypes): Observable<any> | void {
        switch (true) {
            case isErrorModel(action.error) && action.error.isConnectionError:
                {
                    //do nothing
                    console.log('NoConnection');
                }
                break;
            case action instanceof ManageFormsActions.SearchLinksFailure:
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error Loading Links', action.error));
            case action instanceof ManageFormsActions.SearchArchivedLinksFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Loading Archived Links', action.error),
                );
            case action instanceof ManageFormsActions.SaveFailure: {
                if (action.error instanceof ErrorModel && action.error.errorCode === ErrorCodes.ValidationError) {
                    patchState({ errors: action.error });
                    return;
                }

                return this.store.dispatch(new NotificationActions.Error('Unexpected Error Saving Link', action.error));
            }
            case action instanceof ManageFormsActions.DeleteLinksFailure:
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error Deleting Link(s)', action.error));
            case action instanceof ManageFormsActions.ArchiveLinksFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Archiving Link(s)', action.error),
                );
            case action instanceof ManageFormsActions.DeactivateSessionFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Deactivating Session(s)', action.error),
                );
            case action instanceof ManageFormsActions.DeleteSessionFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Deleting Link Session(s)', action.error),
                );
            case action instanceof ManageFormsActions.SyncActiveSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Syncing Link Sessions', action.error),
                );
            case action instanceof ManageFormsActions.UpdateSessionCountFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Updating Session Counts', action.error),
                );
            case action instanceof ManageFormsActions.LoadArchivedLinkSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Loading Link Sessions', action.error),
                );
            case action instanceof ManageFormsActions.LoadLinkSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Loading Link Sessions', action.error),
                );
            case action instanceof ManageFormsActions.MergeSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Merging Link Sessions', action.error),
                );
            case action instanceof ManageFormsActions.ApproveSessionsFailure:
                return this.store.dispatch(
                    new NotificationActions.Error('Unexpected Error Approving Link Session(s)', action.error),
                );
            default:
                return this.store.dispatch(new NotificationActions.Error('Unexpected Error', action.error));
        }
    }

    private formatDate(date: Date, timeZone: Timezone): string {
        return formatDatetimeWithTimeZone(date, timeZone);
    }
}

type ManageFormsFailureTypes =
    | ManageFormsActions.ActivateSessionFailure
    | ManageFormsActions.SearchLinksFailure
    | ManageFormsActions.SearchArchivedLinksFailure
    | ManageFormsActions.SaveFailure
    | ManageFormsActions.DeleteLinksFailure
    | ManageFormsActions.ArchiveLinksFailure
    | ManageFormsActions.DeleteSessionFailure
    | ManageFormsActions.MergeSessionsFailure
    | ManageFormsActions.ApproveSessionsFailure
    | ManageFormsActions.LoadLinkSessionsFailure
    | ManageFormsActions.DeactivateSessionFailure
    | ManageFormsActions.SyncActiveSessionsFailure
    | ManageFormsActions.UpdateSessionCountFailure
    | ManageFormsActions.LoadArchivedLinkSessionsFailure;

function getDefaultFormLinkModel(formLinkType: FormLinkType): FormLinkModel {
    return {
        rowKey: null,
        description: '',
        nodeIds: [],
        questions: [],
        videoSettings: {
            allowListeners: false,
            autoplay: false,
            elapsedTimeInSeconds: 0,
            enableWaitingRoom: false,
            waitingRoomOffsetInSeconds: 60,
            showWatermark: true,
            showBackgroundWatermark: true,
            showControls: true,
            showPlayButton: true,
            showFullscreenButton: true,
            showProgress: true,
            allowScrubbing: true,
            showPlaybackSpeed: false,
            resumePlayback: false,
            startTime: VideoOption.None,
            countDownAssetId: '',
            isLiveStream: false,
        },
        password: generatePassword(),
        influencerPassword: '',
        isDeleted: false,
        url: '',
        requestedBy: '',
        activationDate: null,
        status: LinkStatus.Active,
        timer: null,
        timerUnit: TimerUnit.Hours,
        isNameRequired: true,
        isPasswordRequired: false,

        allowResubmission: false,

        timerEndDate: null,
        sessionLimit: null,
        startDate: null,
        endDate: null,
        type: LinkType.Form,
        requiresApproval: false,
        requiresSubmission: true,
        isGuidedSequence: false,
        formLinkType: formLinkType,
        pages: [
            {
                id: randomId(6),
                children: [],
                description: '',
                sectionType: FormSectionType.Page,
                title: 'Untitled Page 1',
                backgroundColour: null,
                fontColour: null,
                goToPage: '',
                isEnabled: true,
            },
        ],

        hasRegistration: false,
        displayWatermark: true,
        linkExpiration: LinkExpiration.None,
        linkInactivityMode: LinkInactivityMode.Default,
        lastActivityDate: null,
        tags: [],

        createdDate: new Date(),
        registrationMode: RegistrationMode.Default,
        timeZone: 'America/Edmonton',
        registrationEndDate: null,
        timestamp: null,
        archivedDate: null,
        linkExpirationDate: null,
        incompleteCount: 0,
        completeCount: 0,
        lateRegistrationCount: 0,
        activeSubmissionCount: 0,
        resubmissionCount: 0,
        approvalCount: 0,
        formSubmissionCount: 0,
        influencerCount: 0,
        requestedByVisitorId: null,

        isPreambleRequired: true,
        preambleTemplateId: null,
        checklistTemplateIds: [],
        hasAdminAcknowledged: true,
        submissionMessages: [
            {
                rowKey: randomId(6),
                name: 'Default',
                content: `<p>Your submission has been completed.</p> <p>Thank you for your time.</p>`,
                sasUri: '',
                hasLoaded: false,
            },
        ],
        archivedBy: null,
        archivedReason: null,
        autoArchive: true,
        isExpired: false,
        isActive: true,
        displayRegistrationCountdown: false,
        allowUsersToRequestMoreTime: false,
        allowInfluencerToNotifyAdmin: false,
        allowInfluencerAdminActions: false,
    } as FormLinkModel;
}
