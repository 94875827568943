import { Injectable, inject } from '@angular/core';

import { tap } from 'rxjs';

import { ScheduledEvent } from '@app/data/models';
import { Action, createSelector, State, StateContext, Store } from '@ngxs/store';

import { CalendlyActions } from '../actions';
import { CalendlyService } from '../services/calendly.service';

export interface StateModel {
    accessCode: string | null;
    accessToken: string | null;
    refreshToken: string | null;
    isInitialized: boolean;
    expiresAt: number | null;

    scheduledEvents: ScheduledEvent[];
}

@State<StateModel>({
    name: 'calendly',
    defaults: {
        accessCode: null,
        accessToken: null,
        expiresAt: null,
        isInitialized: false,
        refreshToken: null,

        scheduledEvents: [],
    },
})
@Injectable({
    providedIn: 'root',
})
export class CalendlyState {
    private calendlyService = inject(CalendlyService);

    windowRef: Window | null = null;

    static getToken() {
        return createSelector([CalendlyState], (state: StateModel) => state.accessToken);
    }

    static getScheduledEvents() {
        return createSelector([CalendlyState], (state: StateModel) => state.scheduledEvents);
    }

    static isAuthenticated() {
        return createSelector([CalendlyState], (state: StateModel) => !!state.accessToken);
    }

    @Action(CalendlyActions.ClearEvents)
    clearEvents({ patchState }: StateContext<StateModel>) {
        patchState({
            scheduledEvents: [],
        });
    }

    @Action(CalendlyActions.LoadScheduledEvents)
    loadScheduledEvents(
        { dispatch, getState, patchState }: StateContext<StateModel>,
        { eventIds }: CalendlyActions.LoadScheduledEvents,
    ) {
        return this.calendlyService.getScheduledEventById(eventIds).pipe(
            tap(events => {
                const { scheduledEvents } = getState();

                events.forEach(ev => {
                    const ix = scheduledEvents.findIndex(e => e.uri.includes(ev.uri));
                    if (ix > -1) {
                        scheduledEvents[ix] = ev;
                    } else {
                        scheduledEvents.push(ev);
                    }
                });

                patchState({
                    scheduledEvents: [...scheduledEvents],
                });
            }),
        );
    }
}
