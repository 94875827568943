<form [formGroup]="form" ngxsForm="zoom.createMeetingForm" [ngxsFormClearOnDestroy]="true">
    <div>
        <div class="field">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Zoom Meeting Template</mat-label>
                <mat-select formControlName="templateId">
                    @for (template of filteredTemplates; track template) {
                    <mat-option [value]="template.id">
                        {{ template.name }}
                    </mat-option>
                    }
                </mat-select>
                <button matSuffix mat-icon-button (click)="onRefresh()">
                    <mat-icon>refresh</mat-icon>
                </button>
                @if (ctrls['templateId'].invalid) {
                <mat-error>
                    <shared-error-display [error]="ctrls['templateId'].errors"></shared-error-display>
                </mat-error>
                }
            </mat-form-field>
        </div>
        <div class="field">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Meeting Name</mat-label>
                <input matInput formControlName="topic" />
                @if (ctrls['topic'].invalid) {
                <mat-error>
                    <shared-error-display [error]="ctrls['topic'].errors"></shared-error-display>
                </mat-error>
                }
            </mat-form-field>
        </div>
        <div class="field">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Agenda</mat-label>
                <textarea matInput formControlName="agenda"></textarea>
                @if (ctrls['agenda'].invalid) {
                <mat-error>
                    <shared-error-display [error]="ctrls['agenda'].errors"></shared-error-display>
                </mat-error>
                }
            </mat-form-field>
        </div>
        <div class="field">
            <mat-form-field appearance="outline">
                <mat-label>Password (Not Required)</mat-label>
                <input matInput formControlName="password" />
                @if (ctrls['password'].invalid) {
                <mat-error>
                    <shared-error-display [error]="ctrls['password'].errors"></shared-error-display>
                </mat-error>
                }
            </mat-form-field>
        </div>
        <div class="field time-picker">
            <admin-date-time-picker
                label="Start Date"
                placeholder="Set Start Date"
                hint="When does this webinar start?"
                [showTimeZone]="true"
                [errors]="ctrls['startDate'].errors"
                formControlName="startDate"
                (timeZoneChange)="onTimeZoneChange($event)"
            ></admin-date-time-picker>
            <div class="time-summary">
                @if (isDifferentTimeZone) {
                <small> <b>Local Time:</b> {{ ctrls['startDate'].value | formatDate }} </small>
                }
                <small>
                    <b>Webinar Time:</b>
                    {{ ctrls['startDate'].value | formatDate : ctrls['timeZone'].value }}
                </small>
            </div>
        </div>
        <div class="field">
            <mat-form-field appearance="outline">
                <mat-label>Duration</mat-label>
                <mat-hint>Duration in minutes</mat-hint>
                <input matInput type="number" min="1" formControlName="duration" />
                @if (ctrls['duration'].invalid) {
                <mat-error>
                    <shared-error-display [error]="ctrls['duration'].errors"></shared-error-display>
                </mat-error>
                }
            </mat-form-field>
        </div>
    </div>
</form>
