import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';

import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { filter, finalize, map, mergeMap, take, takeUntil, tap } from 'rxjs';

import { ManageMediaState } from '@app/admin/state/manage-media.state';
import { DropMediaModel } from '@app/data/models';
import { handleReportProgress } from '@app/data/operators';
import { BlobService } from '@app/data/services/blob.service';
import { AuthState } from '@app/data/state/auth.state';
import { MediaType } from '@app/shared/enums';
import { Environment } from '@app/shared/models';
import { DestroyService } from '@app/shared/services/destroy.service';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { replaceIllegalCharacters } from '@app/shared/util';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { AngularNodeViewComponent, TiptapDraggableDirective } from 'ngx-tiptap';

import { ManageMediaActions } from '../../actions';
import { TiptapMediaAssetComponent } from '../../components/tiptap-media-asset/tiptap-media-asset.component';
import { InlineMediaAssetDialogComponent } from '../../dialogs/inline-media-asset/inline-media-asset-dialog.component';
import { getFileNameForMediaAssetUpload } from '../../utils';

@Component({
    selector: 'admin-tiptap-media-asset-container',
    templateUrl: './tiptap-media-asset-container.component.html',
    styleUrls: ['./tiptap-media-asset-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService],
    imports: [
        TiptapDraggableDirective,
        NgClass,
        MatButton,
        NgTemplateOutlet,
        TiptapMediaAssetComponent,
        MatProgressSpinner,
        AsyncPipe
    ]
})
export class TiptapMediaAssetContainerComponent extends AngularNodeViewComponent implements OnInit {
    private env = inject<Environment>(APP_ENVIRONMENT);
    private store = inject(Store);
    private actions = inject(Actions);
    private dialog = inject(MatDialog);
    private cd = inject(ChangeDetectorRef);
    private blobService = inject(BlobService);
    private destroy$ = inject(DestroyService, { self: true });

    id = performance.now().toString();

    isUploading = false;
    isUploadingComplete = false;
    uploadProgress = 0;

    waitingForState = false;
    fileName = '';

    token$ = this.store.select(AuthState.getToken());
    currentUser$ = this.store.select(AuthState.getCurrentUser());

    encodingJobs$ = this.store.select(ManageMediaState.getEncodingJobs());

    get mediaFile(): DropMediaModel | null {
        return this.node().attrs['mediaFile'];
    }

    get mediaAssetId(): string | null {
        return this.node().attrs['mediaAssetId'];
    }

    get isEditable() {
        return this.editor().isEditable;
    }

    get isSelected() {
        return this.selected;
    }

    ngOnInit(): void {
        this.editor().on('transaction', ({ editor, transaction }) => {
            this.cd.detectChanges();
        });

        this.editor().on('selectionUpdate', () => {
            this.cd.detectChanges();
        });
    }

    onUploadMediaFile(file: File): void {
        this.isUploading = true;
        this.isUploadingComplete = false;
        const isVideo = file.type.startsWith('video');
        const name = replaceIllegalCharacters(file.name);
        const mediaType = isVideo ? MediaType.Video : MediaType.Audio;

        this.actions
            .pipe(
                takeUntil(this.destroy$), // should finish the entire action, even if the component is destroyed
                ofActionDispatched(ManageMediaActions.CreateMediaAssetSuccess),
                filter(({ asset }: ManageMediaActions.CreateMediaAssetSuccess) => asset.clientId === this.id),
                take(1),
                mergeMap(({ asset }) => {

                    this.updateAttributes()({ mediaAssetId: asset.rowKey, mediaFile: null });
                    this.fileName = asset.title;
                    this.waitingForState = true;
                    this.cd.markForCheck();

                    const assetContainer = this.env.privateContainerName;
                    const assetName = getFileNameForMediaAssetUpload(asset.rowKey, file);

                    this.store.dispatch([
                        new ManageMediaActions.UpdateMediaAsset(asset),
                    ]);

                    return this.blobService
                        .uploadFile(assetName, file, file.type, assetContainer)
                        .pipe(
                            handleReportProgress(({ loadedBytes, isComplete }) => {
                                if (!isComplete) {
                                    this.uploadProgress = Math.floor(100 * (loadedBytes / file.size));
                                    this.cd.markForCheck();
                                } else {
                                    this.uploadProgress = 100;
                                    this.isUploadingComplete = true;
                                    this.cd.markForCheck();
                                }
                            }),
                            map(() => asset),
                            finalize(() => {
                                this.isUploading = false;
                                this.cd.markForCheck();
                            }),
                        );
                }),
                filter(() => this.isUploadingComplete),
                mergeMap(asset => this.store.dispatch(new ManageMediaActions.SendToEncodingQueue(asset))),
            )
            .subscribe();

        this.store.dispatch(new ManageMediaActions.CreateMediaAsset(name, mediaType, this.id));

        this.cd.markForCheck();
    }

    onEdit(): void {
        this.dialog
            .open(InlineMediaAssetDialogComponent, {
                hasBackdrop: true,
                closeOnNavigation: true,
                disableClose: true,
                data: this.mediaAssetId,
                height: '80vh',
                width: '80vw',
            })
            .afterClosed()
            .pipe(
                tap(result => {
                    if (result) {
                        this.updateAttributes()({ mediaAssetId: result });
                        this.cd.detectChanges();
                    }
                }),
            )
            .subscribe();
    }
}
