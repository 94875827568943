import { Component, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { NodeType } from '@app/shared/enums';
import { CdkScrollable } from '@angular/cdk/scrolling';

import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';

@Component({
    templateUrl: './delete-node-confirmation-dialog.component.html',
    styles: [
        `
            :host {
                width: 600px;
                display: block;
            }

            mat-dialog-content {
                height: 100px;
            }
        `,
    ],
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        ReactiveFormsModule,
        MatCheckbox,
        MatDialogActions,
        MatButton,
        MatDialogClose
    ]
})
export class DeleteNodeConfirmationDialogComponent {
    data = inject(MAT_DIALOG_DATA);
    private fb = inject(FormBuilder);

    NodeType: typeof NodeType = NodeType;

    form: FormGroup = this.fb.group({
        includeChildren: new FormControl(false),
        includeMediaAsset: new FormControl(false),
    });

    get name(): string {
        return `${this.data.title}`;
    }

    get isFolder(): boolean {
        return this.data.type === NodeType.Node;
    }

    get isMediaAsset(): boolean {
        return this.data.type === NodeType.Video || this.data.type === NodeType.Audio;
    }
}
