import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup, MatTab } from '@angular/material/tabs';

import { merge, Observable } from 'rxjs';

import { QueryResult, SearchOptionsModel, ShareableLinkModel, ShareableLinkTemplateModel } from '@app/data/models';
import { LinkType } from '@app/shared/enums';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Timezone } from 'timezones.json';

import { ManageLinksActions, ManageTemplateActions } from '../../actions';
import { ManageLinksState,  } from '../../state/manage-links.state';
import { MatCard, MatCardHeader, MatCardTitle, MatCardContent } from '@angular/material/card';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { ErrorDisplayComponent } from '@app/shared/components/error-display/error-display.component';
import { ShareableLinksListComponent } from '../../components/shareable-links-list/shareable-links-list.component';
import { ShareableLinkTemplateListComponent } from '../../components/shareable-link-template-list/shareable-link-template-list.component';
import { ArchivedLinkListComponent } from '../../components/archived-link-list/archived-link-list.component';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatAnchor } from '@angular/material/button';
import { AnyPipe } from '@app/shared/pipes/any.pipe';
import { ManageTemplatesState } from '@app/admin/state/manage-templates.state';

export enum TabIndex {
    Links = 0,
    Templates,
    ArchivedLinks,
}
@Component({
    selector: 'admin-shareable-links-container',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./shareable-links-list-container.component.html`,
    styleUrls: [`./shareable-links-list-container.component.scss`],
    imports: [
        MatCard,
        MatCardHeader,
        MatCardTitle,
        NgTemplateOutlet,
        MatCardContent,
        ErrorDisplayComponent,
        MatTabGroup,
        MatTab,
        ShareableLinksListComponent,
        ShareableLinkTemplateListComponent,
        ArchivedLinkListComponent,
        MatButtonToggleGroup,
        MatButtonToggle,
        MatIcon,
        MatMenuTrigger,
        MatMenu,
        MatMenuItem,
        MatAnchor,
        AnyPipe,
        AsyncPipe
    ]
})
export class ShareableLinksListContainerComponent {
    private store = inject(Store);

    TabIndex: typeof TabIndex = TabIndex;

    links$: Observable<ShareableLinkModel[] | null> = this.store.select(ManageLinksState.getLinks());
    archivedLinks$: Observable<QueryResult<ShareableLinkModel> | null> = this.store.select(
        ManageLinksState.getSearchArchivedLinksResults(),
    );
    error$: Observable<any> = this.store.select(ManageLinksState.getErrors());
    isSaving$ = this.store.select(ManageLinksState.isSaving());
    isLoading$ = merge(
        this.store.select(ManageLinksState.isLoading()),
        this.store.select(ManageTemplatesState.isLoadingTemplates()),
        this.store.select(ManageLinksState.isSearchingArchivedLinks()),
    );

    selectedIndex$ = this.store.select(ManageLinksState.currentTabIndex());

    shareableLinkTemplates$ = this.store.select(ManageTemplatesState.getShareableLinkTemplates());

    tags$ = this.store.select(ManageLinksState.getTags());

    archivedLinksSearchOptions$: Observable<SearchOptionsModel | null> = this.store.select(
        ManageLinksState.getSearchArchivedLinksSearchOptions(),
    );

    onTabClick(event: MatTabChangeEvent): void {
        this.store.dispatch(new ManageLinksActions.SetCurrentTabIndex(event.index));

        switch (event.index) {
            case TabIndex.Links:
                this.store.dispatch(new ManageLinksActions.EnsureLoadLinks());
                break;
            case TabIndex.Templates:
                this.store.dispatch(new ManageTemplateActions.EnsureLoadLinkTemplates());
                break;
            case TabIndex.ArchivedLinks:
                {
                    const results = this.store.selectSnapshot(ManageLinksState.getSearchArchivedLinksResults());
                    if (!results) {
                        this.store.dispatch(new ManageLinksActions.SearchArchivedLinks());
                    }
                }
                break;
        }
    }

    onOpenLink(model: ShareableLinkModel): void {
        this.store.dispatch(new ManageLinksActions.OpenLink(model));
    }

    onCopyLinkToClipboard({
        model,
        timeZone,
        includeInfluencer,
    }: {
        model: ShareableLinkModel;
        timeZone: Timezone;
        includeInfluencer: boolean;
    }): void {
        this.store.dispatch(new ManageLinksActions.CopyLinkToClipboard(model, timeZone, includeInfluencer));
    }

    onRefresh() {
        this.store.dispatch([new ManageLinksActions.LoadLinks()]);
    }

    onSearch() {
        this.store.dispatch([new ManageLinksActions.SearchLinksByName()]);
    }

    onRefreshShareableLinkTemplates() {
        this.store.dispatch([new ManageTemplateActions.LoadShareableLinkTemplates()]);
    }

    onRefreshArchived(model: SearchOptionsModel) {
        this.store.dispatch([new ManageLinksActions.SearchArchivedLinks(model)]);
    }

    onArchive(links: ShareableLinkModel[]): void {
        this.store.dispatch(new ManageLinksActions.ConfirmArchiveLinks(links));
    }

    onDelete(links: ShareableLinkModel[]): void {
        this.store.dispatch(new ManageLinksActions.ConfirmDeleteLinks(links));
    }

    onExtend(links: ShareableLinkModel[]): void {
        this.store.dispatch(new ManageLinksActions.ShowExtendLinkDialog(links));
    }

    onView(model: ShareableLinkModel): void {
        this.store.dispatch(new ManageLinksActions.ViewLink(model));
    }

    onEdit(link: ShareableLinkModel): void {
        this.store.dispatch(new Navigate(['/home/shareable-links', link.rowKey]));
    }

    onEditShareableLinkTemplate(link: ShareableLinkTemplateModel): void {
        this.store.dispatch(new Navigate(['/home/shareable-links/template', link.rowKey]));
    }

    onDeleteShareableLinkTemplate(links: ShareableLinkTemplateModel[]): void {
        this.store.dispatch(new ManageTemplateActions.ConfirmDeleteTemplates(LinkType.Shareable, links));
    }

    onViewArchivedLink(link: ShareableLinkModel): void {
        this.store.dispatch([new ManageLinksActions.ViewArchivedLink(link)]);
    }

    onCopyLink(link: ShareableLinkModel): void {
        this.store.dispatch([new ManageLinksActions.CopyLink(link)]);
    }

    onCopyShareableLinkTemplate(link: ShareableLinkTemplateModel | null): void {
        this.store.dispatch(new ManageLinksActions.CopyShareableLinkTemplate(link));
    }

    onAddLink() {
        this.store.dispatch([new Navigate(['/home/shareable-links/new'])]);
    }

    onSelectTemplate(link: ShareableLinkTemplateModel): void {
        this.store.dispatch([new ManageLinksActions.CopyLink(link)]);
    }
}
