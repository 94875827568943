import { Component, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

import { DropMediaComponent } from '../../components/drop-media/drop-media.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { DropMediaComponent as DropMediaComponent_1 } from '../../components/drop-media/drop-media.component';
import { MatButton } from '@angular/material/button';

@Component({
    templateUrl: `./drop-media-dialog.component.html`,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        DropMediaComponent_1,
        MatDialogActions,
        MatButton,
        MatDialogClose,
    ]
})
export class DropMediaDialogComponent {
    private dialogRef = inject<MatDialogRef<DropMediaDialogComponent>>(MatDialogRef);

    mediaFiles: File[] = [];
    isPublic = false;

    @ViewChild(DropMediaComponent) dropMediaComponent!: DropMediaComponent;

    constructor() {
        const data = inject(MAT_DIALOG_DATA);

        this.mediaFiles = data.mediaFiles;
        this.isPublic = data.isPublic;
    }

    onClose() {
        this.dialogRef.close(this.dropMediaComponent.validMediaFiles);
    }
}
