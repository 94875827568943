<div class="header">
    @if ((isSaving$ | async) === true) {
    <mat-spinner diameter="24"></mat-spinner>
    }
    <button mat-icon-button (click)="isExpanded = !isExpanded">
        <mat-icon>{{ isExpanded ? 'expand_more' : 'expand_less' }}</mat-icon>
    </button>
    <button mat-icon-button color="warn" (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="content">
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">sticky_note_2</mat-icon>
            Notes
        </ng-template>
        <section class="notes" [@detailExpand]="isExpanded ? 'expanded' : 'collapsed'">
            <admin-notes-list
                [notes]="notes$ | async"
                [isLoading]="isLoadingNotes$ | async"
                (add)="onAdd()"
                (delete)="onDelete($event)"
                (pin)="onPin($event)"
                (selectionChange)="onSelectionChange($event)"
            ></admin-notes-list>
            @if (model$ | async) {
            <admin-notes
                [nodes]="nodes$ | async"
                [model]="model$ | async"
                (save)="onSave($event)"
                (nodeSelected)="onNodeSelected($event)"
            ></admin-notes>
            }
        </section>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">task</mat-icon>
            Tasks
        </ng-template>
        <section class="admin-tasks" [@detailExpand]="isExpanded ? 'expanded' : 'collapsed'">
            <admin-tasks (save)="onSaveTasks($event)" [tasks]="tasks$ | async"></admin-tasks>
        </section>
    </mat-tab>
</mat-tab-group>
