<section class="filter-container">
    <div [formGroup]="form" class="fx filter-ctrls" [ngClass]="filterControlClasses">
        <mat-form-field class="ctrl" appearance="outline">
            <mat-label>Filter</mat-label>
            <input
                formControlName="filter"
                matInput
                autocomplete="off"
                [matAutocomplete]="auto"
                (keyup)="onUpdateFilter()"
                (click)="onFilterClick()"
            />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTag($event)">
                @for (tag of filteredTags(); track tag) {
                <mat-option [value]="tag">
                    {{ tag }}
                </mat-option>
                }
            </mat-autocomplete>
            @if (form.controls['filter'].value){
            <button class="remove-option" matSuffix mat-icon-button (click)="onClearFilter()">
                <mat-icon color="warn">clear</mat-icon>
            </button>
            }
        </mat-form-field>
        @for (ctrl of config(); track ctrl.key){ @switch(ctrl.type) { @case('select') {
        <mat-form-field class="ctrl" appearance="outline">
            <mat-label> {{ ctrl.label }} </mat-label>
            <mat-select [formControlName]="ctrl.key" [multiple]="ctrl.multiple === true">
                @for (opt of ctrl.options; track opt.key) {
                <mat-option [value]="opt.key">
                    {{ opt.value }}
                </mat-option>
                }
            </mat-select>
        </mat-form-field>
        } } }
        <mat-chip-listbox class="fx-full">
            @for (tag of displayTags(); track tag) {
            <mat-chip-option [removable]="true" [selectable]="false" (removed)="removeTag(tag)">
                {{ tag }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
            }
        </mat-chip-listbox>
    </div>

    <div class="refresh">
        @if(refreshButtonTemplate(); as tpl){
        <ng-container *ngTemplateOutlet="tpl"> </ng-container>
        }@else {
        <shared-loading-button [isLoading]="isLoading()" (click)="onRefresh()"></shared-loading-button>
        }
    </div>
    <div class="fx">&nbsp;</div>
</section>
