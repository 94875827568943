import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ZoomActions } from '@app/admin/actions';
import { ZoomState } from '@app/admin/state/zoom.state';
import { isFormValid } from '@app/shared/util';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { CreateWebinarFromTemplateComponent } from '../../components/create-webinar-from-template/create-webinar-from-template.component';
import { ErrorDisplayComponent } from '@app/shared/components/error-display/error-display.component';
import { MatButton } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatPrefix } from '@angular/material/form-field';

@Component({
    selector: 'admin-create-webinar-from-template-dialog',
    templateUrl: './create-webinar-from-template-dialog.component.html',
    styleUrls: ['./create-webinar-from-template-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        CreateWebinarFromTemplateComponent,
        ErrorDisplayComponent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        MatProgressSpinner,
        MatPrefix,
        AsyncPipe
    ]
})
export class CreateWebinarFromTemplateDialogComponent {
    private store = inject(Store);


    model$ = this.store.select(ZoomState.getCreateWebinarFromTemplateModel());
    templates$ = this.store.select(ZoomState.getWebinarTemplates());
    isValid$ = this.store.select(ZoomState.getCreateWebinarForm()).pipe(map(isFormValid));
    isSaving$ = this.store.select(ZoomState.isSaving());
    errors$ = this.store.select(ZoomState.getErrors());

    onRefreshTemplates() {
        this.store.dispatch(new ZoomActions.LoadWebinarTemplates());
    }

    onCreateWebinarFromTemplate() {
        this.store.dispatch(new ZoomActions.CreateWebinarFromTemplate());
    }
}
