<shared-link-filter
    storageKey="manageLinks.templateFilters"
    [isLoading]="isLoading()"
    [(model)]="filterModel"
    (filterChange)="onFilterChange($event)"
></shared-link-filter>
@if (error()) {
<shared-error-display [error]="error()"></shared-error-display>
}
<div class="container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" [trackBy]="trackBy">
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-menu #actions="matMenu">
                    <button mat-menu-item (click)="onDeleteSelected()">
                        <mat-icon color="warn">archive</mat-icon>
                        Delete ({{ selection.selected.length }})
                    </button>
                </mat-menu>
                @if (selection.hasValue()) {
                <button mat-icon-button [matMenuTriggerFor]="actions">
                    <mat-icon>more_vert</mat-icon>
                </button>
                }
            </th>
            <td mat-cell *matCellDef="let data">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(data) : null"
                    [checked]="selection.isSelected(data)"
                >
                </mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let data">
                {{ data.rowKey }}
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let data">
                <a class="pointer" [routerLink]="['/home/shareable-links/template', data.rowKey]">
                    {{ data.templateName }}
                </a>
                @if (data.isTemplatePublic) {
                <mat-icon [ngClass]="{ active: data.canRequestLink }">public</mat-icon>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef>Create Date</th>
            <td mat-cell *matCellDef="let model">
                <time> {{ model.createdDate | formatDate }} </time>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let data">
                <mat-menu #actions="matMenu">
                    <button mat-menu-item (click)="onEdit(data)">
                        <mat-icon>edit</mat-icon>
                        Edit
                    </button>
                    <button mat-menu-item (click)="onCopyTemplate(data)">
                        <mat-icon>content_copy</mat-icon>
                        Duplicate
                    </button>
                    <button mat-menu-item (click)="onDelete(data)">
                        <mat-icon color="warn">delete</mat-icon>
                        Delete
                    </button>
                </mat-menu>
                <button mat-icon-button matTooltip="Duplicate template" (click)="onCopyTemplate(data)">
                    <mat-icon>content_copy</mat-icon>
                </button>
                <button mat-icon-button [matMenuTriggerFor]="actions">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['select', 'name', 'createdDate', 'actions']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['select', 'name', 'createdDate', 'actions']"></tr>
    </table>
    <mat-paginator
        [length]="templates()?.length"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="[25, 50, 100]"
    >
    </mat-paginator>
    <!--
  <time>Test: {{ '2020-06-20T19:15:00Z' | amUtc | amUtcOffset: -10 | amDateFormat: 'LLLL (Z)' }}</time>
  <time>Test: {{ '2020-06-20T23:15:00.000Z' | amUtcOffset: -10 | amDateFormat: 'LLLL (Z)' }}</time> -->
</div>
