import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <h2 matDialogTitle><mat-icon>app_blocking</mat-icon> Device not supported</h2>
        <mat-dialog-content>
            Desktop mode is not supported on mobile. Please disable desktop mode and refresh
        </mat-dialog-content>
    `,
    styles: [
        `
            mat-icon {
                vertical-align: sub;
            }
        `,
    ],
    imports: [
        MatDialogTitle,
        MatIcon,
        CdkScrollable,
        MatDialogContent,
    ]
})
export class DenyRequestDesktopDialogComponent {}
