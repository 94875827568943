import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    inject,
    runInInjectionContext,
} from '@angular/core';
import { MatSelectChange, MatSelect } from '@angular/material/select';

import { NotificationActions } from '@app/data/actions';
import { Store } from '@ngxs/store';
import { Editor } from '@tiptap/core';

import { NgClass, NgStyle } from '@angular/common';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/core';

@Component({
    selector: 'admin-rich-text-editor-toolbar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./rich-text-editor-toolbar.component.html`,
    styleUrls: [`./rich-text-editor-toolbar.component.scss`],
    encapsulation: ViewEncapsulation.None,
    imports: [
        NgClass,
        MatIconButton,
        MatTooltip,
        MatIcon,
        MatFormField,
        MatLabel,
        MatSelect,
        MatOption,
        CdkOverlayOrigin,
        CdkConnectedOverlay,
        NgStyle,
        MatButton,
    ],
})
export class RichTextEditorToolbarComponent implements OnInit, OnDestroy {
    private changeDetectorRef = inject(ChangeDetectorRef);
    private store = inject(Store);

    readonly stillImageAccept = 'image/png, image/jpeg';
    readonly videoAccept = 'video/quicktime, video/mpg, video/mp4, video/mpeg, video/mov, video';

    @ViewChild('imageSize', { read: CdkConnectedOverlay }) cdkConnectedOverlayImage: CdkConnectedOverlay;
    @ViewChild('videoSize', { read: CdkConnectedOverlay }) cdkConnectedOverlayVideo: CdkConnectedOverlay;
    @ViewChild('audioDisplay', { read: CdkConnectedOverlay }) cdkConnectedOverlayAudioDisplay: CdkConnectedOverlay;
    @ViewChild('zoomLinkMenu', { read: CdkConnectedOverlay }) cdkConnectedOverlayZoomLinkMenu: CdkConnectedOverlay;
    @ViewChild('guestLinkMenu', { read: CdkConnectedOverlay }) cdkConnectedOverlayGuestLinkMenu: CdkConnectedOverlay;

    isFontColourOpen = false;
    isFontForegroundOpen = false;
    isImageSizeOpen = false;
    isVideoSizeOpen = false;
    isAudioDisplayOpen = false;
    isZoomLinkMenuOpen = false;
    isGuestLinkMenuOpen = false;

    colours = [
        '#000',
        '#e60000',
        '#ff9900',
        '#ffff00',
        '#008a00',
        '#0066cc',
        '#9933ff',
        '#ffffff',
        '#facccc',
        '#ffebcc',
        '#ffffcc',

        '#cce8cc',
        '#cce0f5',
        '#ebd6ff',
        '#bbbbbb',
        '#f06666',
        '#ffc266',
        '#ffff66',
        '#66b966',
        '#66a3e0',
        '#c285ff',
        '#888888',
        '#a10000',

        '#b26b00',
        '#b2b200',
        '#006100',
        '#0047b2',

        '#6b24b2',
        '#444444',
        '#5c0000',
        '#663d00',
        '#666600',
        '#003700',
        '#002966',
        '#3d1466',
    ];

    fonts = [
        { name: 'Amiko', value: "'Amiko', sans-serif" },
        { name: 'Ariel', value: 'ariel' },
        { name: 'Ariel (sans-serif)', value: 'ariel,sans-serif' },
        { name: 'Arima Madurai', value: "'Arima Madurai', cursive" },
        { name: 'Brush Script MT (cursive)', value: "'Brush Script MT',cursive" },
        { name: 'Comic Sans MS, Comic Sans', value: 'Comic Sans MS, Comic Sans' },
        { name: 'Courier New (monospace)', value: "'courier new',monospace" },
        { name: 'cursive', value: 'cursive' },
        { name: 'Inter', value: 'Inter' },
        { name: 'Farsan', value: "'Farsan', cursive" },
        { name: 'Garamond (serif)', value: 'garamond,sans-serif' },
        { name: 'Georgia (serif)', value: 'georgia,sans-serif' },
        { name: 'Helvetica (sans-serif)', value: 'helvetica,sans-serif' },
        { name: 'Lalezar', value: "'Lalezar', cursive" },
        { name: 'Mogra', value: "'Mogra', cursive" },
        { name: 'monospace', value: 'monospace' },
        { name: 'Rakkas', value: "'Rakkas', cursive" },
        { name: 'Rasa', value: "'Rasa', cursive" },
        { name: 'Shrikhand', value: "'Shrikhand', cursive" },
        { name: 'serif', value: 'serif' },
        { name: 'Suez One', value: "'Suez One', cursive" },
        { name: 'Tahoma (sans-serif)', value: 'tahoma,sans-serif' },
        { name: 'Trebuchet MS (sans-serif)', value: "'trebuchet ms',sans-serif" },
        { name: 'Times New Roman (serif)', value: "'Times New Roman',serif" },
        { name: 'Verdana (sans-serif)', value: 'verdana,sans-serif' },
        { name: 'Yatra One', value: "'Yatra One', cursive" },
    ];

    fontSizes: { size: string; label: string }[] = Array.from({ length: 29 }, (_, i) => {
        return { size: `${i + 7}px`, label: `${i + 7}px` };
    });

    get currentZoomMeetingId() {
        if (this.editor) {
            const attrs = this.editor.getAttributes('zoomLink');
            return attrs['zoomId'] || 0;
        }

        return '';
    }

    get currentGuestMeetingId() {
        if (this.editor) {
            const attrs = this.editor.getAttributes('guestLink');
            return attrs['zoomId'] || 0;
        }

        return '';
    }

    get currentFontSize() {
        if (this.editor) {
            const attrs = this.editor.getAttributes('textStyle');
            return (attrs['fontSize'] || '').replace(/['"]+/g, '');
        }

        return '';
    }

    get fontFamily() {
        if (this.editor) {
            const attrs = this.editor.getAttributes('textStyle');
            const fontFamily = (attrs['fontFamily'] || '').replace(/['" ]+/g, '');
            const font = this.fonts.find(f => f.value.replace(/['" ]+/g, '') === fontFamily);
            return font?.value || '';
        }

        return '';
    }

    @Input() zoomMeetings: { id: number; topic: string }[] | null = [];
    @Input() allowZoomLink: boolean | null = false;
    @Input() allowGuestLink: boolean | null = false;
    @Input() allowInlineMediaAsset: boolean | null = false;
    @Input() readonly = false;
    @Input() editor: Editor;
    @Input() showBorder: boolean | null = true;

    ngOnInit(): void {
        this.editor.on('transaction', ({ editor, transaction }): void => {
            if ((transaction as any).curSelection?.node?.type.name === 'image' && this.cdkConnectedOverlayImage) {
                const { state } = editor.view;
                const { selection } = state;

                const { ranges } = selection;
                const from = Math.min(...ranges.map(range => range.$from.pos));
                const node = editor.view.nodeDOM(from) as HTMLElement;
                const el = new ElementRef(node.firstChild);

                this.cdkConnectedOverlayImage.origin = runInInjectionContext(
                    Injector.create({ providers: [{ provide: ElementRef, useValue: el }] }),
                    () => new CdkOverlayOrigin(el),
                );
                this.isImageSizeOpen = true;
            } else {
                this.isImageSizeOpen = false;
            }

            if ((transaction as any).curSelection?.node?.type.name === 'video' && this.cdkConnectedOverlayVideo) {
                const { state } = editor.view;
                const { selection } = state;

                const { ranges } = selection;
                const from = Math.min(...ranges.map(range => range.$from.pos));
                const node = editor.view.nodeDOM(from) as HTMLElement;
                const el = new ElementRef(node.firstChild);

                this.cdkConnectedOverlayVideo.origin = runInInjectionContext(
                    Injector.create({ providers: [{ provide: ElementRef, useValue: el }] }),
                    () => new CdkOverlayOrigin(el),
                );
                this.isVideoSizeOpen = true;
            } else {
                this.isVideoSizeOpen = false;
            }

            if (
                (transaction as any).curSelection?.node?.type.name === 'inlineAudio' &&
                this.cdkConnectedOverlayAudioDisplay
            ) {
                const { state } = editor.view;
                const { selection } = state;

                const { ranges } = selection;
                const from = Math.min(...ranges.map(range => range.$from.pos));
                const node = editor.view.nodeDOM(from) as HTMLElement;
                const el = new ElementRef(node.firstChild);

                this.cdkConnectedOverlayAudioDisplay.origin = runInInjectionContext(
                    Injector.create({ providers: [{ provide: ElementRef, useValue: el }] }),
                    () => new CdkOverlayOrigin(el),
                );
                this.isAudioDisplayOpen = true;
            } else {
                this.isAudioDisplayOpen = false;
            }

            if ((transaction as any).curSelection?.node?.type.name === 'zoomLink' && this.cdkConnectedOverlayZoomLinkMenu) {
                const { state } = editor.view;
                const { selection } = state;

                const { ranges } = selection;
                const from = Math.min(...ranges.map(range => range.$from.pos));
                const node = editor.view.nodeDOM(from) as HTMLElement;
                const el = new ElementRef(node.firstChild);

                this.cdkConnectedOverlayZoomLinkMenu.origin = runInInjectionContext(
                    Injector.create({ providers: [{ provide: ElementRef, useValue: el }] }),
                    () => new CdkOverlayOrigin(el),
                );
                this.isZoomLinkMenuOpen = true;
            } else {
                this.isZoomLinkMenuOpen = false;
            }

            if (
                (transaction as any).curSelection?.node?.type.name === 'guestLink' &&
                this.cdkConnectedOverlayGuestLinkMenu
            ) {
                const { state } = editor.view;
                const { selection } = state;

                const { ranges } = selection;
                const from = Math.min(...ranges.map(range => range.$from.pos));
                const node = editor.view.nodeDOM(from) as HTMLElement;
                const el = new ElementRef(node.firstChild);

                this.cdkConnectedOverlayGuestLinkMenu.origin = runInInjectionContext(
                    Injector.create({ providers: [{ provide: ElementRef, useValue: el }] }),
                    () => new CdkOverlayOrigin(el),
                );
                this.isGuestLinkMenuOpen = true;
            } else {
                this.isGuestLinkMenuOpen = false;
            }
        });

        this.editor.on('selectionUpdate', () => {
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnDestroy() {
        this.editor.off('selectionUpdate');
        this.editor.off('transaction');
    }

    setInlineMediaAsset() {
        if (this.readonly) return;

        this.editor.chain().setInlineMediaAsset({ mediaAssetId: '' }).focus().run();
    }

    setTextAlign(align: string) {
        if (this.readonly) return;

        this.editor.chain().setTextAlign(align).focus().run();
    }

    onFontSizeChange($event: MatSelectChange) {
        if (this.readonly) return;

        const fontSize = $event.value;
        const chain = this.editor.chain();

        chain.setFontSize(fontSize).run();
    }

    onFontFamilyChange($event: MatSelectChange) {
        if (this.readonly) return;

        const fontFamily = $event.value;
        const chain = this.editor.chain();

        chain.setFontFamily(fontFamily).run();
    }

    setFontColour(colour: string) {
        if (this.readonly) return;

        const chain = this.editor.chain();
        if (
            this.editor.isActive('textStyle', {
                fontColour: colour,
            })
        ) {
            chain.unsetColor().run();
        } else {
            chain.setColor(colour).run();
        }
    }

    setForegroundColour(colour: string) {
        if (this.readonly) return;

        const chain = this.editor.chain();

        if (
            this.editor.isActive('textStyle', {
                fontForeground: colour,
            })
        ) {
            chain.unsetFontForeground().run();
        } else {
            chain.setFontForeground(colour).run();
        }
    }

    // async onImageSelect(event: Event): Promise<void> {
    //     if (this.readonly) return;

    //     const fileList: FileList = (event.target as HTMLInputElement).files as FileList;
    //     const file = fileList[0];

    //     this.editor.commands.uploadImage({ src: file });
    // }

    async onFileSelect(event: Event): Promise<void> {
        if (this.readonly) return;

        const fileList: FileList = (event.target as HTMLInputElement).files as FileList;
        const file = fileList[0];

        this.editor.commands.uploadFile({
            src: file,
            title: file.name,
            progressFn: this.onReportProgress.bind(this),
        });
    }

    onReportProgress<T>(e: HttpEvent<T>, isComplete: boolean) {
        if (e.type === HttpEventType.UploadProgress) {
            this.store.dispatch(
                new NotificationActions.Progress(
                    'Uploading file...',
                    e ? (e.loaded / (e?.total || 0)) * 100 : 100,
                    isComplete,
                ),
            );
        }
    }

    onUpdateLink() {
        if (this.readonly) return;

        const attrs = this.editor.getAttributes('link');
        const href = window.prompt('Enter link URL', attrs?.['href'] || '');

        if (href) {
            //update the link
            this.editor.chain().setLink({ href }).run();
        } else if (href === '') {
            //remove the link
            this.editor.chain().unsetLink().run();
        }
    }
}
