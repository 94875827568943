<shared-link-filter
    storageKey="manageLinks.searchArchivedLinks"
    [config]="filterColumns"
    [isLoading]="isLoading()"
    [model]="filterModel()"
    (filterChange)="onFilterChange($event)"
></shared-link-filter>
@if (error()) {
<shared-error-display [error]="error()"></shared-error-display>
}
<div class="container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-menu #actions="matMenu">
                    <button mat-menu-item (click)="onDeleteSelected()">
                        <mat-icon color="warn">delete</mat-icon>
                        Permanently Delete ({{ selection.selected.length }})
                    </button>
                </mat-menu>
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="onSelectAllChange($event)"
                    [checked]="areAllSelected()"
                    [indeterminate]="areAnySelected()"
                >
                </mat-checkbox>
                @if (selection.hasValue()) {
                <button mat-icon-button [matMenuTriggerFor]="actions">
                    <mat-icon>more_vert</mat-icon>
                </button>
                }
            </th>
            <td mat-cell *matCellDef="let data">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(data) : null"
                    [checked]="selection.isSelected(data)"
                >
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let data">
                {{ data.rowKey }}
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let data">
                <div>
                    <a
                        class="pointer active-badge"
                        (click)="onView(data)"
                        matBadgePosition="before"
                        matBadgeOverlap="false"
                        [routerLink]="['/home/shareable-links/archived', data.rowKey]"
                        [queryParams]="{ t: 'content' }"
                        [matBadge]="data.activeSubmissionCount || '-'"
                        [ngClass]="{ active: data.isActivated, empty: data.activeSubmissionCount === 0 }"
                    >
                        {{ data.requestedBy ? '(' + data.requestedBy + ')' : '' }}
                        {{ data.url }}
                    </a>
                    <div class="meta-badges">
                        @if (data.approvalCount > 0) {
                        <span
                            matBadgePosition="after"
                            matBadgeOverlap="false"
                            [matBadge]="data.approvalCount"
                            (click)="onView(data)"
                            class="badge pending-badge"
                            >&nbsp;
                        </span>
                        } @if (data.lateRegistrationCount > 0) {
                        <span
                            matBadgePosition="after"
                            matBadgeOverlap="false"
                            [matBadge]="data.lateRegistrationCount"
                            class="badge inactive-badge"
                        >
                            &nbsp;
                        </span>
                        }
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>Details</th>
            <td mat-cell *matCellDef="let data">
                <time> Create Date {{ data.createdDate | formatDate }} MST </time>
                @if (data.isActivated) { @switch (data.displayAs) { @case (DisplayAs.RegistrationReset) {
                <time class="active">
                    {{ data.resetDisplay }} ({{ data.resetStartDate | formatDate }}) @if (!data.isExpired) {
                    <span>{{ data.expires | expires }}</span>
                    }
                </time>
                } @case (DisplayAs.Timer) {
                <time class="active">
                    {{ data.linkInactivityMode === LinkInactivityMode.Link ? 'Link Timer' : 'User Timer' }} ({{ data.timer
                    }}{{ data.timerUnit | timerUnit }}) @if (!data.isExpired) {
                    <span>{{ data.expires | expires }}</span>
                    }
                </time>
                } @case (DisplayAs.RegistrationCutOff) {
                <time class="pending"> Cut-off {{ data.registrationEndDate | formatDate }} </time>
                } @default {
                <time class="active"> Start Date {{ data.startDate || data.createdDate | formatDate }} MST </time>
                } } }
            </td>
        </ng-container>

        <ng-container matColumnDef="expires">
            <th mat-header-cell *matHeaderCellDef>Expires</th>
            <td mat-cell *matCellDef="let data">
                <time>
                    {{ data.linkExpirationDate | formatDate }}
                </time>
            </td>
        </ng-container>

        <ng-container matColumnDef="archived">
            <th mat-header-cell *matHeaderCellDef>Archived</th>
            <td mat-cell *matCellDef="let data">
                <time>
                    {{ data.archivedDate | formatDate }}
                </time>
            </td>
        </ng-container>

        <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef>Timestamp</th>
            <td mat-cell *matCellDef="let data">
                <time>
                    {{ data.timestamp | formatDate }}
                </time>
            </td>
        </ng-container>

        <ng-container matColumnDef="tags">
            <th mat-header-cell *matHeaderCellDef>Tags</th>
            <td mat-cell *matCellDef="let model">
                @if (model.tags | any) {
                <mat-chip-listbox>
                    @for (tag of model.tags; track tag) {
                    <mat-chip-option [removable]="false" [selectable]="false">
                        {{ tag }}
                    </mat-chip-option>
                    }
                </mat-chip-listbox>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let data">
                <mat-menu #actions="matMenu">
                    <button mat-menu-item (click)="onView(data)">
                        <mat-icon>edit</mat-icon>
                        View
                    </button>
                    <button mat-menu-item (click)="onCopyLink(data)">
                        <mat-icon>content_copy</mat-icon>
                        Duplicate
                    </button>
                    <button mat-menu-item (click)="onDelete(data)">
                        <mat-icon color="warn">delete</mat-icon>
                        Permanently Delete
                    </button>
                </mat-menu>
                <button mat-icon-button matTooltip="Duplicate link" (click)="onCopyLink(data)">
                    <mat-icon>content_copy</mat-icon>
                </button>
                <button mat-icon-button [matMenuTriggerFor]="actions">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{
                inactive: row.status === 0 || row.isExpired,
                expired: row.isExpired,
                'not-activated': !row.isLinkActivated,
                archived: row.isDeleted
            }"
        ></tr>
    </table>
    <mat-paginator
        #paginator
        (page)="onPage($event)"
        [length]="total"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="[25, 50, 100]"
    >
    </mat-paginator>
</div>
