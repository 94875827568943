import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { DeleteMediaAssetResult, MediaAsset, Node } from '@app/data/models';
import { errorHandler, getData, verifyServerResult } from '@app/data/operators';
import { MediaAssetType, MediaType } from '@app/shared/enums';
import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

@Injectable({
    providedIn: 'root',
})
export class ManageMediaService {
    private env = inject<Environment>(APP_ENVIRONMENT);
    private http = inject(HttpClient);


    getMediaAssets(rowKeys: string[] = []): Observable<MediaAsset[]> {
        return this.http
            .post<ServerResult<MediaAsset[]>>(`${this.env.serverUrl}/manage/media-assets/all`, rowKeys)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deleteMediaAsset(rowKey: string): Observable<DeleteMediaAssetResult> {
        return this.http
            .post<ServerResult<DeleteMediaAssetResult>>(`${this.env.serverUrl}/manage/media-assets/delete`, { rowKey })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    renameMediaAsset(rowKey: string, name: string): Observable<MediaAsset> {
        return this.http
            .post<ServerResult<MediaAsset>>(`${this.env.serverUrl}/manage/media-assets/rename`, {
                rowKey,
                name,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    downloadMediaAsset(rowKey: string, assetName: string): Observable<HttpResponse<ArrayBuffer>> {
        return this.http
            .post(
                `${this.env.serverUrl}/manage/media/assets/download`,
                { rowKey, assetName },
                {
                    observe: 'response',
                    responseType: 'arraybuffer',
                },
            )
            .pipe(errorHandler());
    }

    createMediaAsset(
        assetName: string,
        mediaType: MediaType,
        mediaAssetType: MediaAssetType,
        replaceExisting: boolean,
    ): Observable<MediaAsset> {
        return this.http
            .post<ServerResult<MediaAsset>>(`${this.env.serverUrl}/manage/media-assets/save`, {
                assetName,
                replaceExisting,
                type: mediaType,
                mediaAssetType,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    createMediaAssetForNode(
        nodeId: string,
        assetName: string,
        mediaType: MediaType,
        mediaAssetType: MediaAssetType,
        replaceExisting: boolean,
    ): Observable<{ item1: Node; item2: MediaAsset }> {
        return this.http
            .post<ServerResult<{ item1: Node; item2: MediaAsset }>>(`${this.env.serverUrl}/manage/media-assets/save`, {
                nodeId,
                assetName,
                replaceExisting,
                type: mediaType,
                mediaAssetType,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    cancelMediaAsset(rowKey: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/media-assets/cancel`, {
                rowKey,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    normalizeWebmAudio(form: FormData): Observable<HttpEvent<Blob>> {
        return this.http.post(`${this.env.serverUrl}/audio/normalize`, form, {
            reportProgress: true,
            observe: 'events',
            // observe: 'body',
            responseType: 'blob',
        });
    }
}
