<section tiptapDraggable [ngClass]="{ editable: isEditable, selected: isEditable && isSelected }">
    <span> Zoom Meeting Link: {{ zoomMeeting?.topic || 'No Meeting Selected' }} </span>
    <shared-tiptap-zoom-link
        [showCopyLinkButton]="showCopyLinkButton"
        [showJoinButton]="showJoinButton"
        [showJoinUrl]="showJoinUrl"
        [showCopyPasswordButton]="showCopyPasswordButton"
        [joinUrl]="joinUrl"
        [password]="password"
        [alignment]="alignment"
    ></shared-tiptap-zoom-link>
    @if (!showJoinButton && !showJoinUrl && !showCopyLinkButton) {
    <span class="hint"> You've chosen to hide all buttons. This is not recommended. </span>
    }
</section>
