import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

@Injectable()
export class ApiVersionInterceptor implements HttpInterceptor {
    private env = inject<Environment>(APP_ENVIRONMENT);


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.addToken(req));
    }

    addToken(request: HttpRequest<any>): HttpRequest<any> {
        if (request.url.indexOf(this.env.serverUrl) === -1 && request.url.indexOf(this.env.proxyServerUrl) === -1) {
            // request already has the header set, so fast return
            return request;
        }

        return request.clone({
            setParams: {
                v: 'v1',
            },
        });
    }
}
