import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

@Injectable()
export class CredentialsInterceptor implements HttpInterceptor {
    private env = inject<Environment>(APP_ENVIRONMENT);

    currentHost = location.host;
    proxyHost = new URL(this.env.proxyServerUrl).host;
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.startsWith(`${this.env.proxyServerUrl}`) && this.currentHost !== this.proxyHost) {
            return next.handle(
                req.clone({
                    withCredentials: true,
                }),
            );
        }
        return next.handle(req);
    }
}
