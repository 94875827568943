import { Component, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

import { NodeType } from '@app/shared/enums';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
    templateUrl: './rename-media-asset-dialog.component.html',
    styles: [
        `
            :host {
                width: 600px;
                display: block;
            }

            .full-width {
                width: 100%;
            }

            mat-dialog-content {
                height: 100px;
            }
        `,
    ],
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatDialogActions,
        MatButton,
        MatDialogClose,
    ]
})
export class RenameMediaAssetDialogComponent {
    data = inject(MAT_DIALOG_DATA);
    private fb = inject(FormBuilder);

    NodeType: typeof NodeType = NodeType;

    form: FormGroup = this.fb.group({
        name: new FormControl('', [Validators.required]),
    });

    constructor() {
        const data = this.data;

        this.form.patchValue({ name: data.title });
    }
}
