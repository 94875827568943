import { HttpErrorResponse } from '@angular/common/http';

import { ErrorCodes } from '../enums';
import { getErrorCodeMessage, getHttpErrorMessage, getRestErrorMessage, isHttpError, isRestError, isString } from '../util';
import { isServerResult, ServerResult } from './server-result.model';
import { RestError } from '@azure/storage-blob';

export class ErrorModel<T extends HttpErrorResponse | Error | ServerResult<any> | RestError | string> {
    get isCritical(): boolean {
        if (isHttpError(this.source)) {
            return this.source.status === 500 || this.source.status === 0;
        }

        return false;
    }

    get isConnectionError() {
        return this.errorCode === ErrorCodes.NoConnection;
    }

    get httpStatus(): number {
        if (isHttpError(this.source)) {
            return this.source.status;
        }

        return 0;
    }

    get errorCode(): ErrorCodes | number {
        if (isHttpError(this.source)) {
            if (this.source.error && this.source.error.meta && this.source.error.meta.errorCode) {
                return this.source.error.meta.errorCode;
            }
        } else if (isServerResult(this.source) && this.source.hasErrorCode) {
            return this.source.errorCode;
        }

        return 0;
    }

    get validationResults(): string[] {
        return isServerResult(this.source) ? this.source.validationResults : [];
    }

    get message(): string {
        if (isHttpError(this.source)) {
            if (this.source.error && this.source.error.meta && this.source.error.meta.errorCode) {
                return getErrorCodeMessage(this.source.error.meta.errorCode, this.source.error.meta);
            }
            return getHttpErrorMessage(this.source);
        } else if (isServerResult(this.source) && this.source.hasErrorCode) {
            return this.source.errorCodeMessage;
        } else if (isRestError(this.source)) {
            return getRestErrorMessage(this.source);
        } else if (isString(this.source)) {
            return this.source;
        }

        return this.source?.message || '';
    }

    get meta(): any {
        if (isHttpError(this.source)) {
            return this.source.error?.meta || null;
        } else if (isServerResult(this.source)) {
            return this.source.meta || null;
        }

        return null;
    }

    constructor(public source?: T) {
        console.error(this.source);
    }

    get isErrorModel() {
        return true;
    }
}

export function isErrorModel(model: any): model is ErrorModel<any> {
    return model instanceof ErrorModel || model?.isErrorModel === true;
}
