<h1 mat-dialog-title>Confirm merge link sessions</h1>
<mat-dialog-content>
  <p>
    Are you certain you want to merge {{ sessions.length }} link sessions? This action is permanent and cannot be undone.
  </p>
  <p>Please select the primary session to merge into.</p>

  <mat-selection-list [multiple]="false" (selectionChange)="onSelectionChange($event)">
    @for (session of sessions; track session) {
      <mat-list-option [value]="session" [disableRipple]="true">
        @switch (linkType) {
          @case (LinkType.Form) {
            <ng-container *ngTemplateOutlet="forms; context: { session: session }"></ng-container>
          }
          @default {
            <ng-container *ngTemplateOutlet="default; context: { session: session }"></ng-container>
          }
        }
      </mat-list-option>
    }
  </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-raised-button [disabled]="selected === null" color="primary" [mat-dialog-close]="selected">
    <mat-icon>merge_type</mat-icon>
    Merge
  </button>
</mat-dialog-actions>

<ng-template #forms let-session="session">
  <div class="row" [ngClass]="getRowClasses(session)"  >
    @if (session.hasLocation) {
      <img class="avatar" [src]="session.location?.flag" />
    }
    @if (!session.hasLocation) {
      <mat-icon class="avatar no-location">gps_off</mat-icon>
    }
    <div class="details">
      <span>
        {{ session.name }}
      </span>
      @if (!session.isInfluencer) {
        <div class="meta-badges">
          @if (session.formSubmissionCount > 0) {
            <span
              matBadgePosition="after"
              matBadgeOverlap="false"
              [matBadgeHidden]="session.formSubmissionCount === 0"
              [matBadge]="session.formSubmissionCount"
              class="access-badge"
              >&nbsp;
            </span>
          }
        </div>
      }
      @if (session.isInfluencer) {
        <mat-icon class="no-location influencer">verified_user</mat-icon>
      }
      @if (session.sessionExpires && session.isActiveSession) {
        <small class="session-expires">
          Expires {{ session.sessionExpires | formatDate }}
        </small>
      }
    </div>
    <div class="location">
      @if (session.hasLocation) {
        {{ session.location?.city }}, {{ session.location?.region }}, {{ session.location?.country }}
      }
      @if (!session.hasLocation) {
        Unknown
      }
    </div>
  </div>
</ng-template>

<ng-template #default let-session="session">
  <div class="row" [ngClass]="getRowClasses(session)">
    @if (session.hasLocation) {
      <img class="avatar" [src]="session.location?.flag" />
    }
    @if (!session.hasLocation) {
      <mat-icon class="avatar no-location">gps_off</mat-icon>
    }
    <div class="details">
      <span>
        {{ session.name }}
      </span>
      @if (!session.isInfluencer) {
        <div class="meta-badges">
          @if (session.lifeTimeSessionAccessCount > 0) {
            <span
              matBadgePosition="after"
              matBadgeOverlap="false"
              [matBadgeHidden]="session.lifeTimeSessionAccessCount === 0"
              [matBadge]="session.lifeTimeSessionAccessCount"
              class="access-badge"
              >&nbsp;
            </span>
          }
        </div>
      }
      @if (session.isInfluencer) {
        <mat-icon class="no-location influencer">verified_user</mat-icon>
      }
      @if (session.sessionExpires && session.isActiveSession) {
        <small class="session-expires">
          Expires {{ session.sessionExpires | formatDate }}
        </small>
      }
    </div>
    <div class="location">
      @if (session.hasLocation) {
        {{ session.location?.city }}, {{ session.location?.region }}, {{ session.location?.country }}
      }
      @if (!session.hasLocation) {
        Unknown
      }
    </div>
  </div>
</ng-template>
