import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

import { DeleteNoteResult, NoteModel } from '@app/data/models';
import { errorHandler, getData, verifyServerResult } from '@app/data/operators';

@Injectable({ providedIn: 'root' })
export class NoteService {
    private env = inject<Environment>(APP_ENVIRONMENT);
    private http = inject(HttpClient);


    getNotesForUser(): Observable<NoteModel[]> {
        return this.http
            .post<ServerResult<NoteModel[]>>(`${this.env.serverUrl}/notes`, null)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    saveNote(note: NoteModel): Observable<NoteModel> {
        return this.http
            .post<ServerResult<NoteModel>>(`${this.env.serverUrl}/notes/save`, note)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deleteNotes(notes: NoteModel[]): Observable<DeleteNoteResult> {
        return this.http
            .request<ServerResult<DeleteNoteResult>>('DELETE', `${this.env.serverUrl}/notes/delete`, {
                body: notes.map(m => m.rowKey),
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }
}
