import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'shared-connection-status',
    template: `
        @if (connectionStatus !== true) {
          <div class="connection-status">
            <mat-icon>wifi_on</mat-icon>
          </div>
        }
        `,
    styles: [
        `
            .connection-status {
                position: fixed;
                bottom: 0;
                right: 0;
                z-index: 1000;
                color: #f44336;
                text-align: center;
                pointer-events: none;
                // padding: 10px;
                transform: translate(-50%, -50%);

                // animate rotate
                mat-icon {
                    height: 35px;
                    width: 35px;
                    font-size: 35px;
                    animation: rotateAnimation 2s infinite linear;
                }
            }

            @keyframes rotateAnimation {
                0% {
                    opacity: 1;
                }
                50% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIcon]
})
export class ConnectionStatusComponent {
    @Input() connectionStatus: boolean | null = true;
}
