import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { Store } from '@ngxs/store';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatDialogContent } from '@angular/material/dialog';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <mat-dialog-content>
            <p>Please wait while we check your browser...</p>
            <mat-progress-bar mode="query"></mat-progress-bar>
        </mat-dialog-content>
    `,
    styles: [
        `
            mat-icon {
                vertical-align: sub;
            }
        `,
    ],
    imports: [
        CdkScrollable,
        MatDialogContent,
        MatProgressBar,
    ]
})
export class BrowserCheckDialogComponent {    private store = inject(Store);

}
