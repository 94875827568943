<section class="filters" [formGroup]="form" ngxsForm="home.mediaAssetFilters">
    <mat-form-field appearance="outline">
        <mat-label>Filter</mat-label>
        <input formControlName="filter" matInput (keyup)="onUpdateFilter()" />
        <button mat-icon-button matSuffix (click)="clearFilter(); onUpdateFilter()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-select multiple="true" formControlName="displayedColumns" (selectionChange)="updateDisplayedColumns($event)">
            @for (column of columns; track column) {
            <mat-option [value]="column.value">
                {{ column.key }}
            </mat-option>
            }
        </mat-select>
        <mat-label> Display Columns </mat-label>
    </mat-form-field>
</section>
<table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let data">
            <mat-checkbox
                (click)="$event.stopPropagation(); onSelectionChange(data)"
                [checked]="data.rowKey === selectedAssetId"
            ></mat-checkbox>
        </td>
    </ng-container>
    <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let data">
            {{ data.rowKey }}
        </td>
    </ng-container>
    <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let data">
            <span mat-ripple (click)="onPreview(data)" class="title">{{ data.title }}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef>Duration</th>
        <td mat-cell *matCellDef="let data">
            {{ data.duration | mediaDuration : true }}
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let asset">
            <mat-menu #actionsMenu="matMenu">
                @if (allowRename) {
                <button mat-menu-item (click)="onPreview(asset)">
                    <mat-icon>movie</mat-icon>
                    Preview
                </button>
                } @if (allowRename) {
                <button mat-menu-item (click)="onRename(asset)">
                    <mat-icon>drive_file_rename_outline</mat-icon>
                    Rename
                </button>
                } @if (allowDownload) {
                <button mat-menu-item (click)="onDownload(asset)">
                    <mat-icon>download</mat-icon>
                    Download
                </button>
                } @if (allowDelete) {
                <button mat-menu-item (click)="onDelete(asset)">
                    <mat-icon color="warn">delete</mat-icon>
                    Delete
                </button>
                }
            </mat-menu>

            @if (allowActions) {
            <button mat-icon-button [matMenuTriggerFor]="actionsMenu" (click)="$event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
            </button>
            }
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{
            inactive: row.status === 0 || row.isExpired
        }"
    ></tr>
</table>
<mat-paginator
    (page)="onPage($event)"
    [length]="mediaAssets?.length"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[25, 50, 100]"
>
</mat-paginator>
