import { Injectable, NgZone, inject } from '@angular/core';

import { BehaviorSubject, forkJoin, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import { APP_WINDOW } from '@app/shared/tokens';

import { ScreenMedia, ScreenOrientation, ScreenProfile } from '@app/shared/enums';

const DEBOUNCE_IN_MS = 200;

@Injectable({
    providedIn: 'root',
})
export class MatchMediaService {
    private zone = inject(NgZone);

    private $window: Window;

    private media = new BehaviorSubject(ScreenMedia.Unknown);
    media$ = this.media.asObservable().pipe(distinctUntilChanged((x, y) => x === y));

    private screen = new BehaviorSubject(ScreenProfile.Unknown);
    screen$ = this.screen.asObservable().pipe(distinctUntilChanged((x, y) => x === y));

    private orientation = new BehaviorSubject(ScreenOrientation.Unknown);
    orientation$ = this.orientation.asObservable().pipe(distinctUntilChanged((x, y) => x === y));

    change$ = forkJoin([this.media$, this.screen$, this.orientation$]).pipe(
        distinctUntilChanged((x, y) => {
            return !(x[0] === x[0] && x[1] === x[1] && x[2] === y[2]);
        }),
    );

    // https://github.com/angular/flex-layout/wiki/Responsive-API
    rules = {
        print: 'print',
        screen: 'screen',
        xs: 'screen and (max-width: 599px)',
        sm: 'screen and (min-width: 600px) and (max-width: 959px)',
        md: 'screen and (min-width: 960px) and (max-width: 1279px)',
        lg: 'screen and (min-width: 1280px) and (max-width: 1919px)',
        xl: 'screen and (min-width: 1920px) and (max-width: 5000px)',
        portrait: '(orientation: portrait)',
        landscape: '(orientation: landscape)',
    };

    get currentMedia(): ScreenMedia {
        switch (true) {
            case this.$window.matchMedia(this.rules.print).matches:
                return ScreenMedia.Print;
            case this.$window.matchMedia(this.rules.screen).matches:
                return ScreenMedia.Screen;
            default:
                return ScreenMedia.Unknown;
        }
    }

    get currentScreenProfile(): ScreenProfile {
        switch (true) {
            case this.$window.matchMedia(this.rules.xs).matches:
                return ScreenProfile.ExtraSmall;
            case this.$window.matchMedia(this.rules.sm).matches:
                return ScreenProfile.Small;
            case this.$window.matchMedia(this.rules.md).matches:
                return ScreenProfile.Medium;
            case this.$window.matchMedia(this.rules.lg).matches:
                return ScreenProfile.Large;
            case this.$window.matchMedia(this.rules.xl).matches:
                return ScreenProfile.ExtraLarge;
            default:
                return ScreenProfile.Unknown;
        }
    }

    get currentOrientation(): ScreenOrientation {
        switch (true) {
            case this.$window.matchMedia(this.rules.landscape).matches:
                return ScreenOrientation.Landscape;
            case this.$window.matchMedia(this.rules.portrait).matches:
                return ScreenOrientation.Portrait;
            default:
                return ScreenOrientation.Unknown;
        }
    }

    constructor() {
        const win = inject(APP_WINDOW);

        this.$window = win as Window;

        this.zone.runOutsideAngular(() => {
            fromEvent(this.$window, 'resize')
                .pipe(
                    debounceTime(DEBOUNCE_IN_MS),
                    tap(() => this.detectChange()),
                )
                .subscribe();
        });
        this.detectChange();
    }

    detectChange(): void {
        this.media.next(this.currentMedia);
        this.screen.next(this.currentScreenProfile);
        this.orientation.next(this.currentOrientation);
    }

    isPhone() {
        return this.$window.matchMedia('screen and (max-width: 599px)').matches;
    }

    isTablet() {
        return this.$window.matchMedia('screen and (max-width: 959px)').matches;
    }

    isDesktop() {
        return this.$window.matchMedia('screen and (max-width: 1919px)').matches;
    }

    isLessThanExtraLarge() {
        return this.$window.matchMedia('screen and (max-width: 1919px)').matches;
    }

    isLessThanLarge() {
        return this.$window.matchMedia('screen and (max-width: 1279px)').matches;
    }

    isLessThanMedium() {
        return this.$window.matchMedia('screen and (max-width: 959px)').matches;
    }

    isLessThanSmall() {
        return this.$window.matchMedia('screen and (max-width: 599px)').matches;
    }

    isGreaterThanSmall() {
        return this.$window.matchMedia('screen and (min-width: 600px)').matches;
    }

    isPortrait() {
        return this.$window.matchMedia(this.rules.portrait).matches;
    }

    isLandscape() {
        return this.$window.matchMedia(this.rules.landscape).matches;
    }
}
