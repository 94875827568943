import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { ClipboardModel } from '@app/data/models';
import { DestroyService } from '@app/shared/services/destroy.service';
import { NgStyle } from '@angular/common';

import { MatRipple } from '@angular/material/core';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'shared-tiptap-zoom-link',
    templateUrl: './tiptap-zoom-link.component.html',
    styleUrls: ['./tiptap-zoom-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService],
    imports: [
        NgStyle,
        MatRipple,
        MatButton,
        MatProgressSpinner,
        MatIcon
    ]
})
export class TiptapZoomLinkComponent {
    isJoiningMeeting = false;

    @Input() showJoinButton: boolean | null = false;
    @Input() showJoinUrl: boolean | null = false;
    @Input() showCopyLinkButton: boolean | null = false;
    @Input() showCopyPasswordButton: boolean | null = false;
    @Input() joinUrl: string | null = null;
    @Input() password: string | null = null;
    @Input() alignment: string | null = 'flex-start';

    @Output() readonly copyToClipboard = new EventEmitter<ClipboardModel>();
    @Output() readonly joinMeeting = new EventEmitter<string>();

    onJoinMeeting() {
        this.isJoiningMeeting = true;
        this.joinMeeting.emit(this.joinUrl as string);
    }

    onCopyPasswordToClipboard() {
        this.copyToClipboard.emit({
            text: this.password || '',
            message: 'Password copied to clipboard',
        });
    }

    onCopyLinkToClipboard() {
        this.copyToClipboard.emit({
            text: this.joinUrl || '',
            message: 'Link copied to clipboard',
        });
    }

    getStyling() {
        const styles: Record<string, string> = {};

        switch (this.alignment) {
            case 'left':
                styles['justify-content'] = 'flex-start';
                break;
            case 'center':
                styles['justify-content'] = 'center';
                break;
            case 'right':
                styles['justify-content'] = 'flex-end';
                break;
            default:
                styles['justify-content'] = 'flex-start';
                break;
        }

        return styles;
    }
}