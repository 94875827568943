<section>
    <div class="header">
        <mat-form-field appearance="outline">
            <mat-label>Filter</mat-label>
            <input #filterInput matInput autocomplete="off" (keyup)="filter = filterInput.value; onUpdateFilter()" />
        </mat-form-field>
        @if (node) {
        <button mat-icon-button (click)="showAll = !showAll; onUpdateFilter()">
            <mat-icon>{{ showAll ? 'visibility' : 'visibility_off' }}</mat-icon>
        </button>
        }
        <button color="accent" mat-mini-fab (click)="onAdd()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    @if (!isLoading) {
    <mat-selection-list (selectionChange)="onSelectionChange($event)" [multiple]="false">
        @for (note of filteredNotes; track note) {
        <mat-list-option [value]="note">
            <div class="note">
                <span class="title">{{ note.title }}</span>
                <button color="warn" mat-icon-button (click)="$event.stopPropagation(); onDelete(note)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </mat-list-option>
        <mat-divider></mat-divider>
        }
    </mat-selection-list>
    } @else {
    <mat-spinner></mat-spinner>
    }
</section>
