import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoadingComponent } from '../loading/loading.component';

@Component({
    selector: 'shared-global-loading',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ` <shared-loading [viewBox]="'100 0 900 300'"></shared-loading>`,
    styleUrls: [`./global-loading.component.scss`],
    imports: [LoadingComponent]
})
export class GlobalLoadingComponent {}
