import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatTab, MatTabGroup } from '@angular/material/tabs';

import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

import { createDropMediaExtension } from '@app/admin/tiptap/drop-media';
import { Node, NoteModel, SaveNoteModel } from '@app/data/models';
import { APP_TIPTAP_EDITOR_CONFIG } from '@app/shared/tokens';
import { Editor, EditorOptions } from '@tiptap/core';
import { TiptapEditorDirective } from 'ngx-tiptap';

import { RichTextEditorToolbarComponent } from '../rich-text-editor-toolbar/rich-text-editor-toolbar.component';
import { SelectContentComponent } from '../select-content/select-content.component';

const DEBOUNCE_IN_MS = 500;

@Component({
    selector: 'admin-notes',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./notes.component.html`,
    styleUrls: [`./notes.component.scss`],
    imports: [
        ReactiveFormsModule,
        TiptapEditorDirective,
        MatFormField,
        MatLabel,
        MatInput,
        MatTabGroup,
        MatTab,
        RichTextEditorToolbarComponent,
        SelectContentComponent,
    ]
})
export class NotesComponent implements OnInit, OnChanges, OnDestroy {
    private fb = inject(FormBuilder);

    value = '';
    editor: Editor;

    private isAlive$: Subject<void> = new Subject();

    form: FormGroup = this.fb.group({
        rowKey: [''],
        title: [''],
        nodeIds: [[]],
        isFavourite: [false],
        content: [''],
    });

    @Input() model: NoteModel | null;
    @Input() nodes: Node[] | null;

    @Output() readonly save: EventEmitter<SaveNoteModel> = new EventEmitter();
    @Output() readonly nodeSelected: EventEmitter<Node> = new EventEmitter();

    get nodeIds(): AbstractControl {
        return this.form.controls['nodeIds'];
    }

    constructor() {
        const config = inject<EditorOptions>(APP_TIPTAP_EDITOR_CONFIG);
        const injector = inject(Injector);

        this.editor = new Editor({
            ...config,
            extensions: config.extensions.concat([createDropMediaExtension(injector)]),
            editable: true,
        });
    }

    onNodeSelected(node: Node): void {
        this.nodeSelected.emit(node);
    }

    ngOnInit(): void {
        this.form.controls['title'].valueChanges
            .pipe(
                takeUntil(this.isAlive$),
                debounceTime(DEBOUNCE_IN_MS),
                tap(s => this.save.emit({ model: { ...this.form.value }, saveTitle: true, saveContent: false })),
            )
            .subscribe();

        this.form.controls['nodeIds'].valueChanges
            .pipe(
                takeUntil(this.isAlive$),
                debounceTime(DEBOUNCE_IN_MS),
                tap(s => this.save.emit({ model: { ...this.form.value }, saveTitle: true, saveContent: false })),
            )
            .subscribe();

        this.form.controls['content'].valueChanges
            .pipe(
                takeUntil(this.isAlive$),
                filter(s => s !== this.model?.content),
                debounceTime(DEBOUNCE_IN_MS),
                tap(s => {
                    this.save.emit({ model: { ...this.form.value }, saveTitle: false, saveContent: true });
                }),
            )
            .subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['model'] && this.model) {
            this.form.patchValue({ ...this.model }, { emitEvent: false });
        }
    }

    ngOnDestroy(): void {
        this.isAlive$.next();
        this.isAlive$.complete();

        this.editor.destroy();
    }
}
