<section class="container ngx-toastr toast-info" [ngClass]="{ 'toast-info': !isError, 'toast-error': isError }">
  <div class="title" [attr.title]="title"><mat-icon>movie</mat-icon>{{ title }}</div>
  <div class="message" [attr.title]="message">{{ message }}</div>
  @if (!isCancelling && progress > 0) {
    <mat-progress-bar
      mode="determinate"
      color="accent"
      [value]="progress"
    ></mat-progress-bar>
  }
  <div class="actions">
    @if (isError) {
      <button mat-flat-button (click)="onCopyErrorMessage()">Copy</button>
    }
    <button mat-flat-button [color]="isError ? '' : 'primary'" (click)="onCompleted()">Dismiss</button>
  </div>
</section>
