import { SelectionModel } from '@angular/cdk/collections';
import { NgClass } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    input,
    OnChanges,
    output,
    signal,
    SimpleChanges,
    TrackByFunction,
    ViewChild,
} from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatTableDataSource,
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';

import { filter } from 'rxjs/operators';

import { ShareableLinkModel } from '@app/data/models';
import {
    DayOfWeek,
    FilterLinkTypes,
    LinkExpiration,
    LinkInactivityMode,
    LinkStatus,
    RegistrationMode,
    TimerUnit,
} from '@app/shared/enums';
import {
    DEFAULT_TIME_ZONE,
    getFormSubmissionResetDate,
    getTimeZoneByIanaTimeZone,
    isDate,
    isNullOrEmpty,
} from '@app/shared/util';
import { Timezone } from 'timezones.json';

import { ExpiresPipe } from '@app/shared/pipes/expires.pipe';
import { FormatDatePipe } from '@app/shared/pipes/format-date.pipe';
import { TimerUnitPipe } from '@app/shared/pipes/timer-unit.pipe';
import { ErrorDisplayComponent } from '@app/shared/components/error-display/error-display.component';
import { FilterConfig, FilterModel, LinkFilterComponent } from '@app/shared/components/link-filter/link-filter.component';

const DEFAULT_PAGE_SIZE = 25;
interface ShareableLinkViewModel extends ShareableLinkModel {
    hasLinkStarted: boolean;
    isStale: boolean;
    hasDates: boolean;
    hasTimer: boolean;
    displayAs: DisplayAs;
}

enum DisplayAs {
    None,
    StartDate,
    Timer,
    RegistrationCutOff,
    RegistrationReset,
}

@Component({
    selector: 'admin-shareable-links-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./shareable-links-list.component.html`,
    styleUrls: [`./shareable-links-list.component.scss`],
    imports: [
        RouterLink,
        NgClass,
        MatIcon,
        MatTable,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatMenu,
        MatMenuItem,
        MatIconButton,
        MatMenuTrigger,
        MatCellDef,
        MatCell,
        MatCheckbox,
        MatBadge,
        MatTooltip,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatPaginator,
        ExpiresPipe,
        TimerUnitPipe,
        FormatDatePipe,
        LinkFilterComponent,
        ErrorDisplayComponent,
    ]
})
export class ShareableLinksListComponent implements OnChanges, AfterViewInit {
    DisplayAs: typeof DisplayAs = DisplayAs;
    TimerUnit: typeof TimerUnit = TimerUnit;
    FilterLinkTypes: typeof FilterLinkTypes = FilterLinkTypes;
    LinkInactivityMode: typeof LinkInactivityMode = LinkInactivityMode;

    trackBy = (): TrackByFunction<ShareableLinkViewModel> => {
        return (ix, model) => model.rowKey;
    };
    readonly error = input();
    readonly links = input<ShareableLinkModel[] | null>(null);
    readonly filter = input<string | null>(null);
    readonly isLoading = input<boolean | null>(false);

    readonly copyLink = output<ShareableLinkModel>();
    readonly edit = output<ShareableLinkModel>();
    readonly delete = output<ShareableLinkModel[]>();
    readonly extend = output<ShareableLinkModel[]>();
    readonly copyLinkToClipboard = output<{
        model: ShareableLinkModel;
        timeZone: Timezone;
        includeInfluencer: boolean;
    }>();
    readonly openLink = output<ShareableLinkModel>();
    readonly statusChange = output<{ linkIds: string[]; status: LinkStatus }>();
    readonly refresh = output<void>();
    readonly view = output<ShareableLinkModel>();

    cd = inject(ChangeDetectorRef);

    dataSource: MatTableDataSource<ShareableLinkViewModel>;
    selection: SelectionModel<ShareableLinkViewModel> = new SelectionModel<ShareableLinkViewModel>(true, []);

    displayTags: string[] = [];
    allTags = signal<string[]>([]);

    filterModel = signal<FilterModel>({
        filter: '',
        tags: [],
        pageSize: DEFAULT_PAGE_SIZE,
        pageIndex: 0,
        linkFilters: [],
    });

    _displayedColumns = ['select', 'description', 'details', 'expires', 'password', 'actions'];
    filterColumns: FilterConfig[] = [
        {
            type: 'select',
            key: 'displayedColumns',
            label: 'Displayed Columns',
            multiple: true,
            options: [
                { key: 'index', value: 'Id' },
                { key: 'description', value: 'Description' },
                { key: 'details', value: 'Details' },
                { key: 'expires', value: 'Expires' },
                { key: 'password', value: 'Password' },
            ],
            default: ['description', 'details', 'expires'],
        },
        {
            type: 'select',
            key: 'linkFilters',
            label: 'Link Types',
            multiple: true,
            options: [
                { key: FilterLinkTypes.Registration, value: 'Registration Links' },
                { key: FilterLinkTypes.Communal, value: 'Communal Links' },
                { key: FilterLinkTypes.UserTimer, value: 'User-based Timer Links' },
                { key: FilterLinkTypes.LinkTimer, value: 'Link-based Timer Links' },
                { key: FilterLinkTypes.StartEnd, value: 'Start/End Links' },
                { key: FilterLinkTypes.NotActivated, value: 'Not Activated Links' },
                { key: FilterLinkTypes.Active, value: 'Active Links' },
                { key: FilterLinkTypes.Overdue, value: 'Overdue Links' },
                { key: FilterLinkTypes.RequestedLinks, value: 'Requested Links' },
            ],
            default: [],
        },
    ];

    get pageSize() {
        const { pageSize } = this.filterModel();

        return pageSize;
    }

    get pageIndex() {
        const { pageIndex } = this.filterModel();

        return pageIndex;
    }

    get displayedColumns(): string[] {
        return this._displayedColumns;
    }

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    onFilterChange(value: FilterModel) {
        switch (value['event']) {
            case 'init':
                this.updateDisplayedColumns(value['displayedColumns']);
                this.filterModel.set(value);
                this.onRefresh();
                break;
            case 'displayedColumns':
                this.updateDisplayedColumns(value['displayedColumns']);
                break;
            case 'filter':
            case 'tags':
            case 'linkFilters':
                this.filterModel.set(value);
                this.dataSource.filter = `${new Date()}`;
                break;
            case 'refresh':
                this.onRefresh();
                break;
        }
    }

    updateDisplayedColumns(event: string[]) {
        const allowedColumns = ['select', 'index', 'description', 'details', 'expires', 'password', 'actions'];
        this._displayedColumns = (event || []).filter(c => allowedColumns.includes(c));

        if (!this._displayedColumns.includes('actions')) {
            this._displayedColumns.push('actions');
        }

        if (!this._displayedColumns.includes('select')) {
            this._displayedColumns = ['select'].concat(this._displayedColumns);
        }

        this.cd.markForCheck();
    }

    onRefresh(): void {
        this.refresh.emit();
    }

    mapLinksToViewModel(link: ShareableLinkModel): ShareableLinkViewModel {
        const now = new Date();
        const hasDates = isDate(link.startDate) && isDate(link.endDate);
        const hasTimer = link.timer && link.timer > 0;
        const hasStartDate = !!link.startDate;
        const hasRegistrationCutoff = !!link.registrationEndDate;
        const registrationEndDate = hasRegistrationCutoff ? link.registrationEndDate : null;
        const startDate = hasStartDate ? link.startDate : null;
        const linkExpiration = link.linkExpiration;
        const inactivityMode = link.linkInactivityMode;
        const inactivityDate = new Date(link.createdDate.getTime());
        const isActive = link.isActive;

        inactivityDate.setDate(link.createdDate.getDate() + 30);

        let hasLinkStarted = link.activeSubmissionCount > 0;

        if (hasStartDate) {
            hasLinkStarted = now > (startDate as Date) && link.activeSubmissionCount > 0;
        } else if (hasTimer) {
            hasLinkStarted = hasRegistrationCutoff
                ? now > (registrationEndDate as Date) && link.activeSubmissionCount > 0
                : link.activeSubmissionCount > 0;
        }

        const fiveDaysAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 5);
        const isStale =
            !isActive && link.linkExpiration === LinkExpiration.Timer && new Date(link.createdDate) < fiveDaysAgo;
        const isInactiveLink = inactivityMode === LinkInactivityMode.Link && !isActive && now > inactivityDate;

        let displayAs = DisplayAs.StartDate;
        let resetStartDate: Date | null = null;
        let resetDisplay = null;

        if (hasRegistrationCutoff && now < (registrationEndDate as Date)) {
            displayAs = DisplayAs.RegistrationCutOff;
        } else if (link.registrationMode !== RegistrationMode.Default) {
            displayAs = DisplayAs.RegistrationReset;
            resetStartDate = getFormSubmissionResetDate(link);
            switch (link.registrationMode) {
                case RegistrationMode.Daily:
                    resetDisplay = 'Resets Daily';
                    break;
                case RegistrationMode.Weekly:
                    resetDisplay = `Resets ${DayOfWeek[link.dayOfWeek]} Weekly`;
                    break;
                case RegistrationMode.Monthly:
                    resetDisplay = `Resets Monthly`;
                    break;
            }
        } else if (linkExpiration === LinkExpiration.Date) {
            if (isActive || link.startDate) {
                displayAs = DisplayAs.StartDate;
            } else {
                displayAs = DisplayAs.None;
            }
        } else if (linkExpiration === LinkExpiration.Timer) {
            displayAs = DisplayAs.Timer;
        } else {
            displayAs = DisplayAs.None;
        }

        return {
            ...link,
            hasLinkStarted,
            isInactiveLink,
            isStale,
            hasDates,
            hasTimer,
            displayAs,
            resetStartDate,
            resetDisplay,
        } as ShareableLinkViewModel;
    }

    updateDataSource(): void {
        const links: ShareableLinkViewModel[] = (this.links() as ShareableLinkModel[]).map(
            this.mapLinksToViewModel.bind(this),
        ); // clone

        links.sort((a: ShareableLinkViewModel, b: ShareableLinkViewModel) => {
            if (a.isExpired && !b.isExpired) {
                return 1;
            } else if (!a.isExpired && b.isExpired) {
                return -1;
            }

            if (a.status === LinkStatus.Inactive && b.status === LinkStatus.Active) {
                return 1;
            } else if (a.status === LinkStatus.Active && b.status === LinkStatus.Inactive) {
                return -1;
            }

            if (a.status === LinkStatus.Active && b.status === LinkStatus.Active) {
                if (
                    a.lateRegistrationCount + a.approvalCount + a.resubmissionCount > 0 &&
                    b.lateRegistrationCount + b.approvalCount + b.resubmissionCount === 0
                ) {
                    return -1;
                } else if (
                    a.lateRegistrationCount + a.approvalCount + a.resubmissionCount === 0 &&
                    b.lateRegistrationCount + b.approvalCount + b.resubmissionCount > 0
                ) {
                    return 1;
                }

                if (a.hasLinkStarted && !b.hasLinkStarted) {
                    return -1;
                } else if (!a.hasLinkStarted && b.hasLinkStarted) {
                    return 1;
                }

                if (a.activeSubmissionCount > 0 && b.activeSubmissionCount === 0) {
                    return -1;
                } else if (a.activeSubmissionCount === 0 && b.activeSubmissionCount > 0) {
                    return 1;
                }

                if (a.approvalCount > 0 && b.approvalCount === 0) {
                    return -1;
                } else if (a.approvalCount === 0 && b.approvalCount > 0) {
                    return 1;
                }
            }

            if (a.activeSubmissionCount > 0 && b.activeSubmissionCount === 0) {
                return -1;
            } else if (a.activeSubmissionCount === 0 && b.activeSubmissionCount > 0) {
                return 1;
            }

            if (!a.isExpired) {
                if (a.isActive && !b.isActive) {
                    return -1;
                } else if (!a.isActive && b.isActive) {
                    return 1;
                }

                if (a.isStale && !b.isStale) {
                    return -1;
                } else if (!a.isStale && b.isStale) {
                    return 1;
                }

                if (a.hasTimer && !b.hasTimer) {
                    return -1;
                } else if (!a.hasTimer && b.hasTimer) {
                    return 1;
                }

                if (a.hasTimer && !a.isActive) {
                    return new Date(a.createdDate) < new Date(b.createdDate) ? -1 : 1;
                } else {
                    const expiresA = a.linkExpirationDate?.getTime() || 0;
                    const expiresB = b.linkExpirationDate?.getTime() || 0;

                    if (expiresA === 0 && expiresB !== 0) {
                        return 1;
                    } else if (expiresA !== 0 && expiresB === 0) {
                        return -1;
                    } else if (expiresA > expiresB) {
                        return 1;
                    } else if (expiresA < expiresB) {
                        return -1;
                    }

                    if (a.requestedBy && !b.requestedBy) {
                        return 1;
                    } else if (!a.requestedBy && b.requestedBy) {
                        return -1;
                    }

                    const descriptionA = `(${a.requestedBy}) ${a.url}`;
                    const descriptionB = `(${b.requestedBy}) ${b.url}`;

                    return descriptionA.localeCompare(descriptionB);
                }
            }

            if (a.isActive && !b.isActive) {
                return -1;
            } else if (!a.isActive && b.isActive) {
                return 1;
            }

            const expiresA = a.linkExpirationDate?.getTime() || 0;
            const expiresB = b.linkExpirationDate?.getTime() || 0;

            if (expiresA === 0 && expiresB !== 0) {
                return 1;
            } else if (expiresA !== 0 && expiresB === 0) {
                return -1;
            } else if (expiresA > expiresB) {
                return 1;
            } else if (expiresA < expiresB) {
                return -1;
            }

            const descriptionA = `(${a.requestedBy}) ${a.url}`;
            const descriptionB = `(${b.requestedBy}) ${b.url}`;

            return descriptionA.localeCompare(descriptionB);
        });

        this.selection.clear();
        this.dataSource = new MatTableDataSource<ShareableLinkViewModel>(links);

        this.dataSource.filterPredicate = this.filterPredicate.bind(this);
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = `${new Date()}`;

        this.allTags.set(
            links
                .reduce((acc, link) => {
                    (link.tags || []).forEach(tag => {
                        if (tag && !acc.includes(tag.trim())) {
                            acc.push(tag.trim());
                        }
                    });

                    return acc;
                }, [] as string[])
                .sort(),
        );
    }

    ngAfterViewInit(): void {
        if (this.dataSource && this.paginator && !this.dataSource.paginator) {
            this.dataSource.paginator = this.paginator;
        }

        const { filter } = this.filterModel();
        this.dataSource.filter = filter + '.';
    }

    filterPredicate(data: ShareableLinkViewModel): boolean {
        let include = true;
        const { filter, linkFilters, tags } = this.filterModel();
        const filterValue = (filter || '').toLocaleLowerCase();
        const linkFiltersValue = linkFilters || [];
        const areAllFalse = linkFiltersValue.length === 0;

        if (tags.length > 0) {
            if (data.tags.every(tag => !tags.includes(tag))) {
                return false;
            }
        }

        if (!areAllFalse) {
            if (linkFilters.includes(FilterLinkTypes.Registration) && !data.hasRegistration) {
                include = false;
            }

            if (linkFilters.includes(FilterLinkTypes.UserTimer) && data.linkInactivityMode !== LinkInactivityMode.User) {
                include = false;
            }

            if (linkFilters.includes(FilterLinkTypes.LinkTimer) && data.linkInactivityMode !== LinkInactivityMode.Link) {
                include = false;
            }

            if (linkFilters.includes(FilterLinkTypes.StartEnd) && data.linkExpiration !== LinkExpiration.Date) {
                include = false;
            }

            if (linkFilters.includes(FilterLinkTypes.NotActivated) && data.isActive) {
                include = false;
            }

            if (linkFilters.includes(FilterLinkTypes.Active) && !data.isActive) {
                include = false;
            }

            if (linkFilters.includes(FilterLinkTypes.Overdue) && !data.isStale) {
                include = false;
            }

            if (linkFilters.includes(FilterLinkTypes.Communal) && data.registrationMode === RegistrationMode.Default) {
                include = false;
            }

            if (linkFilters.includes(FilterLinkTypes.RequestedLinks) && isNullOrEmpty(data.requestedByVisitorId)) {
                include = false;
            }

            if (!include) {
                return false;
            }
        }

        if (filterValue === '') {
            return true;
        }

        const requestedBy = (data.requestedBy || '').toLocaleLowerCase().split(' ');

        return (
            (data?.rowKey || '').toLocaleLowerCase().startsWith(filterValue) ||
            data.url.toLocaleLowerCase().includes(filterValue) ||
            requestedBy.some(rb => rb.startsWith(filterValue))
        );
    }

    onCopyToClipboard(viewModel: ShareableLinkViewModel, includeInfluencer = false): void {
        const model = { ...viewModel };
        const timeZone = getTimeZoneByIanaTimeZone(model.timeZone || DEFAULT_TIME_ZONE) as Timezone;

        this.copyLinkToClipboard.emit({ model, includeInfluencer, timeZone });
    }

    onOpenLink(viewModel: ShareableLinkViewModel): void {
        this.openLink.emit(viewModel);
    }

    onDeleteSelected(): void {
        if (this.selection.hasValue()) {
            this.delete.emit(this.selection.selected);
        }
    }

    onCopyLink(model: ShareableLinkViewModel): void {
        this.copyLink.emit(model);
    }

    onEdit(model: ShareableLinkViewModel): void {
        this.edit.emit(model);
    }

    onDelete(model: ShareableLinkViewModel): void {
        this.delete.emit([model]);
    }

    onExtend(model: ShareableLinkViewModel): void {
        this.extend.emit([model]);
    }

    canExtendLink(model: ShareableLinkViewModel): boolean {
        return (
            model.linkExpiration === LinkExpiration.Date ||
            (model.linkExpiration === LinkExpiration.Timer && model.linkInactivityMode === LinkInactivityMode.Link)
        );
    }

    onExtendSelected(): void {
        if (this.selection.hasValue()) {
            this.extend.emit(this.selection.selected);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['links']) {
            this.updateDataSource();
        }
    }
}
