import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';

import { AppSettings } from '@app/data/models';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MatFormField, MatError, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOptgroup, MatOption } from '@angular/material/core';

import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';

@Component({
    selector: 'admin-admin-actions',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./admin-actions.component.html`,
    styleUrl: `./admin-actions.component.scss`,
    imports: [
        ReactiveFormsModule,
        MatCard,
        MatCardContent,
        MatCardTitle,
        MatFormField,
        MatSelect,
        MatOptgroup,
        MatOption,
        MatError,
        MatButton,
        MatProgressSpinner,
        MatCheckbox,
        MatInput,
        MatLabel,
    ]
})
export class AdminActionsComponent {
    fb = inject(FormBuilder);

    form = this.fb.group({
        action: ['', [Validators.required]],
        sendToQueue: [false],
        payload: [''],
    });

    @Input() isPending: boolean | null = null;
    @Input() isSnapshotPending: boolean | null = null;
    @Input() isNodePermissionPending: boolean | null = null;
    @Input() isMigrateNotificationsPending: boolean | null = null;
    @Input() isMigrateBinariesPending: boolean | null = null;
    @Input() isMigrateContentLinksPending: boolean | null = null;
    @Input() settings: AppSettings | null = null;

    @Output() readonly calculateNodePermissions: EventEmitter<void> = new EventEmitter();
    @Output() readonly snapshotContent: EventEmitter<void> = new EventEmitter();
    @Output() readonly migrateNotifications: EventEmitter<void> = new EventEmitter();
    @Output() readonly migrateBinaries: EventEmitter<void> = new EventEmitter();
    @Output() readonly migrateContentLinks: EventEmitter<void> = new EventEmitter();
    @Output() readonly executeAction: EventEmitter<{ action: string; sendToQueue: boolean; payload: string }> =
        new EventEmitter();

    onCalculateNodePermissions() {
        this.calculateNodePermissions.emit();
    }

    onSnapshotContent() {
        this.snapshotContent.emit();
    }

    onMigrateNotifications() {
        this.migrateNotifications.emit();
    }

    onMigrateBinaries() {
        this.migrateBinaries.emit();
    }

    onMigrateContentLinks() {
        this.migrateContentLinks.emit();
    }

    onExecuteAction() {
        if (this.form.invalid) {
            return;
        }

        this.executeAction.emit(this.form.value as any);
    }
}
