import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isNullOrUndefined } from '@app/shared/util';
import { ContentLoaderModule } from '@ngneat/content-loader';

@Component({
    selector: 'shared-loading',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./loading.component.html`,
    styleUrls: [`./loading.component.scss`],
    imports: [ContentLoaderModule]
})
export class LoadingComponent {
    get _interval(): number {
        if (isNullOrUndefined(this.interval)) {
            return 0.25;
        }

        return this.interval as number;
    }

    get _gradientRatio(): number {
        return this.gradientRatio || 1;
    }

    get _viewBox(): string {
        return this.viewBox || '250 -100 1100 800';
    }

    @Input() speed = 1.2;
    @Input() animate: boolean | null = true;
    @Input() interval: number | null = 0.25;
    @Input() viewBox: string | null = '250 -100 1100 800';

    @Input() gradientRatio: number | null = 1;

    @Input() backgroundColor = '#000000';
    @Input() backgroundOpacity = 1;

    @Input() foregroundColor = '#eee';
    @Input() foregroundOpacity = 1;
}
