import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatRipple } from '@angular/material/core';

import { LoadingComponent } from '../loading/loading.component';

@Component({
    selector: 'shared-loading-button',
    templateUrl: `./loading-button.component.html`,
    styleUrls: [`./loading-button.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatRipple,
        LoadingComponent
    ]
})
export class LoadingButtonComponent {
    viewBox = '100 0 900 300';

    @Input() isLoading: boolean | null = false;
    @Input() isDisabled: boolean | null = false;
}
