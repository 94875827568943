import { EncodingJob } from '@app/data/models';
import { LinkExpiration, LinkInactivityMode, TimerUnit } from '@app/shared/enums';
import { replaceIllegalCharacters } from '@app/shared/util';
import { from, map, mergeMap, of, tap } from 'rxjs';

export function getFileNameForMediaAssetUpload(mediaAssetId: string, file: File) {
    return `media-assets/${mediaAssetId}/${replaceIllegalCharacters(file.name).replace(/\W/g, '-')}.original`;
}

export function hasLinkEnded(
    now: Date,
    createdDate: Date,
    activationDate: Date | null,
    lastActivityDate: Date | null,
    endDate: Date | null,
    timerEndDate: Date | null,
    linkInactivityMode: LinkInactivityMode,
    linkExpiration: LinkExpiration,
    timer: number | null = 0,
    timerUnit: TimerUnit,
    inactivityTimeoutInDays: number = 0,
): boolean {
    if (linkExpiration === LinkExpiration.None) {
        return false;
    }

    if (linkExpiration === LinkExpiration.Date && endDate) {
        return now > endDate;
    }

    if (linkExpiration === LinkExpiration.Timer) {
        if (timerEndDate) {
            return now > timerEndDate;
        }

        if (linkInactivityMode === LinkInactivityMode.Link) {
            const endDate = new Date(activationDate || createdDate);

            switch (timerUnit) {
                case TimerUnit.Months:
                    endDate.setDate(endDate.getDate() + (timer as number) * 30);
                    break;
                case TimerUnit.Weeks:
                    endDate.setDate(endDate.getDate() + (timer as number) * 7);
                    break;
                case TimerUnit.Days:
                    endDate.setDate(endDate.getDate() + (timer as number));
                    break;
                case TimerUnit.Hours:
                    endDate.setHours(endDate.getHours() + (timer as number));
                    break;
                default:
                    endDate.setMinutes(endDate.getMinutes() + (timer as number));
                    break;
            }

            return now > endDate;
        }

        if (linkInactivityMode === LinkInactivityMode.User) {
            const endDate = new Date(lastActivityDate || createdDate);

            switch (timerUnit) {
                case TimerUnit.Months:
                    endDate.setDate(endDate.getDate() + (timer as number) * 30);
                    break;
                case TimerUnit.Weeks:
                    endDate.setDate(endDate.getDate() + (timer as number) * 7);
                    break;
                case TimerUnit.Days:
                    endDate.setDate(endDate.getDate() + (timer as number));
                    break;
                case TimerUnit.Hours:
                    endDate.setHours(endDate.getHours() + (timer as number));
                    break;
                default:
                    endDate.setMinutes(endDate.getMinutes() + (timer as number));
                    break;
            }

            endDate.setDate(endDate.getDate() + inactivityTimeoutInDays);

            return now > endDate;
        }
    }

    return false;
}

export function getEncodingJobStatusAsObservable(job: EncodingJob, url = 'https://api.qencode.com/v1/status') {
    const body = new FormData();
    body.append('task_tokens', job.providerUniqueId);

    return from(fetch(url, { method: 'POST', body })).pipe(
        mergeMap(response => {
            if (response.ok) {
                return response.json() as Promise<ApiResponse>;
            }

            return of(null);
        }),
        map(resp => {
            if (resp && resp.error === 0 && resp.statuses[job.providerUniqueId]) {
                return resp.statuses[job.providerUniqueId];
            }

            return null;
        }),
    );
}

export interface ApiResponse {
    error: number;
    statuses: {
        [taskToken: string]: EncodingState;
    };
}

export interface EncodingState {
    status: string;
    percent: number;
    error: number;
    error_description: string | null;
    warnings: string[] | null;
    images: any[];
    videos: VideoModel[];
    audios: any[];
    texts: any[];
    duration: number | string;
    source_size: number | string;
    api_version: string;
    status_url: string;
}

export interface VideoModel {
    tag: string;
    profile: string | null;
    user_tag: string | null;
    storage: {
        url: string;
        playlist: string;
        type: string;
        format: string;
        expire: string | null;
        timestamp: string;
    };
    url: string;
    bitrate: number;
    meta: {
        resolution_width: number;
        resolution_height: number;
        framerate: string;
        height: number;
        width: number;
        codec: string;
        dar: string;
        aspect_ratio: number;
        sar: string;
        bitrate: string;
        audio_codec: string;
    };
    duration: string;
    size: string;
    output_format: string;
    percent: number;
    status: string;
    error: boolean;
    error_description: string | null;
}
