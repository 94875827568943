<h1 mat-dialog-title>Create webinar from zoom template</h1>
<mat-dialog-content>
  <admin-create-webinar-from-template
    [model]="model$ | async"
    [templates]="templates$ | async"
    (refresh)="onRefreshTemplates()"
  ></admin-create-webinar-from-template>
  <shared-error-display [error]="errors$ | async"></shared-error-display>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">Close</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="(isValid$ | async) === false || (isSaving$ | async) === true"
    (click)="onCreateWebinarFromTemplate()"
    >
    @if ((isSaving$ | async) === true) {
      <mat-spinner matPrefix diameter="20" strokeWidth="4"></mat-spinner>
    }
    Create
  </button>
</mat-dialog-actions>
