<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let data">
      <div class="file-name">{{ data.file.name }}</div>
      @if (data.warnings.fileSize) {
        <div class="warning file-size-warning">{{ data.warnings.fileSize }}</div>
      }
      @if (data.warnings.audio) {
        <div class="warning audio-warning">{{ data.warnings.audio }}</div>
      }
      @if (data.warnings.video) {
        <div class="warning video-warning">{{ data.warnings.video }}</div>
      }
    </td>
  </ng-container>
  <ng-container matColumnDef="nodeType">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let data">
      <mat-select [(ngModel)]="data.nodeType" (selectionChange)="onNodeTypeChange($event, data)">
        @for (option of data.nodeTypeOptions; track option) {
          <mat-option [value]="option">{{
            getMediaNodeTypeLabel(option)
          }}</mat-option>
        }
      </mat-select>
    </td>
  </ng-container>
  <ng-container matColumnDef="fileSize">
    <th mat-header-cell *matHeaderCellDef>Size</th>
    <td mat-cell *matCellDef="let data">
      {{ data.fileSize | fileSize }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let data">

      <button
        color="warn"
        mat-mini-fab
        matTooltip="Remove this file"
        (click)="$event.stopPropagation(); onRemove(data)"
        >
        <mat-icon>remove</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns" [ngClass]="getRowClasses(element)"></tr>
</table>
