import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';

@Component({
    templateUrl: './archive-content-link-confirmation-dialog.component.html',
    styles: [
        `
            :host {
                width: 600px;
                display: block;
            }

            mat-dialog-content {
                height: 100px;
            }
        `,
    ],
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
    ]
})
export class ArchiveContentLinkConfirmationDialogComponent {
    private data = inject(MAT_DIALOG_DATA);

    get count(): number {
        return this.data.length;
    }
}
