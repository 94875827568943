import { SelectionModel } from '@angular/cdk/collections';
import { NgClass } from '@angular/common';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    inject,
    input,
    Input,
    OnChanges,
    OnDestroy,
    output,
    Output,
    signal,
    SimpleChanges,
    TrackByFunction,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatTableDataSource,
} from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';

import { Subject } from 'rxjs';

import { ShareableLinkTemplateModel } from '@app/data/models';
import { ErrorDisplayComponent } from '@app/shared/components/error-display/error-display.component';
import { FormatDatePipe } from '@app/shared/pipes/format-date.pipe';
import { FilterModel, LinkFilterComponent } from '@app/shared/components/link-filter/link-filter.component';

@Component({
    selector: 'admin-shareable-link-template-list',
    templateUrl: './shareable-link-template-list.component.html',
    styleUrls: ['./shareable-link-template-list.component.scss'],
    imports: [
        ReactiveFormsModule,
        RouterLink,
        NgClass,
        MatTable,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatMenu,
        MatMenuItem,
        MatIcon,
        MatIconButton,
        MatMenuTrigger,
        MatCellDef,
        MatCell,
        MatCheckbox,
        MatTooltip,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatPaginator,
        FormatDatePipe,
        ErrorDisplayComponent,
        LinkFilterComponent,
    ]
})
export class ShareableLinkTemplateListComponent implements OnChanges, AfterViewInit {
    trackBy = (): TrackByFunction<ShareableLinkTemplateModel> => {
        return (ix, model) => model.rowKey;
    };

    readonly error = input<any>();
    readonly templates = input<ShareableLinkTemplateModel[] | null>([]);
    readonly filter = input<string | null>(null);
    readonly isLoading = input<boolean | null>(false);

    readonly copyTemplate = output<ShareableLinkTemplateModel>();
    readonly edit = output<ShareableLinkTemplateModel>();
    readonly delete = output<ShareableLinkTemplateModel[]>();
    readonly refresh = output<void>();
    readonly view = output<ShareableLinkTemplateModel>();

    dataSource: MatTableDataSource<ShareableLinkTemplateModel>;
    selection: SelectionModel<ShareableLinkTemplateModel> = new SelectionModel<ShareableLinkTemplateModel>(true, []);

    pageSize = 25;
    pageIndex = 0;

    filterModel = signal<FilterModel>({
        filter: '',
        tags: [],
    });

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    onFilterChange(value: FilterModel) {
        switch (value['event']) {
            case 'filter':
                const { filter } = this.filterModel();
                this.dataSource.filter = filter + '.';
                break;
            case 'refresh':
            case 'init':
                this.refresh.emit();
                break;
        }
    }

    onRefresh(): void {
        this.refresh.emit();
    }

    ngAfterViewInit(): void {
        if (this.dataSource && this.paginator && !this.dataSource.paginator) {
            this.dataSource.paginator = this.paginator;
        }

        const { filter } = this.filterModel();
        this.dataSource.filter = filter + '.';
    }

    filterPredicate(data: ShareableLinkTemplateModel): boolean {
        const { filter } = this.filterModel();
        const filterValue = (filter || '').toLocaleLowerCase();

        if (filterValue === '') {
            return true;
        }

        return (
            (data.rowKey || '').toLocaleLowerCase().startsWith(filterValue) ||
            data.templateName.toLocaleLowerCase().includes(filterValue)
        );
    }

    updateDataSource(): void {
        if (!this.templates) {
            return;
        }

        const links: ShareableLinkTemplateModel[] = [...(this.templates() || [])];

        // If the result is negative a is sorted before b.
        // If the result is positive b is sorted before a.
        // If the result is 0 no changes are done with the sort order of the two values.

        links.sort((a: ShareableLinkTemplateModel, b: ShareableLinkTemplateModel) => {
            return a.templateName.localeCompare(b.templateName);
        });

        this.dataSource = new MatTableDataSource<ShareableLinkTemplateModel>(links);

        this.dataSource.filterPredicate = this.filterPredicate.bind(this);
        this.dataSource.filter = `${new Date()}`;
        this.dataSource.paginator = this.paginator;
    }

    onDeleteSelected(): void {
        if (this.selection.hasValue()) {
            this.delete.emit(this.selection.selected);
        }
    }

    onCopyTemplate(model: ShareableLinkTemplateModel): void {
        this.copyTemplate.emit(model);
    }

    onEdit(model: ShareableLinkTemplateModel): void {
        this.edit.emit(model);
    }

    onDelete(model: ShareableLinkTemplateModel): void {
        this.delete.emit([model]);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['templates']) {
            this.updateDataSource();
        }
    }
}
