<shared-link-filter
    storageKey="manageLinks.filters"
    [config]="filterColumns"
    [isLoading]="isLoading()"
    [model]="filterModel()"
    [tags]="allTags()"
    (filterChange)="onFilterChange($event)"
></shared-link-filter>
@if(error(); as errorModel){
<shared-error-display [error]="errorModel"></shared-error-display>
}
<div class="container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-menu #actions="matMenu">
                    <button mat-menu-item (click)="onDeleteSelected()">
                        <mat-icon color="warn">archive</mat-icon>
                        Archive ({{ selection.selected.length }})
                    </button>
                    <button mat-menu-item (click)="onExtendSelected()">
                        <mat-icon color="warn">more_time</mat-icon>
                        Extend time ({{ selection.selected.length }})
                    </button>
                </mat-menu>
                @if(selection.hasValue()){<button mat-icon-button [matMenuTriggerFor]="actions">
                    <mat-icon>more_vert</mat-icon></button
                >}
            </th>
            <td mat-cell *matCellDef="let data">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(data) : null"
                    [checked]="selection.isSelected(data)"
                >
                </mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let data">
                {{ data.rowKey }}
            </td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let data">
                <a
                    matBadgePosition="before"
                    matBadgeOverlap="false"
                    [matBadge]="data.activeSubmissionCount || '-'"
                    class="pointer active-badge"
                    [routerLink]="['/home/shareable-links', data.rowKey]"
                    [queryParams]="{ t: 'sessions' }"
                    [ngClass]="{
                        active: data.hasLinkStarted && data.activeSubmissionCount > 0,
                        empty: data.activeSubmissionCount === 0
                    }"
                >
                    {{ data.requestedBy ? '(' + data.requestedBy + ')' : '' }}
                    {{ data.url }}
                </a>
                @if(data.requestedByVisitorId){
                <mat-icon class="influencer">account_circle</mat-icon>
                } @if(data.influencerPassword){
                <mat-icon class="influencer" [ngClass]="{ active: data.influencerCount > 0 }">verified_user</mat-icon>
                }
                <div class="meta-badges">
                    @if(data.approvalCount > 0){
                    <span
                        matBadgePosition="after"
                        matBadgeOverlap="false"
                        [matBadge]="data.approvalCount"
                        class="badge pending-badge"
                        >&nbsp;
                    </span>
                    } @if(data.lateRegistrationCount > 0){
                    <span
                        matBadgePosition="after"
                        matBadgeOverlap="false"
                        [matBadge]="data.lateRegistrationCount"
                        class="badge inactive-badge"
                    >
                        &nbsp;
                    </span>
                    } @if(data.resubmissionCount > 0){
                    <span matBadgePosition="after" matBadgeOverlap="false" [matBadge]="data.resubmissionCount" class="badge">
                        &nbsp;
                    </span>
                    }
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>Details</th>
            <td mat-cell *matCellDef="let model">
                <time> Create Date {{ model.createdDate | formatDate }} </time>
                @if(model.isActive){ @switch(model.displayAs){ @case (DisplayAs.RegistrationReset){
                <time class="active">
                    {{ model.resetDisplay }} ({{ model.resetStartDate | formatDate }}) @if(!model.isExpired){<span
                        >Expiring in {{ model.linkExpirationDate | expires }}</span
                    >}
                </time>

                } @case (DisplayAs.Timer){
                <time class="active">
                    {{ model.linkInactivityMode === LinkInactivityMode.Link ? 'Link Timer' : 'User Timer' }} ({{ model.timer
                    }}{{ model.timerUnit | timerUnit }}) @if(!model.isExpired){<span>{{
                        model.linkExpirationDate | expires
                    }}</span
                    >}
                </time>
                } @case (DisplayAs.RegistrationCutOff){
                <time class="pending"> Cut-off {{ model.registrationEndDate | formatDate }} </time>} @default {
                <time class="active"> Start Date {{ model.startDate || model.createdDate | formatDate }} </time>
                } } } @if(!model.autoArchive){ <span>Auto-archive disabled</span>}
            </td>
        </ng-container>
        <ng-container matColumnDef="expires">
            <th mat-header-cell *matHeaderCellDef>Expires</th>
            <td mat-cell *matCellDef="let model">
                @if(model.linkExpirationDate !== null){
                <time> {{ model.linkExpirationDate | formatDate }} </time>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="password">
            <th mat-header-cell *matHeaderCellDef>Password</th>
            <td mat-cell *matCellDef="let data">
                {{ data.password }}
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let data">
                <mat-menu #actions="matMenu">
                    <button mat-menu-item (click)="onEdit(data)">
                        <mat-icon>edit</mat-icon>
                        Edit
                    </button>
                    <button mat-menu-item (click)="onCopyLink(data)">
                        <mat-icon>content_copy</mat-icon>
                        Duplicate
                    </button>
                    <button mat-menu-item color="accent" (click)="onCopyToClipboard(data)">
                        <mat-icon>link</mat-icon>
                        Copy Link Info
                    </button>
                    <button
                        mat-menu-item
                        color="accent"
                        [disabled]="!data.influencerPassword"
                        (click)="onCopyToClipboard(data, true)"
                    >
                        <mat-icon>link</mat-icon>
                        Copy Influencer Info
                    </button>
                    @if(canExtendLink(data)){<button mat-menu-item color="accent" (click)="onExtend(data)">
                        <mat-icon>more_time</mat-icon>
                        Extend link</button
                    >}
                    <button mat-menu-item (click)="onDelete(data)">
                        <mat-icon color="warn">delete</mat-icon>
                        Archive
                    </button>
                    <button mat-menu-item (click)="onOpenLink(data)">
                        <mat-icon>launch</mat-icon>
                        View
                    </button>
                </mat-menu>
                <button mat-icon-button matTooltip="Duplicate link" (click)="onCopyLink(data)">
                    <mat-icon>content_copy</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Copy to clipboard" (click)="onCopyToClipboard(data)">
                    <mat-icon>link</mat-icon>
                </button>
                @if(canExtendLink(data)){<button mat-icon-button matTooltip="Extend link time" (click)="onExtend(data)">
                    <mat-icon>more_time</mat-icon></button
                >}
                <button mat-icon-button [matMenuTriggerFor]="actions">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{
                inactive: row.status === 0 || row.isExpired,
                expired: row.isExpired,
                stale: row.isStale,
                'not-activated': !row.isActive
            }"
        ></tr>
    </table>
    <mat-paginator
        [length]="links()?.length"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="[25, 50, 100]"
    >
    </mat-paginator>
    <!--
    <time>Test: {{ '2020-06-20T19:15:00Z' | amUtc | amUtcOffset: -10 | amDateFormat: 'LLLL (Z)' }}</time>
    <time>Test: {{ '2020-06-20T23:15:00.000Z' | amUtcOffset: -10 | amDateFormat: 'LLLL (Z)' }}</time> -->
</div>
