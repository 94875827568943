import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    inject,
    input,
    OnChanges,
    OnInit,
    output,
    signal,
} from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import {
    AsyncSubject,
    catchError,
    filter,
    from,
    interval,
    mergeMap,
    of,
    startWith,
    Subject,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';

import { EncodingJob } from '@app/data/models';
import { DestroyService } from '@app/shared/services/destroy.service';
import { isNullOrEmpty } from '@app/shared/util';
import { EncodingState, getEncodingJobStatusAsObservable } from '@app/admin/utils';

@Component({
    selector: 'admin-encoding-status',
    templateUrl: './encoding-status.component.html',
    styleUrls: ['./encoding-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService],
    imports: [NgClass, MatProgressSpinner, MatProgressBar],
})
export class EncodingStatusComponent implements OnInit, OnChanges {
    private destroy$ = inject(DestroyService, { self: true });

    readonly encodingJob = input<EncodingJob | null>(null);
    readonly encodingComplete = output<EncodingJob | null>();

    completed$ = new AsyncSubject<void>();
    digest$ = new Subject<void>();

    state = signal<EncodingState | null>(null);

    status = computed(() => {
        if (this.hasError()) {
            return 'error encoding video';
        }

        return this.state()?.status || 'queued';
    });

    progress = computed(() => {
        return this.state()?.percent || 0;
    });

    hasError = computed(() => {
        return this.state()?.error === 1;
    });

    errorMessage = computed(() => {
        return this.state()?.error_description || 'Unknown error';
    });

    showIndeterminateBar = computed(() => {
        return this.progress() === 0 && this.status() !== 'completed';
    });

    ngOnInit(): void {
        this.digest$
            .pipe(
                takeUntil(this.destroy$),
                filter(() => !isNullOrEmpty(this.encodingJob()?.providerUniqueId)),
                switchMap(() => {
                    const job = this.encodingJob() as EncodingJob;

                    return getEncodingJobStatusAsObservable(job).pipe(
                        takeUntil(this.destroy$),
                        takeUntil(this.completed$),
                        mergeMap((resp) =>
                            interval(5000).pipe(
                                startWith(0),
                                takeUntil(this.destroy$),
                                takeUntil(this.completed$),
                                mergeMap(() => getEncodingJobStatusAsObservable(job, resp?.status_url)),
                                catchError(err => {
                                    return of(null);
                                }),
                            ),
                        ),
                        tap(resp => {
                            this.state.set(resp);
                            if (resp?.status === 'completed') {
                                this.completed$.next();
                                this.completed$.complete();
                                this.encodingComplete.emit(this.encodingJob());
                            }
                        }),
                    );
                }),
            )
            .subscribe();

        this.digest$.next(void 0);
    }

    ngOnChanges(): void {
        this.digest$.next(void 0);
    }
}

interface ApiResponse {
    error: number;
    statuses: {
        [taskToken: string]: {
            status: string;
            percent: number;
            error: number;
            error_description: string | null;
            warnings: string[] | null;
            images: any[];
            videos: VideoModel[];
            audios: any[];
            texts: any[];
            duration: number | string;
            source_size: number | string;
            api_version: string;
            status_url: string;
        };
    };
}

interface VideoModel {
    tag: string;
    profile: string | null;
    user_tag: string | null;
    storage: {
        url: string;
        playlist: string;
        type: string;
        format: string;
        expire: string | null;
        timestamp: string;
    };
    url: string;
    bitrate: number;
    meta: {
        resolution_width: number;
        resolution_height: number;
        framerate: string;
        height: number;
        width: number;
        codec: string;
        dar: string;
        aspect_ratio: number;
        sar: string;
        bitrate: string;
        audio_codec: string;
    };
    duration: string;
    size: string;
    output_format: string;
    percent: number;
    status: string;
    error: boolean;
    error_description: string | null;
}
