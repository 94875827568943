@if (path !== null) {
<section class="container">
    <admin-breadcrumb [path]="path" [nodes]="nodes" (selectNode)="setRoot($event)"></admin-breadcrumb>
</section>
} @if (nodeDataSource && nodeDataSource.data.length > 0) {
<mat-tree class="item" [dataSource]="nodeDataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [matTreeNodePaddingIndent]="24">
        <div class="indent"></div>
        <div class="node-content">
            <mat-checkbox [checked]="checklistSelection.isSelected(node.data)" (change)="leafSelectionToggle(node)">
                {{ node.item }}
            </mat-checkbox>
            <!-- use a disabled button to provide padding for tree leaf -->
            <mat-icon [ngClass]="node.data | nodeColour">{{ node.data | nodeTypeIcon }}</mat-icon>
            <span
                [ngClass]="node.data.status === NodeStatus.InActive ? 'inactive' : ''"
                class="node-name"
                (click)="onNodeSelected(node.data); $event.stopPropagation()"
                >{{ node.name }}</span
            >
            @if (node.data.duration) {
            <time>&nbsp;({{ node.data.duration }})</time>
            }
        </div>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="24">
        <button mat-icon-button matTreeNodeToggle>
            <mat-icon>
                {{ isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
        </button>
        <mat-checkbox
            [checked]="descendantsAllSelected(node)"
            [indeterminate]="descendantsPartiallySelected(node)"
            (change)="nodeSelectionToggle(node)"
        >
            {{ node.item }}
        </mat-checkbox>
        <mat-icon [ngClass]="node.data | nodeColour">{{ node.data | nodeTypeIcon }}</mat-icon>
        <span
            class="node-name has-children"
            [ngClass]="{ 'is-expanded': isExpanded(node), inactive: node.data.status === NodeStatus.InActive }"
            (click)="setRoot(node.data); $event.stopPropagation()"
            >{{ node.name }}</span
        >
        @if (showNodeOptions) {
        <mat-checkbox
            class="link-to-folder"
            [checked]="isFolderAssociatedWithLink(node)"
            (change)="onAssociateLinkToFolder($event, node)"
        >
            Associate link with this folder
        </mat-checkbox>
        }
    </mat-tree-node>
</mat-tree>
}
