<form [formGroup]="form">
    <mat-form-field appearance="outline" class="title">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" />
    </mat-form-field>
    <mat-tab-group class="content">
        <mat-tab label="Content">
            <div class="html-content">
                <admin-rich-text-editor-toolbar [editor]="editor"></admin-rich-text-editor-toolbar>
                <div class="tiptap-container">
                    <div tiptap [editor]="editor" formControlName="content"></div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Parent">
            <admin-select-content
                [showNodeOptions]="false"
                formControlName="nodeIds"
                [nodes]="nodes"
                (nodeSelected)="onNodeSelected($event)"
            ></admin-select-content>
        </mat-tab>
    </mat-tab-group>
</form>
