import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

import { Node } from '@app/data/models';
import { NodeType } from '@app/shared/enums';
import { CdkScrollable } from '@angular/cdk/scrolling';

import { MatButton } from '@angular/material/button';
import { AnyPipe } from '@app/shared/pipes/any.pipe';

@Component({
    templateUrl: './delete-media-asset-confirmation-dialog.component.html',
    styles: [
        `
            :host {
                width: 600px;
                display: block;
            }

            mat-dialog-content {
                height: 100px;
            }
        `,
    ],
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        AnyPipe
    ]
})
export class DeleteMediaAssetConfirmationDialogComponent {
    data = inject(MAT_DIALOG_DATA);

    NodeType: typeof NodeType = NodeType;

    get name(): string {
        return `${this.data.asset.title}`;
    }

    get nodes(): Node[] {
        return this.data.nodes;
    }
}
