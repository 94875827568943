import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    inject,
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';

import { takeUntil, tap } from 'rxjs';

import { CreateWebinarFromTemplate, WebinarTemplate } from '@app/data/models';
import { DestroyService } from '@app/shared/services/destroy.service';
import { DEFAULT_TIME_ZONE } from '@app/shared/util';
import { Timezone } from 'timezones.json';
import { NgxsFormDirective } from '@ngxs/form-plugin';
import { MatFormField, MatLabel, MatSuffix, MatError, MatHint } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';

import { MatOption } from '@angular/material/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ErrorDisplayComponent } from '@app/shared/components/error-display/error-display.component';
import { MatInput } from '@angular/material/input';
import { DateTimePickerComponent } from '../date-time-picker/date-time-picker.component';
import { FormatDatePipe } from '@app/shared/pipes/format-date.pipe';

@Component({
    selector: 'admin-create-webinar-from-template',
    templateUrl: './create-webinar-from-template.component.html',
    styleUrls: ['./create-webinar-from-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService],
    imports: [
        ReactiveFormsModule,
        NgxsFormDirective,
        MatFormField,
        MatLabel,
        MatSelect,
        MatOption,
        MatIconButton,
        MatSuffix,
        MatIcon,
        MatError,
        MatInput,
        MatHint,
        ErrorDisplayComponent,
        DateTimePickerComponent,
        FormatDatePipe,
    ]
})
export class CreateWebinarFromTemplateComponent implements OnInit, OnChanges {
    private destroy$ = inject(DestroyService, { self: true });

    defaultTimeZone = DEFAULT_TIME_ZONE;

    filteredTemplates: WebinarTemplate[] = [];

    get ctrls(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    get isDifferentTimeZone() {
        return this.ctrls['timeZone'].value !== DEFAULT_TIME_ZONE;
    }

    form = new FormGroup({
        templateId: new FormControl<string | null>(null, [Validators.required]),
        topic: new FormControl('', [Validators.required]),
        agenda: new FormControl({ value: '', disabled: true }, []),
        password: new FormControl(this.generateFourDigitPassword()),
        startDate: new FormControl<Date | null>(null, [Validators.required, this.isFutureDateValidator()]),
        duration: new FormControl<number>(1, [Validators.required, Validators.min(1), Validators.max(1440)]),
        timeZone: new FormControl<string>(this.defaultTimeZone, [Validators.required]),
    });

    @Input() model: CreateWebinarFromTemplate | null = null;
    @Input() templates: WebinarTemplate[] | null = [];

    @Output() readonly refresh = new EventEmitter<void>();

    ngOnInit(): void {
        this.form
            .get('templateId')
            ?.valueChanges.pipe(
                takeUntil(this.destroy$),
                tap((templateId: string | null) => {
                    const template = this.templates?.find(x => x.id === templateId);

                    if (template) {
                        const pieces = [];
                        if (this.model?.topic) {
                            pieces.push(this.model?.topic);
                        }
                        pieces.push(template.name);
                        this.form.get('topic')?.setValue(pieces.join(' - '));
                    }
                }),
            )
            .subscribe();
    }

    onRefresh() {
        this.refresh.emit();
    }

    onTimeZoneChange(tz: Timezone): void {
        const timeZone = tz.utc[0];
        this.ctrls['timeZone'].setValue(timeZone);
    }

    isFutureDateValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const date = control.value as Date;
            if (!date) {
                return null;
            }

            const now = new Date();
            if (date < now) {
                return { isFutureDate: 'Date must be in the future' };
            }

            return null;
        };
    }

    generateFourDigitPassword(): string {
        const password = Math.floor(1000 + Math.random() * 9000).toString();
        return password;
    }

    update() {
        if (!this.model) {
            return;
        }

        this.form.patchValue({
            topic: this.model.topic,
            agenda: this.model.agenda,
            startDate: this.model.startDate,
            duration: 60,
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['model'] && this.model) {
            this.update();
        }

        if (changes['templates'] && this.templates) {
            this.filteredTemplates = [...this.templates];
            this.filteredTemplates.sort((a, b) => a.name.localeCompare(b.name));
        }
    }
}
