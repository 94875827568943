import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';

import { map, withLatestFrom } from 'rxjs';

import { MediaPlayerActions } from '@app/data/actions';
import { DropMediaModel } from '@app/data/models';
import { DestroyService } from '@app/shared/services/destroy.service';
import { AuthState } from '@app/data/state/auth.state';
import { MiniPlayerState } from '@app/shared/enums';
import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { handleFileUpload, isOldStorageUrl, migrateToStorageUrl, transformToCdnUrl } from '@app/shared/util';
import { Store } from '@ngxs/store';
import { AngularNodeViewComponent, TiptapDraggableDirective } from 'ngx-tiptap';

import { HomeState } from '../../state/home.state';
import { NgClass, AsyncPipe } from '@angular/common';
import { TiptapAudioComponent } from '@app/shared/components/tiptap-audio/tiptap-audio.component';
import { SrcUnloadDirective } from '@app/shared/directives/src-unload.directive';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MediaPlayerState } from '@app/data/state/media-player.state';

@Component({
    selector: 'admin-tiptap-audio-container',
    templateUrl: './tiptap-audio-container.component.html',
    styleUrls: ['./tiptap-audio-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService],
    imports: [TiptapDraggableDirective, NgClass, TiptapAudioComponent, SrcUnloadDirective, MatProgressBar, AsyncPipe]
})
export class TiptapAudioContainerComponent extends AngularNodeViewComponent implements OnInit {
    private env = inject<Environment>(APP_ENVIRONMENT);
    private store = inject(Store);
    private cd = inject(ChangeDetectorRef);

    id = performance.now().toString();
    isUploading = false;
    progress = 0;
    waitingForState = false;
    fileName = '';

    tokenResult = this.store.selectSnapshot(AuthState.getToken());

    get model(): DropMediaModel | null {
        return this.node().attrs['file'] || null;
    }

    get display(): 'default' | 'inline' {
        return this.node().attrs['display'];
    }

    get file(): File | null {
        return this.model?.file || null;
    }

    get isPublic(): boolean {
        return this.model?.isPublic === true;
    }

    get title(): string | null {
        return this.node().attrs['title'];
    }

    get type(): string | null {
        return this.node().attrs['type'];
    }

    get src() {
        return this.node().attrs['src'];
    }

    get isEditable() {
        return this.editor().isEditable;
    }

    get isSelected() {
        return this.selected;
    }

    get alignment() {
        return this.node().attrs['textAlign'];
    }

    get isProduction() {
        return this.env.production;
    }

    get token(): string | null {
        return this.tokenResult?.token || null;
    }

    hasInteracted$ = this.store.select(MediaPlayerState.hasInteracted()).pipe(
        withLatestFrom(this.store.select(MediaPlayerState.getId())),
        map(([hasInteracted, id]) => hasInteracted && id === this.src),
    );

    isPlayingMedia$ = this.store.select(MediaPlayerState.isPlaying()).pipe(
        withLatestFrom(this.store.select(MediaPlayerState.getId())),
        map(([isPlaying, id]) => isPlaying && id === this.src),
    );

    ngOnInit(): void {
        this.editor().on('transaction', ({ editor, transaction }) => {
            this.cd.detectChanges();
        });

        this.editor().on('selectionUpdate', () => {
            this.cd.detectChanges();
        });

        const srcAttr = this.node().attrs['src'] || '';

        if (isOldStorageUrl(srcAttr)) {
            this.updateAttributes()({ src: migrateToStorageUrl(srcAttr, this.env, 'audio') });
            this.cd.markForCheck();
        }
    }

    onReportProgress(event: ProgressEvent, isComplete: boolean): void {
        this.isUploading = !isComplete;
        this.progress = Math.round((event.loaded / event.total) * 100);
        this.cd.markForCheck();
    }

    onPlayMedia() {
        const id = this.store.selectSnapshot(MediaPlayerState.getId());
        const state = this.store.selectSnapshot(HomeState.getMiniPlayerState());
        const src = transformToCdnUrl(this.src, this.env, 'audio');

        if (id === this.src) {
            if (state !== MiniPlayerState.Full) {
                this.store.dispatch(new MediaPlayerActions.SetMiniPlayerVisibility(MiniPlayerState.Full));
            }
        } else {
            this.store.dispatch([
                new MediaPlayerActions.LoadAudio(this.src, this.title, src),
                new MediaPlayerActions.SetMiniPlayerVisibility(MiniPlayerState.Full),
            ]);
        }
    }

    async onUploadMediaFile(): Promise<void> {
        this.isUploading = true;

        const src = await handleFileUpload(
            this.env.serverUrl,
            this.token as string,
            this.file as File,
            (e, isComplete) => this.onReportProgress(e, isComplete),
            this.isPublic,
        );

        this.updateAttributes()({ src, type: this.file?.type, title: this.file?.name, file: null });

        this.cd.markForCheck();
    }
}
