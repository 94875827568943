import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ScheduledEvent } from '@app/data/models';
import { errorHandler, getData, verifyServerResult } from '@app/data/operators';
import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CalendlyService {
    private http = inject(HttpClient);
    private env = inject<Environment>(APP_ENVIRONMENT);


    getScheduledEventById(rowKeys: string[]): Observable<ScheduledEvent[]> {
        return this.http
            .post<ServerResult<ScheduledEvent[]>>(`${this.env.serverUrl}/form/event`, JSON.stringify(rowKeys))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }
}
