import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { ErrorDisplayComponent } from '../../components/error-display/error-display.component';

@Component({
    templateUrl: './extend-session-dialog.component.html',
    styleUrls: ['./extend-session-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ReactiveFormsModule,

        CdkScrollable,

        MatDialogTitle,
        MatDialogContent,
        MatFormField,
        MatLabel,
        MatInput,
        MatHint,
        MatError,
        MatDialogActions,
        MatButton,
        MatDialogClose,

        ErrorDisplayComponent,
    ]
})
export class ExtendSessionDialogComponent {
    private fb = inject(FormBuilder);

    get ctrls() {
        return this.form.controls;
    }

    form = this.fb.group({
        days: [7, [Validators.required, Validators.min(-90), Validators.max(90)]],
    });
}
