<section class="tasks-container">
    <input
        #addInput
        class="new-task"
        placeholder="What needs to be done?"
        autofocus=""
        (keyup.enter)="onAdd(addInput.value); addInput.value = ''"
    />

    @if (tasks | any) {
    <section class="main">
        <ul
            class="task-list"
            cdkDropList
            (cdkDropListDropped)="onReorderList($event)"
            (cdkDropListSorted)="onSorted($event)"
        >
            @for (task of tasks; track task; let index = $index) {
            <li
                cdkDrag
                [class.completed]="task.isComplete"
                [class.editing]="task.isEditing"
                [class.subtask]="task.isSubtask"
                [cdkDragData]="task"
                (cdkDragStarted)="onDragStarted()"
                (cdkDragMoved)="onMoved($event, task)"
            >
                <div *cdkDragPreview data="task">
                    <li [class.completed]="task.isComplete" [class.subtask]="task.isSubtask">
                        <div class="view">
                            <input class="toggle" type="checkbox" [checked]="task.isComplete" />
                            <label>{{ task.title }}</label>
                        </div>
                    </li>
                </div>
                <div *cdkDragPlaceholder data="task">
                    <li [class.completed]="task.isComplete" [class.subtask]="task.isSubtask">
                        <div class="view">
                            <input class="toggle" type="checkbox" [checked]="task.isComplete" />
                            <label>{{ task.title }}</label>
                        </div>
                    </li>
                </div>
                <div class="view">
                    <input
                        class="toggle"
                        type="checkbox"
                        (click)="onToggleCompletion(task, index); $event.stopPropagation()"
                        [checked]="task.isComplete"
                    />
                    <label (click)="onHandleClick(task)">{{ task.title }}</label>
                    <button class="destroy" (click)="onDelete(index)"></button>
                    <div class="toolbar" [ngClass]="{ 'has-note': !!task.notes }">
                        @if (hasSubtasks(index)) {
                        {{ completedSubtasks(index) }} of {{ totalSubtasks(index) }}
                        }
                        <span class="note"> <mat-icon>sticky_note_2</mat-icon> Note </span>
                    </div>
                </div>
                @if (task.isEditing) {
                <input
                    #editInput
                    cdkFocusInitial
                    class="edit"
                    [value]="task.title"
                    (blur)="onUpdateTask(task, editInput.value, index)"
                    (keyup.enter)="onUpdateTask(task, editInput.value, index)"
                    (keyup.escape)="task.isEditing = false"
                />
                }
            </li>
            }
        </ul>
    </section>

    <footer class="footer">
        @if (areAnyCompleted()) {
        <a mat-button class="clear-completed" (click)="onClearCompleted()">Clear Completed</a>
        }
    </footer>
    }
</section>
