import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { ErrorModel } from '@app/shared/models';
import { MatList, MatListItem } from '@angular/material/list';

import { MatButton } from '@angular/material/button';

@Component({
    selector: 'admin-validation-summary',
    templateUrl: './validation-summary.component.html',
    styleUrls: ['./validation-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatList,
        MatListItem,
        MatButton
    ]
})
export class ValidationSummaryComponent {
    private data = inject<ErrorModel<any>>(MAT_SNACK_BAR_DATA);
    private snack = inject(MatSnackBar);

    get validationErrors() {
        const results: string[] = (this.data.meta?.['results'] as string[]) || [];
        return results;
    }

    onClose() {
        this.snack.dismiss();
    }
}
