<section tiptapDraggable [ngClass]="{ editable: isEditable, selected: isEditable && isSelected }">
  <shared-tiptap-audio
    [src]="src"
    [title]="title"
    [display]="display"
    [file]="file"
    [token]="tokenResult"
    [alignment]="alignment"
    [hasInteracted]="hasInteracted$ | async"
    [isPlayingMedia]="isPlayingMedia$ | async"
    (playMedia)="onPlayMedia()"
    (uploadFile)="onUploadMediaFile()"
  ></shared-tiptap-audio>

  @if (file && isUploading) {
    <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
  }
</section>
