import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { LinkSessionSummary, QueryResult, SaveLinkModel, SearchOptionsModel, ShareableLinkModel } from '@app/data/models';
import { errorHandler, getData, verifyServerResult } from '@app/data/operators';
import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

@Injectable({
    providedIn: 'root',
})
export class ManageLinkRequestsService {
    private env = inject<Environment>(APP_ENVIRONMENT);
    private http = inject(HttpClient);


    saveLink(model: SaveLinkModel): Observable<ShareableLinkModel> {
        return this.http
            .post<ServerResult<ShareableLinkModel>>(`${this.env.serverUrl}/manage/links/save`, model, {})
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getLinkRequestModel(): Observable<ShareableLinkModel> {
        return this.http
            .get<ServerResult<ShareableLinkModel>>(`${this.env.serverUrl}/manage/link-request`)
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    searchLinkSessions(rowKey: string, model: SearchOptionsModel): Observable<QueryResult<LinkSessionSummary>> {
        return this.http
            .post<ServerResult<QueryResult<LinkSessionSummary>>>(`${this.env.serverUrl}/manage/links/search-sessions`, {
                ...model,
                rowKey,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    getAllContentLinkSessions(rowKey: string): Observable<LinkSessionSummary[]> {
        return this.http
            .post<ServerResult<LinkSessionSummary[]>>(`${this.env.serverUrl}/manage/links/sessions`, {
                rowKey,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    activateSession(rowKey: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/activate`, JSON.stringify(rowKey))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deactivateSession(rowKey: string): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/deactivate`, JSON.stringify(rowKey))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    updateActiveSessionCount(rowKey: string): Observable<ShareableLinkModel> {
        return this.http
            .post<ServerResult<ShareableLinkModel>>(`${this.env.serverUrl}/manage/links/sessions/update-count`, JSON.stringify(rowKey))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    deleteSession(rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/link-requests/sessions/delete`, JSON.stringify(rowKeys))
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    mergeSessions(linkId: string, primary: string, rowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/merge`, { rowKeys, primary, linkId })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }

    approveSession(rowKey: string, formSubmissionRowKeys: string[]): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/manage/links/sessions/approve`, {
                rowKey,
                formSubmissionRowKeys,
            })
            .pipe(verifyServerResult(), getData(), errorHandler());
    }
}
